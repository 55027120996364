const productAdditionalFields = {
    ContentRecom: [
        {
          name: "CURRENT_CLIENT",
          label: "Current Client",
          type: "Input",
          default: "",
          selectOptins: null,
          isRequired: true,
        },
        {
          name: "GA_CODE",
          label: "GA CODE",
          type: "Input",
          default: "",
          selectOptions: null,
          isRequired: true,
        },
        {
          name: "ExternalWidget_script",
          label: "External Widget script",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "WidgetPosition",
          label: "Widget Position Desktop",
          type: "radio",
          default: "right",
          selectOptions: ["left", "right"],
          isBool: false,
        },
        {
          name: "WidgetPositionMobile",
          label: "Widget Position Mobile",
          type: "radio",
          default: "right",
          selectOptions: ["left", "right"],
          isBool: false,
        },
        {
          name: "CubeWidget",
          label: "Cube Widget",
          type: "radio",
          default: "enabled",
          selectOptions: ["enabled", "disabled"],
        },
        {
          name: "Sticky_Ad",
          label: "Sticky Ad",
          type: "radio",
          default: false,
          selectOptions: ["true", "false"],
          isBool: true,
        },
        {
          name: "CUSTOM_ADS_DIV",
          label: "CUSTOM ADS DIV",
          type: "radio",
          default: false,
          selectOptions: ["true", "false"],
          isBool: true,
        },
        {
          name: "CUSTOM_ADS_CSS",
          label: "CUSTOM ADS CSS",
          type: "radio",
          default: false,
          selectOptions: ["true", "false"],
          isBool: true,
        },
        {
          name: "saveUserConfig",
          label: "Save User Config",
          type: "radio",
          default: false,
          selectOptions: ["true", "false"],
          isBool: true,
        },
        {
          name: "ampEnable",
          label: "amp Enable",
          type: "radio",
          default: false,
          selectOptions: ["true", "false"],
          isBool: true,
        },
    
        {
          name: "POPUP",
          label: "POPUP",
          type: "objectgroup",
          fields: [
            {
              name: "UB_POPUP",
              label: "UB POPUP",
              type: "radio",
              default: false,
              selectOptions: ["true", "false", "custom"],
              isBool: true,
            },
            {
              name: "UB_ID",
              label: "UB ID",
              type: "Input",
              hidden: true,
              relation: "UB_POPUP",
            },
            {
              name: "UB_SRC",
              label: "UB SRC",
              type: "Input",
              hidden: true,
              relation: "UB_POPUP",
            },
          ],
          default: [{}],
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "objectarray",
        },
        {
          name: "Anchor",
          label: "Anchor",
          type: "objectgroup",
          fields: [
            {
              name: "UB_Anchor",
              label: "UB Anchor",
              type: "radio",
              default: false,
              selectOptions: ["true", "false", "custom"],
              isBool: true,
            },
            {
              name: "UB_ID",
              label: "UB ID",
              type: "Input",
              hidden: true,
              relation: "UB_Anchor",
            },
            {
              name: "UB_SRC",
              label: "UB SRC",
              type: "Input",
              hidden: true,
              relation: "UB_Anchor",
            },
          ],
          default: [{}],
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "objectarray",
        },
        {
          name: "Parallax",
          label: "Parallax",
          type: "objectgroup",
          fields: [
            {
              name: "Parallax_AD",
              label: "UB Parallax",
              type: "radio",
              default: false,
              selectOptions: ["true", "false", "custom"],
              isBool: true,
            },
            {
              name: "UB_ID",
              label: "UB ID",
              type: "Input",
              hidden: true,
              relation: "Parallax_AD",
            },
            {
              name: "UB_SRC",
              label: "UB SRC",
              type: "Input",
              hidden: true,
              relation: "Parallax_AD",
            },
          ],
          default: [{}],
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "objectarray",
        },
        {
          name: "InImage",
          label: "InImage",
          type: "objectgroup",
          fields: [
            {
              name: "Inimage_AD",
              label: "UB Inimage",
              type: "radio",
              default: false,
              selectOptions: ["true", "false", "custom"],
              isBool: true,
            },
            {
              name: "UB_ID",
              label: "UB ID",
              type: "Input",
              hidden: true,
              relation: "Inimage_AD",
            },
            {
              name: "UB_SRC",
              label: "UB SRC",
              type: "Input",
              hidden: true,
              relation: "Inimage_AD",
            },
          ],
          default: [{}],
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "objectarray",
        },
      ]
};
export default productAdditionalFields;