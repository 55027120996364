import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import DeleteUser from "./DeleteUser";
import AddUser from "./AddUser";

function Users(props) {
  const [rows, setRows] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const columns = [
    {
      field: "username",
      headerName: "Name",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Edit",
      renderCell: (params) => {
        return (
          <AddUser
            edit={true}
            email={params.row.email}
            getUsers={getUsers}
            setRefresh={setRefresh}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      renderCell: (params) => {
        return (
          <DeleteUser
            email={params.row.email}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
      },
    },
  ];
  const getUsers = async (email) => {
    return new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_API_URL + "users/getUsers";
      const options = {
        email: email,
      };
      axios
        .post(url, options)
        .then((res) => {
          if (!email) {
            setRows(res.data.data);
          }
          resolve(res.data.data[0]);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  useEffect(() => {
    getUsers();
  }, [refresh]);
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }
  return (
    <Box sx={{ height: 450, width: "100%" }}>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "end", mb: 2 }}
      >
        <Button
          variant="contained"
          onClick={() => {
            setAddOpen(true);
          }}
        >
          Add User
        </Button>
      </Box>
      <AddUser edit={false} open={addOpen} setRefresh={setRefresh} />
      {rows.length > 0 && (
        <DataGrid
          rows={rows}
          columns={columns}
          getRowId={(row) => row.email}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
          components={{ Pagination: CustomPagination, Toolbar: CustomToolbar }}
        />
      )}
    </Box>
  );
};
export default Users;