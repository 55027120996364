import { FlashOffOutlined } from "@material-ui/icons";

const productAdditionalFields = {
  NewsBot: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "ScrollPixelHeight",
      label: "Scroll Pixel Height",
      type: "Input",
      default: 45,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "ScrollStartPixelHeight",
      label: "Scroll Start Pixel Height",
      type: "Input",
      default: 0,
      isNum: true,
    },
    {
      name: "MobileTopPixel",
      label: "Mobile Top Pixel",
      type: "Input",
      default: 54,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "ExternalWidget_script",
      label: "External Widget script",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "mybotLang",
      label: "Bot Language",
      type: "Input",
      default: "en",
      selectOptions: null,
      isBool: false,
    },
    {
      name: "mybotMatchTimestamps",
      label: "Mybot Match Timestamps",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
    },
    {
      name: "mybotClickLocation",
      label: "Mybot Click Location",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
    },
    {
      name: "mybotSearchcx",
      label: "Bot Search cx",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "BotCategories",
      label: "Bot categories",
      type: "Dropdown",
      catNum: true,
      default: 6,
      selectOptions: [4, 6, 9, 12],
      isNum: true,
    },
    {
      name: "DesktopBottomPixel",
      label: "Desktop Bottom Pixel",
      type: "Input",
      default: 30,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "mybotAdSmallName",
      label: "Ad Small Name",
      type: "Input",
      default: "/123148010/MO_HOME_PAGE_FLOATING_WIDGET_320x50",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "mybotAdSmallSize",
      label: "Ad Small Size",
      type: "Dropdown",
      default: "[320,50]",
      selectOptions: ["[320,50]"],
      isBool: false,
      isRequired: false,
    },
    {
      name: "mybotAdSmallID",
      label: "Ad Small ID",
      type: "Input",
      default: "div-ub-1",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "MobileBottomPixel",
      label: "Mobile Bottom Pixel",
      type: "Input",
      default: 30,
      selectOptions: null,
      isBool: false,
      isNum: true,
      line: true,
    },
    {
      name: "LoadAdDefault",
      label: "Load Ad Default",
      type: "switch",
      default: false,
      isBool: true,
    },

    {
      name: "Sticky_Ad",
      label: "Sticky Ad",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "AuthEnabled",
      label: "Auth Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "CloseEnabled",
      label: "Close Enabled",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "ClickCountEnabled",
      label: "Click Count Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },

    {
      name: "ScrollFlag",
      label: "Scroll Flag",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ScrollStartFlag",
      label: "Scroll Start Flag",
      type: "switch",
      default: false,
      isBool: true,
    },

    {
      name: "IsBottomScrollDisapperEnabled",
      label: "Is Bottom Scroll Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "IsBottomScrollStartDisapperEnabled",
      label: "Is Bottom Scroll Start Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "IsScrollupDisapperEnabled",
      label: "Is Scroll up Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "MobileTopPlacement",
      label: "Mobile Top Placement",
      type: "switch",
      default: false,
      isBool: true,
    },

    {
      name: "mybotMultipleIconsDynamicEnabled",
      label: "mybot Multiple Icons DynamicEnabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotMultipleIconsEnabled",
      label: "mybot Multiple IconsEnabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotspecialEvent",
      label: "mybot specialEvent",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotMultiIconNumber",
      label: "mybot MultiIconNumber",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotMatchEnabledFlag",
      label: "mybot MatchEnabledFlag",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotMatchEnabled",
      label: "mybot MatchEnabled",
      type: "switch",
      default: false,
      isBool: true,
    },

    {
      name: "mybotCatFallbackImage",
      label: "My BotCatFallbackImage",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "noImageExp",
      label: "No Image Exp",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "imageSearchExp",
      label: "imageSearchExp",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "mybotSearchTitleField",
      label: "Mybot SearchTitleField",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "mybotMobileSwipeEnabled",
      label: "Mybot MobileSwipeEnabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotlinkAppendFlag",
      label: "Mybot linkAppendFlag",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "firstCardSkip",
      label: "firstCardSkip",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotOutSliderEnabled",
      label: "Mybot OutSliderEnabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "SearchTitleField",
      label: "Search Title Field",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "MobileSwipeEnabled",
      label: "Mobile Swipe Enabled",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "SaveUserConfig",
      label: "Save User Config",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "CUSTOM_ADS_CSS",
      label: "CUSTOM ADS CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CUSTOM_ADS_DIV",
      label: "CUSTOM ADS DIV",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "adText",
      label: "ad Text",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "mybotslickCloseBtn",
      label: "Search Close Button",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "CustomSearchTexts",
      label: "Custom Search Texts",
      type: "Input",
      default: "",
      dataType: "Object",
    },
    {
      name: "Ui_Style",
      label: "Ui Style",
      type: "Dropdown",
      default: "V1",
      selectOptions: ["V1", "V2", "V3"],
    },
    {
      name: "WidgetPosition",
      label: "Widget Position Desktop",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
    },
    {
      name: "WidgetPositionMobile",
      label: "Widget Position Mobile",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
    },
    {
      name: "Image_type",
      label: "Image Type",
      type: "radio",
      default: "svg",
      selectOptions: ["svg", "png"],
      isBool: false,
    },
    {
      name: "logoUploadFront",
      label: "Upload Logo Front",       
      type: "upload",
      path: "https://cdn/unibots.in/newsbot/",
      default: "",
      selectOptions: null,
      isBool: false,
      saveType: "string",
      isRequired: true,
    },
    {
      name: "logoUploadBack",
      label: "Upload Logo Back",
      type: "upload",
      path: "https://cdn/unibots.in/newsbot/",
      default: "",
      selectOptions: null,
      isBool: false,
      saveType: "string",
      isRequired: true,
    },
    {
      name: "CustomColors",
      label: "Custom Colors",
      type: "objectgroup",
      index: "static",
      fields: [
        {
          name: "headerColor",
          label: "Header Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
        },
        {
          name: "themeColor",
          label: "Theme Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
        },
        {
          name: "searchColor",
          label: "Search Icon Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
        },
      ],
      default: {},
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "objectarray",
    },
    {
      name: "DisplayTexts",
      label: "Display Texts",
      type: "objectgroup",
      index: "static",
      fields: [
        {
          name: "botSearchDisplayText",
          label: "bot Search Display Text",
          type: "Input",
          default: "Here is what I have got for you!",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
          isRequired: true,
        },
        {
          name: "botSearchDisplayTextNF",
          label: "bot Search Display Text NF",
          type: "Input",
          default: "We could not find anything with this query!",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
          isRequired: true,
        },
        {
          name: "botSearchPlaceholder",
          label: "bot Search Placeholder",
          type: "Input",
          default: "Ask Me Anything...",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
          isRequired: true,
        },
        {
          name: "myBotMenuCustomTopText",
          label: "Bot Menu Custom TopText",
          type: "Input",
          default:
            "Welcome to the News Assistant. What kind of updates would you like today?",
          selectOptions: null,
          isBool: false,
          saveType: "string",
          custom: true,
          isRequired: true,
        },
        {
          name: "catText",
          label: "Show More Categories Text",
          type: "Input",
          default: "Show more categories",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string",
          custom: true,
        },
        {
          name: "myBotMenuCustomTopTextCats",
          label: "Bot Menu Custom TopText Cats",
          type: "Input",
          default: "Here are some categories for you to pick from!",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string",
          custom: true,
        },
        {
          name: "mybotslickCloseText",
          label: "Search Close Text",
          type: "Input",
          default: "Close this slide",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string",
          custom: true,
        },
      ],
      default: {},
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "objectarray",
    },
    {
      name: "MoreCategoryText",
      label: "More Category Text",
      type: "Input",
      default: "More Categories",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "MyBotCat",
      label: "My Bot Cat",
      type: "objectgroup",
      fields: [
        {
          name: "Category_Key",
          label: "Category Key(unicode)",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
        },
        {
          name: "Category_Value",
          label: "Category Value(eng)",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: false,
        },
      ],
      addButton: true,
      addCount: 1,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "objectarray",
    },
  ],
};

export default productAdditionalFields;
