const defaultFields = {
  operations: {
    VideoPlayer: [
      "SIZE",
      "SIZE_MB",
      "PLAYER_WIDTH",
      "PLAYER_HEIGHT",
      "PLAYER_WIDTH_MB",
      "PLAYER_HEIGHT_MB",
      "GA_CODE",
      "CURRENT_CLIENT",
      "ENABLED_FOR_PC",
      "ENABLED_FOR_MB",
      "LOGO_ENABLE",
      "LOGO_ENABLE_MB",
    ],
  },
};
export default defaultFields;