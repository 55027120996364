import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SetFields from "./SetFields";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SwitchInput from "../../components/Inputs/SwitchInput";

const DialogPopup = (props) => {
  const counter = props.counter;
  const field = props.field;
  const [objOpen, setObjOpen] = useState({});
  const nextProps = props.nextProps ?? {};
  useEffect(() => {
    if (props.open) {
      setObjOpen((prev) => {
        return {
          ...prev,
          [field.name]: true,
        };
      });
    }
  }, []);

  return (
    <>
      <Dialog open={props.open ?? objOpen[field.name]} fullWidth maxWidth="xl">
        <DialogTitle>{field.label}</DialogTitle>
        <DialogContent
          sx={{
            height: "70vh",
            maxHeight: "70vh",
          }}
        >
          {counter?.map((el, index) => {
            nextProps["index"] =
              props?.index !== undefined
                ? props.index + index.toString()
                : props.newIndex ?? index;
            return (
              <>
                <span>
                  <div className="objectgroupdiv">
                    <div
                      style={{
                        border: "1px solid grey",
                        margin: "10px",
                        borderRadius: "10px",
                        width: "95%",
                      }}
                    >
                      <br />
                      <SetFields {...nextProps} />
                    </div>
                    {field.addButton ? (
                      <IconButton
                        style={{
                          height: "10%",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                        color="error"
                        onClick={(e) => {
                          props.name
                            ? props.deleteSubField(
                                props.name,
                                field.name,
                                props.index,
                                index
                              )
                            : props.deleteField(field.name, index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </span>
              </>
            );
          })}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {field.addButton ? (
            <Button
              sx={{ margin: "5px", width: "5%" }}
              onClick={
                props.name !== undefined
                  ? (e) =>
                      props.addSubField(props.name, field.name, props.index)
                  : (e) => props.addField(field.name)
              }
            >
              <AddCircleIcon />
            </Button>
          ) : (
            <span></span>
          )}

          <Button
            onClick={() => {
              setObjOpen((prev) => {
                return {
                  ...prev,
                  [field.name]: false,
                };
              });
              if (props.open) {
                props?.setOpen(false);
              }
            }}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {!props.open && field.name !== "bidders" ? (
        <Button
          variant="contained"
          style={{
            width: "max-content",
            marginLeft: props.name !== undefined ? "10px" : 0,
          }}
          onClick={() => {
            setObjOpen((prev) => {
              return {
                ...prev,
                [field.name]: true,
              };
            });
          }}
        >
          {props.customName ?? field.label}
        </Button>
      ) : (
        <></>
      )}

      {field.name === "bidders" && (
        <SwitchInput
          data={{
            index: props.index,
            prodDetails: nextProps?.biddersDisabled,
          }}
          field={{ ...field, label: "Disable Bidders" }}
          custom={true}
          handleChange={(e) => {
            nextProps.handleBidderDisableChange(props.index, e.target.checked);
          }}
        />
      )}
    </>
  );
};

export default DialogPopup;
