import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";

import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridSearchIcon,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { TextField, Tooltip } from "@mui/material";

const DataTable = (props) => {
  const [clients, setClients] = useState(props.clients.data);
  const searchValue = props.searchValue;
  const setSearchValue = props.setSearchValue;
  const classes = useStyles();
  const page = props.page;
  const setPage = props.setPage;
  const filter_client = JSON.parse(JSON.stringify(props.clients.data));
  const columns = [
    { field: "brand", headerName: "Client", width: 200 },
    {
      field: "contact",
      headerName: "Contact",
      flex: 1,
      valueGetter: ({ value }) => {
        return value?.name + ", " + value?.phone + ", " + value?.email;
      },
    },
    {
      field: "createdAt",
      headerName: "createdAt",
      flex: 1,
      valueGetter: ({ value }) => {
        if (value) {
          return `${value?.split("T")[0].split("-").reverse().join("-")}`;
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "updatedAt",
      flex: 1,
      valueGetter: ({ value }) => {
        if (value) {
          return `${value?.split("T")[0].split("-").reverse().join("-")}`;
        }
      },
    },
    {
      field: "isLive",
      headerName: "Status",
      flex: 1,
      renderCell: ({ value }) => {
        if (value === undefined) {
          return <></>;
        }
        const arr = Object.keys(value).map((key) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  backgroundColor: value[key] ? "#33cf4d" : "#d32f2f",
                  width: "10px",
                  height: "10px",
                }}
              ></Box>
              {key}
            </Box>
          );
        });

        return (
          <Tooltip title={arr}>
            <IconButton>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  let addClient = () => {
    props.changeAddClient();
  };

  let loadClientDetails = (details) => {
    props.fetchClients(details.row.brand).then((res) => {
      details.row = res.data[0];
      props.setClientData(details);
    });
  };
  //const [client_search, setClientSearch] = useState("");
  const handleSearchChange = (e, init) => {
    setSearchValue((prev) => e.target.value);
    let filteredRows = filter_client.filter((el) =>
      el.brand.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setClients(filteredRows);
    if (e.target.value.length > 0 && !init) {
      setPage(0);
    }
  };
  useEffect(() => {
    handleSearchChange({ target: { value: searchValue } }, true);
  }, []);
  const CustomToolbar = () => {
    const ret_item = (
      <GridToolbarContainer>
        <TextField
          style={{ width: "45%", margin: "5px" }}
          onChange={handleSearchChange}
          value={searchValue}
          autoFocus={true}
          label={
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <GridSearchIcon />
              <> Search Client</>
            </div>
          }
        />
      </GridToolbarContainer>
    );
    return ret_item;
  };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        classes={{
          root: classes.ul,
        }}
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => {
          setPage(value - 1);
        }}
      />
    );
  }
  return (
    <div style={{ height: "700px", width: "100%" }}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        sx={{ mb: 2 }}
      >
        <Button variant="contained" onClick={addClient}>
          Add Client
        </Button>
      </Stack>
      <DataGrid
        // onSelectionModelChange={(rows) => { selectedUnits(rows) }}
        rows={clients}
        getRowId={(row) => row._id}
        columns={columns}
        page={page ?? 0}
        pageSize={10}
        rowsPerPageOptions={[5]}
        onRowClick={loadClientDetails}
        components={{ Pagination: CustomPagination, Toolbar: CustomToolbar }}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        color: "white",
        background: "#121212",
        "&:hover": {
          background: "#121212",
        },
      },
    },
  },
}));

export default DataTable;
