import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import AddProductDetails from "../../Products/AddProducts/AddProductDetails";
import GenerateCode from "../../utils/GenerateCode";
import NestCheck from "./NestCheck";
import DeleteIcon from "@mui/icons-material/Delete";
import { dfpscript } from "../../utils/dfpscript";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabComponent(props) {
  const [value, setValue] = React.useState(props.editClient ? 2 : 0);
  const formData = props.formData;
  const [nestCheck, setNestCheck] = useState([]);
  const [code, setCode] = useState(true);
  const [openDfp, setOpenDfp] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  const products = [
    { name: "VideoPlayer", label: "Video Player" },
    { name: "HeaderBidding", label: "Header Bidding" },
    { name: "NewsBot", label: "NewsBot" },
    { name: "EventsWidget", label: "Events Widget" },
    { name: "CricketWidget", label: "Cricket Widget" },
    { name: "AppMonetization", label: "App Monetization" },
    { name: "GenericCube", label: "Generic Cube" },
    { name: "DirectCampaign", label: "Direct Campaign" },
    { name: "AMPGists", label: "AMP Gists" },
  ];
  const userData = useSelector((state) => state.userData);
  const productsAccess = userData.access.products;
  useEffect(() => {
    setNestCheck(
      <NestCheck
        formData={formData}
        heading={props.currentProduct}
        editFlag={props.editClient}
        handleSubmit={props.handleSubmit}
        setMatch={props.setMatch}
      />
    );
  }, [props.currentProduct]);

  const gistFormData = formData["additionalDetailsAMPGists"];
  const clientName = formData["clientName"];
  const [gistUrl, setGistUrl] = useState("");
  const [error, setError] = useState("");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, seterrorAlert] = useState(false);
  const [creationRes, setCreationRes] = useState("");
  const [openModal, setOpenModal] = useState(false);
  // useEffect(() => {
  //   if (!productsAccess.includes(props.currentProduct)) {
  //     if (productsAccess.length > 0) {
  //       props.setcurrentProduct(productsAccess[0]);
  //     } else {
  //       props.setcurrentProduct(products[0].name);
  //     }
  //   }
  // }, []);
  const createGist = async (gistFormData, clientName) => {
    // console.log( "gistFormData : ", gistFormData );
    let arr = [...gistFormData.Add_Ad_Units];
    let arrNative = [...(gistFormData.cube_details || []), ...(gistFormData.Direct_Banner|| [])];
    const description = clientName + "Gist";
    const githubAccessToken = process.env.REACT_APP_GITHUB_ACCESS_TOKEN;
    const GIST_URL = "https://api.github.com/gists";
    var fileName;
    var fileNameCube;
    let headers = {
      Authorization: `token ${githubAccessToken}`,
    };
    var bodyContent;
    let files;
    let tempFile;
    function splitStringFromLastSlash(inputString) {
      const lastSlashIndex = inputString.lastIndexOf("/");
      if (lastSlashIndex !== -1) {
        const part2 = inputString.slice(lastSlashIndex + 1);
        return [part2];
      } else {
        return [inputString];
      }
    }
    arr.forEach((el, index) => {
      if(el.AD_UNIT){
      const result = el.AD_UNIT && splitStringFromLastSlash(el.AD_UNIT);

      fileName = gistFormData.CURRENT_CLIENT + "_" + result;
      const sizes = el.SIZES;
      const [WIDTH, HEIGHT] = sizes[0].split('x').map(Number);
      const dataMultiSize = sizes.slice(1);
      const width = WIDTH;
      const height = HEIGHT;
      const adUnitName = el.AD_UNIT;
      const adType = el.AD_TYPE;
      tempFile = {
        [`${fileName}`]: {
          content: `STEP 1:
              Add the following script tag in the <head> to include amp-iframe component. If already present, ignore this step.
              <script async custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js"></script>
              ${
                adType === "anchor"
                  ? `Add the following script tag in the <head> to include amp-sticky-ad component.\n\t\t<script async custom-element="amp-sticky-ad" src="https://cdn.ampproject.org/v0/amp-sticky-ad-1.0.js"></script>`
                  : adType === "parallax"
                  ? `Add the following script tag in the <head> to include amp-sticky-ad and amp-fx-flying-carpet component.\n\t\t<script async custom-element="amp-fx-flying-carpet" src="https://cdn.ampproject.org/v0/amp-fx-flying-carpet-0.1.js"></script>`
                  : ""
              }              
            STEP 2:
              ${
                adType === "anchor"
                  ? `Add the following div just before closing <body> tag`
                  : `Add the following div where you want to see the ad`
              }
              ${
                adType === "anchor"
                  ? `<amp-sticky-ad layout="nodisplay">`
                  : adType === "parallax"
                  ? `<div class="amp-flying-carpet-text-border">Advertising</div>\n\t\t<amp-fx-flying-carpet height="300px">`
                  : `<div id="ub_${width}x${height}_${index}" style="text-align: center; margin: 20px;">`
              }
              <amp-ad width="${width}" height="${height}" type="doubleclick" ${
            adType === "anchor" ? 'layout="fixed"' : ""
          } data-multi-size="${dataMultiSize}" data-slot="${adUnitName}" data-enable-refresh="30"></amp-ad>
              ${
                adType === "anchor"
                  ? `</amp-sticky-ad>`
                  : adType === "parallax"
                  ? `</amp-fx-flying-carpet>\n\t\t<div class="amp-flying-carpet-text-border">Advertising</div>`
                  : `</div>`
              }`,
        },
      };
      files = { ...files, ...tempFile };
      }
    });
    console.log("arr",arr,"\n arrNative",arrNative)
    arrNative?.forEach((el) => {
    if(el.CHOOSE_TYPE){
    fileNameCube = gistFormData.CURRENT_CLIENT + (el.CHOOSE_TYPE === "CUBE" ? "_cube" : el.CHOOSE_TYPE === "Direct_Banner" ? "_Direct_Banner" : `${el.CHOOSE_TYPE}`);
    console.log(fileNameCube);
    const bottom = el.bottom;
    const pos = el.side_position;
    const src = el.src;
    const widthc = el.WIDTH;
    const heightc = el.HEIGHT;
    let tempFileCube = {
      [`${fileNameCube}`]: {
        content: `STEP 1:
          Add the following script tag in the <head> to include amp-iframe component. If already present, ignore this step.
          <script async custom-element="amp-iframe" src="https://cdn.ampproject.org/v0/amp-iframe-0.1.js"></script>

         ${(el.CHOOSE_TYPE === "CUBE") ?
         (`STEP 2:
          Add the following style to your already existing <style amp-custom> tag.

          .mybotUnibots {
              bottom: ${bottom}px ;
              ${pos}: 0;
              position: fixed;
              z-index: 2147483646;
            }
          
          STEP 3:
          Add the following div just before closing <body> tag
           
          <div class="mybotUnibots">
            <amp-iframe width="${widthc}" height="${heightc}" sandbox="allow-scripts allow-same-origin allow-popups" resizable src="${src}">
              <div overflow tabindex="0" role="button" aria-label="Show more">More</div>
              <amp-img layout="fill" src="https://cdn.unibots.in/genericcube/common/images/ph.png" placeholder></amp-img>
            </amp-iframe>
          </div>`):
        (`STEP 2:
        Add the following div element, where you want to show the ad in  <body> tag
        
        <div id="ub_${el.CHOOSE_TYPE+"_"+widthc+"x"+heightc}">
              <amp-iframe width="${widthc}" height="${heightc}" sandbox="allow-scripts allow-forms allow-same-origin allow-popups" allowfullscreen frameborder="0" src="${src}">
                <amp-img layout="fill" src="https://cdn.unibots.in/genericcube/common/images/ph.png" placeholder></amp-img>
              </amp-iframe>
        </div>`)}`,
      },
    };
  
    files = { ...files, ...tempFileCube };
    }
  });

    bodyContent = JSON.stringify({
      description: description,
      public: true,
      files: files,
    });

    try {
      let reqOptionsGet = {
        url: GIST_URL,
        method: "GET",
        headers: headers,
      };
      const existingGistsResponse = await axios.request(reqOptionsGet, {
        headers,
      });
      const existingGist = existingGistsResponse.data.find((gist) => {
        return Object.keys(gist.files).some((fileKey) => fileKey === fileName);
      });

      if (existingGist) {
        console.log("patching the gist");
        const existingGistId = existingGist.id;
        let reqOptionsPatch = {
          url: "https://api.github.com/gists/" + existingGistId,
          method: "PATCH",
          headers: headers,
          data: bodyContent,
        };
        let editResponse = await axios.request(reqOptionsPatch);
        setCreationRes("updated");
        setGistUrl(editResponse.data.html_url);
      } else {
        console.log("Creating a new Gist");
        let reqOptionsPost = {
          url: GIST_URL,
          method: "POST",
          headers: headers,
          data: bodyContent,
        };

        let createResponse = await axios.request(reqOptionsPost);
        setCreationRes("created");
        setGistUrl(createResponse.data.html_url);
        // handleOpenModal();
        setSuccessAlert(true);
      }
    } catch (error) {
      console.error(error);
      // console.error(error.response);
      setError("Error creating Gists. Please check your input.");
      // handleOpenModal();
    }
  };
  const handleDfp = async (index) => {
    props.handleGenerateCodeOpen("D" + index);
    setOpenDfp(true);
    setLoading(true);
    let url = process.env.REACT_APP_API_URL + "apidfp/getScript";
    const res = await axios({
      url: url,
      method: "GET",
      params: { name: formData.clientName, product: props.currentProduct },
    });
    if (res.data.status !== 200) {
      console.log("creating dfp script");
      url = process.env.REACT_APP_API_URL + "apidfp/uploadScript";
      // console.log("url2",url)
      let reqObj = {
        name: formData.clientName,
        file: dfpscript[props.currentProduct].replaceAll(
          "clientname",
          formData.clientName
        ),
        product: props.currentProduct,
      };
      axios
        .post(url, reqObj)
        .then((response) => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      console.log("dfp script exists already");
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: value !== 2 ? "40%" : "100%",
        height: "50%",
        position: "relative",
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Contact Details" {...a11yProps(0)} />
          <Tab label="Bank Details" {...a11yProps(1)} />
          <Tab label="Product" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TextField
          style={{ width: "100%" }}
          id="clientName"
          name="clientName"
          onChange={(e) => props.handleChange(e, e.target.value.trim())}
          value={formData.clientName}
          label="Client Name"
          variant="outlined"
          required
          InputProps={{
            readOnly: props.editClient ? true : false,
          }}
        />
        <TextField
          style={{ width: "100%" }}
          id="contactName"
          name="contactName"
          onChange={props.handleChange}
          value={formData.contactName}
          label="Contact Name"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="contactMail"
          name="contactMail"
          onChange={props.handleChange}
          value={formData.contactMail}
          label="Contact Email"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="contactNumber"
          name="contactNumber"
          onChange={props.handleChange}
          value={formData.contactNumber}
          label="Contact Number"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="contactCountry"
          name="contactCountry"
          onChange={props.handleChange}
          value={formData.contactCountry}
          label="Contact Country"
          variant="outlined"
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TextField
          style={{ width: "100%" }}
          id="acName"
          name="acName"
          onChange={props.handleChange}
          value={formData.acName}
          label="A/c Name"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="acNumber"
          name="acNumber"
          onChange={props.handleChange}
          value={formData.acNumber}
          label="A/c Number"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="ifsc"
          name="ifsc"
          onChange={props.handleChange}
          value={formData.ifsc}
          label="IFSC"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="swift"
          name="swift"
          onChange={props.handleChange}
          value={formData.swift}
          label="Swift Code"
          variant="outlined"
        />
        <TextField
          style={{ width: "100%" }}
          id="acCountry"
          name="acCountry"
          onChange={props.handleChange}
          value={formData.acCountry}
          label="A/c Country"
          variant="outlined"
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {props.websiteCounter.map((c, index) => {
          return (
            <>
              {index > 0 && <hr />}
              <div style={{ display: "flex", alignItems: "end", gap: 20 }}>
                <TextField
                  style={{ width: "45%", margin: 0 }}
                  id={"website" + index}
                  name={"website" + index}
                  onChange={props.handleChange}
                  value={formData["website" + index]}
                  label="Website"
                  variant="outlined"
                />
                {index > 0 && (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      props.deleteWebsite(index);
                    }}
                    endIcon={<DeleteIcon />}
                  >
                    <Box sx={{ height: "22px" }}>Delete</Box>
                  </Button>
                )}
              </div>
              <div>
                <FormControl sx={{ ml: 1, mb: 2 }}>
                  <FormLabel id={"product" + index}>Product</FormLabel>
                  <RadioGroup
                    id={"product" + index}
                    label="Product Name"
                    labelId={"product" + index}
                    name={"currentproduct"}
                    onChange={props.handleChange}
                    value={props.currentProduct}
                    row
                  >
                    {products.map((el) => {
                      return (
                        <FormControlLabel
                          value={el.name}
                          control={<Radio />}
                          disabled={!productsAccess.includes(el.name)}
                          label={el.label}
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {nestCheck}
                {/* <TextField style={{ width: '100%' }} id={"product" + index } name={"product" + index } onChange={props.handleChange} value={formData["product" + index ]} label="Product Name" variant="outlined" required color="primary" /> */}
                <TextField
                  style={{ width: "40%" }}
                  id={"deal" + index}
                  name={"deal" + index}
                  onChange={props.handleChange}
                  value={formData["deal" + index]}
                  label="Deal Percentage"
                  variant="outlined"
                />
                {props.currentProduct !== undefined ? (
                  <span>
                    <Button
                      variant="contained"
                      sx={{
                        margin: "10px",
                      }}
                      // disabled={!productsAccess.includes(props.currentProduct)}
                      disabled={(props.currentProduct !=="VideoPlayer") ? !productsAccess.includes(props.currentProduct) : !props.formData.additionalDetailsVideoPlayer
                    }   
                      onClick={(e) => props.handleClickOpen("D" + index)}
                    >
                      Add Details
                    </Button>
                    {
                      <span>
                        <Button
                          variant="contained"
                          sx={{
                            margin: "10px",
                          }}
                          onClick={(e) => {
                            props.currentProduct === "AMPGists" &&
                              createGist(gistFormData, clientName);
                            props.handleGenerateCodeOpen("D" + index);
                            setCode(true);
                            setOpenDfp(false);
                          }}
                        >
                          {props.currentProduct !== "AMPGists"
                            ? "Generate " + props.currentProduct + " Code"
                            : "Generate " + props.currentProduct + " URL"}
                        </Button>
                        {(props.currentProduct === "VideoPlayer" ||
                          props.currentProduct === "HeaderBidding") && (
                          <LoadingButton
                            loading={loading}
                            onClick={() => {
                              handleDfp(index);
                            }}
                            variant="contained"
                          >
                            Generate DFP Code
                          </LoadingButton>
                        )}
                        {(props.currentProduct === "HeaderBidding" ||
                          props.currentProduct === "GenericCube") && (
                          <Button
                            variant="contained"
                            sx={{
                              margin: "10px",
                            }}
                            onClick={(e) => {
                              props.handleGenerateCodeOpen("D" + index);
                              setCode(false);
                            }}
                          >
                            Generate {props.currentProduct} divs
                          </Button>
                        )}
                        <GenerateCode
                          dfp={openDfp}
                          open={props.code_open["D" + index]}
                          code={code}
                          formData={formData}
                          handleGenerateCodeClose={
                            props.handleGenerateCodeClose
                          }
                          index={index}
                          product={props.currentProduct}
                          gistUrl={gistUrl}
                        />
                      </span>
                    }
                    <AddProductDetails
                      heading={props.currentProduct}
                      open={props.open["D" + index]}
                      handleClose={props.handleClose}
                      handleChange={props.handleChange}
                      setVpJson={props.setVpJson}
                      formData={formData}
                      index={index}
                      editFlag={props.editClient}
                    />
                  </span>
                ) : (
                  <></>
                )}
                <br />
              </div>
            </>
          );
        })}
        <Button
          sx={{ m: 2, ml: 1 }}
          onClick={props.AddNewWebsite}
          variant="contained"
        >
          Add Website
        </Button>
      </TabPanel>

      <Button
        sx={{ left: 30 }}
        onClick={() => {
          setValue((prev) => prev + -1);
        }}
        variant="outlined"
        disabled={value <= 0}
      >
        Previous
      </Button>

      <Button
        sx={{ left: 309 }}
        onClick={() => {
          setValue((prev) => prev + 1);
        }}
        variant="outlined"
        disabled={value >= 2}
      >
        Next
      </Button>
    </Box>
  );
}
