const userData = {
  access: {
    sections: []
  },
};

export default function userReducer(state = userData, action) {
  switch (action.type) {
    case "UPDATE_USER_DATA":
      return action.data;
    default:
      return state;
  }
}
