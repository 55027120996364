import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

import axios from "axios";

function DeleteUser(props) {
  const handleDelete = () => {
    const url = process.env.REACT_APP_API_URL + "users/deleteuser";
    const options = {
      method: "POST",
      email: props.email,
    };
    axios.post(url, options).then((res) => {
      handleCancel();
      props.setRefresh(!props.refresh);
    });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Dialog open={open} fullWidth maxWidth="sm">
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <h2>Are you sure you want to delete this user?</h2>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        color="error"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
}

export default DeleteUser;
