import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import productAdditionalFields from "../HeaderBidding/ProductAdditionalField";
import { trackChange } from "../../utils/TrackChange";
import { setChangedFields } from "../../redux/actions/commonActions";

const SaveHb = (props) => {
  const dispatch = useDispatch();

  const field_data = props.prodFields?.HeaderBidding ?? [];
  const mappings_bidder_list = [
    "adf",
    "openx",
    "pubmatic",
    "sovrn",
    "teads",
    "ucfunnel",
    "yandex",
    "fluct"
  ];
  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const changedFields = useSelector((state) => state.common.changedField);

  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subObjectGroupCounter = useSelector(
    (state) => state.common.subObjectGroupCounter
  );
  const subObjectGroupDetails = useSelector(
    (state) => state.common.subObjectGroupDetails
  );
  const bidderLabel = useSelector((state) => state.common.bidderLabel);
  const innerBidderLabel = useSelector(
    (state) => state.common.innerBidderLabel
  );
  const notReqFields = useSelector((state) => state.common.notRequiredFields);
  const biddersDisabled = useSelector((state) => state.common.biddersDisabled);
  const skipItems = [
    "Load_Percentage",
    "refreshMaxLimit",
    "refreshTimeout",
    "exclude_category",
    "include_category",
    "exclude_homepage",
    "include_homepage",
    "Popup_timer",
    "NETWORK_IDs",
    "mappings_extra_units_config"
  ];
  const objSkipItems = ["targetDiv", "ub_adSense", "ub_ClmbParams", "mgid"];
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  const getArrayFromTextArea = (str) => {
    let arr = [];
    if (str) arr = str.split("\n");
    return arr;
  };  

  // to convert comma seperated string into a array
  const getArrayFromString = (str) => {
    let arr = [];
    if (str) arr = str?.split(",");
    return arr;
  };
  // to convert stringifield array of array into a simple array (timestamps fields in CWs, sizes obj grp field in hb)

  const getSizesArray = (str) => {
    let newStr = "[" + str + "]";
    let arr = JSON.parse(newStr);
    return arr;
  };
  function saveHeaderBidding() {
    const toSaveObject = { ...addProdDetails };
    let flag = true;
    if (true) {
      field_data.forEach((el) => {
        if (el.type == "objectgroup") {
          let objlen = objectGroupCounter[el.name].length;
          for (let i = 0; i < objlen; i++) {
            let obj = {};
            el.fields.forEach((sub_el) => {
              if (sub_el.type !== "objectgroup") {
                if (
                  sub_el.name === "adId" &&
                  objectGroupDetails[el.name][sub_el.name + i].includes(
                    "ub-popup"
                  )
                ) {
                  objectGroupDetails[el.name]["refresh" + i] = false;
                }
                if (sub_el.name === "adId" && objectGroupDetails[el.name][sub_el.name + i].includes("ub-parallax") ) {
                  objectGroupDetails[el.name]["childwidth_classname" + i] = objectGroupDetails[el.name]["childwidth_classname" + i] ? objectGroupDetails[el.name]["childwidth_classname" + i] : ("#div_ub_inpage"+i);
                }

                switch (sub_el.saveType) {
                  case "bool":
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i] == "true";
                    break;
                  case "array":
                    let arrayValue = getArrayFromString(objectGroupDetails[el.name][sub_el.name + i]);
                    if (arrayValue.length > 0) {
                      obj[sub_el.name] = arrayValue;
                    }
                    break;
                  case "textarea_array":
                    let textareaArrayValue = getArrayFromTextArea(objectGroupDetails[el.name][sub_el.name + i]);
                    if (textareaArrayValue.length > 0) { 
                      obj[sub_el.name] = textareaArrayValue;
                    }
                    break;
                  case "number":
                    obj[sub_el.name] = parseInt(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    if (isNaN(obj[sub_el.name])) {
                      delete obj[sub_el.name];
                    }
                    break;
                  case "size_array":
                    obj[sub_el.name] = getSizesArray(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  default:
                    if (sub_el.name.trim() !== "") { // Check if the value is not empty
                      obj[sub_el.name] = objectGroupDetails[el.name][sub_el.name + i];
                      if (sub_el.isBool && typeof obj[sub_el.name] === "string") {
                        obj[sub_el.name] = obj[sub_el.name] === "true";
                      }
                    }
                    break;
                }
                if (skipItems.includes(sub_el.name) && obj[sub_el.name]) {
                  if (obj[sub_el.name].length <= 0) {
                    delete obj[sub_el.name];
                  }
                }
              } else {
                obj[sub_el.name] = [];
                let subobjlen =
                  subObjectGroupCounter[el.name + sub_el.name][i].length;
                for (let j = 0; j < subobjlen; j++) {
                  let sub_obj = {};
                  sub_el.fields.forEach((in_sub_el) => {
                    switch (in_sub_el.saveType) {
                      case "bool":
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ] === "true";
                        break;
                      case "array":
                        let arrayValue = getArrayFromString(subObjectGroupDetails[el.name + sub_el.name][
                          in_sub_el.name + i + j
                        ]);
                        if (arrayValue.length > 0) { // Check if the array is not empty
                          sub_obj[in_sub_el.name] = arrayValue;
                        }
                        break;
                      case "number":
                        sub_obj[in_sub_el.name] = parseInt(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        if (isNaN(sub_obj[in_sub_el.name])) {
                          delete sub_obj[in_sub_el.name];
                        }
                        break;
                      default:
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ];
                        if (
                          in_sub_el.isBool &&
                          typeof sub_obj[in_sub_el.name] === "string"
                        ) {
                          sub_obj[in_sub_el.name] =
                            sub_obj[in_sub_el.name] === "true";
                        }
                        break;
                    }
                  });
                  // obj[sub_el.name][j] = sub_obj;
                  if (objSkipItems.includes(sub_el.name)) {
                    for (let key in sub_obj) {
                      if (sub_obj[key].length <= 0) {
                        delete sub_obj[key];
                      }
                    }
                  }
                  if (
                    typeof sub_el.default == "object" &&
                    !isArr(sub_el.default)
                  ) {
                    if (sub_el.index === "static") {
                      obj[sub_el.name] = sub_obj;
                    } else {
                      sub_el.fields.forEach((field) => {
                        let key = field.name;
                        if (
                          Object.keys(sub_obj).length <= 1 &&
                          sub_obj[key] === undefined
                        ) {
                          obj[sub_el.name] = {};
                          return true;
                        } else {
                          obj[sub_el.name][key + j] = sub_obj[key];
                        }
                      });
                    }
                  } else {
                    el.isBool
                      ? (obj[sub_el.name] = sub_obj)
                      : (obj[sub_el.name][j] = sub_obj);
                  }
                  if (objSkipItems.includes(sub_el.name)) {
                    if (Object.keys(sub_obj).length <= 0) {
                      delete obj[sub_el.name];
                    } else if (
                      sub_el.name === "ub_ClmbParams" &&
                      !sub_obj["ub_Clmbenable"] &&
                      Object.keys(sub_obj).length <= 1
                    ) {
                      delete obj[sub_el.name];
                    } else {
                      obj[sub_el.name] = sub_obj;
                    }
                  }
                }
              }
            });
            el.isBool
              ? (toSaveObject[el.name] = obj)
              : (toSaveObject[el.name][i] = obj);
            if (el.name === "CURRENCY_CONVERSION") {
              if (!obj.enable) {
                toSaveObject[el.name] = false;
              } 
              else {
                toSaveObject[el.name] = toSaveObject["CURRENCY_CONVERSION"];
              }
            }
          }
        } else if (skipItems.includes(el.name)) {
          let value = toSaveObject[el.name];
          if (
            !value ||
            value.length <= 0 ||
            value === el.default ||
            (typeof value === "string" && value.trim() === "") ||
            (value.isNum && value.isNaN())
          ) {
            delete toSaveObject[el.name];
          }
        }
      });
      let teads = null;
      for (
        let j = toSaveObject["hb_full_common_bidders"].length - 1;
        j >= 0;
        j--
      ) {
        let bid_obj = toSaveObject["hb_full_common_bidders"][j];
        if (
          bid_obj["Labeltype"] !== undefined &&
          bid_obj["Labeltype"] !== "none"
        ) {
          try {
          bid_obj[bid_obj["Labeltype"]] = bidderLabel["CountryCode" + j].map(
            (val) => {
              return val.split(" - ").pop();
            }
          );
          } catch (err){
            console.log(err)
            props.showAlertMsg(`Please choose country`);
          }
        }
        delete bid_obj["Labeltype"];
        if (bid_obj["bidder"] === "") {
          if (toSaveObject["hb_full_common_bidders"].length == 1) {
            toSaveObject["hb_full_common_bidders"] = [];
          } else {
            toSaveObject["hb_full_common_bidders"].splice(j, 1);
          }
        }
        if (bid_obj["bidder"] === "teads") {
          teads = bid_obj;
          toSaveObject["hb_full_common_bidders"].splice(j, 1);
        }
      }
      for (let j = 0; j < toSaveObject["mappings_full_hb_config"].length; j++) {
        let bid_len =
          toSaveObject["mappings_full_hb_config"][j]["bidders"].length;
        let clmb = toSaveObject["mappings_full_hb_config"][j]["ub_ClmbParams"];
        clmb = clmb ?? {};
        if (toSaveObject["mappings_full_hb_config"][j]["ub_ClmbParams"]) {
          toSaveObject["mappings_full_hb_config"][j]["ub_ClmbParams"] = [];
        }
        let childwidth =
          toSaveObject["mappings_full_hb_config"][j]["childwidth_classname"];
        if (childwidth === "") {
          delete toSaveObject["mappings_full_hb_config"][j][
            "childwidth_classname"
          ];
        }
        for (const [key, value] of Object.entries(clmb)) {
          let clmbObj = {};
          clmbObj[key] = value;
          toSaveObject["mappings_full_hb_config"][j]["ub_ClmbParams"].push(
            clmbObj
          );
        }
        for (let i = bid_len - 1; i >= 0; i--) {
          let inner_bid_obj =
            toSaveObject["mappings_full_hb_config"][j]["bidders"][i];
          if (
            !mappings_bidder_list.includes(inner_bid_obj["bidder"]) &&
            inner_bid_obj["bidder"].length > 0
          ) {
            let bidder = toSaveObject["mappings_full_hb_config"][j][
              "bidders"
            ].splice(i, 1);
            if (
              !toSaveObject["hb_full_common_bidders"].some(
                (el) => el.bidder === bidder[0]["bidder"]
              )
            ) {
              toSaveObject["hb_full_common_bidders"].push(bidder[0]);
            }
          }
          if (
            inner_bid_obj["Labeltype"] !== undefined &&
            inner_bid_obj["Labeltype"] !== "none"
          ) {
            inner_bid_obj[inner_bid_obj["Labeltype"]] = innerBidderLabel[
              "CountryCode" + j + i
            ].map((val) => {
              return val.split(" - ").pop();
            });
          }
          delete inner_bid_obj["Labeltype"];

          if (
            inner_bid_obj["bidder"] === undefined ||
            inner_bid_obj["bidder"] === ""
          ) {
            toSaveObject["mappings_full_hb_config"][j]["bidders"].splice(i, 1);
          }
          Object.values(inner_bid_obj["params"]).some((param) => {
            if (param.length <= 0) {
              toSaveObject["mappings_full_hb_config"][j]["bidders"].splice(
                i,
                1
              );
            }
          });
        }
        let sizes = toSaveObject["mappings_full_hb_config"][j]?.sizes;
        if (sizes) {
          toSaveObject["mappings_full_hb_config"][j]["sizesM"] = sizes;
          toSaveObject["mappings_full_hb_config"][j]["sizesD"] = sizes;
          delete toSaveObject["mappings_full_hb_config"][j]["sizes"];
        }
        let disabled = false;
        if (biddersDisabled["bidders" + j]) {
          toSaveObject["mappings_full_hb_config"][j]["bidders"] = false;
          disabled = true;
        }
        if (teads && !disabled) {
          toSaveObject["mappings_full_hb_config"][j]["bidders"].push(teads);
        }
      }
      toSaveObject["mappings_extra_units_config"] =
        toSaveObject["mappings_extra_units_config"][0];

      for (let key in toSaveObject) {
        let adUnitfilled;
        if (toSaveObject[key].length === 0 && !notReqFields.includes(key)) {
          flag = false;
          props.showAlertMsg(`Please fill ${key} field`);
          break;
        } else if (
          !notReqFields.includes(key) &&
          typeof toSaveObject[key] === "object"
        ) {
          if (isArr(toSaveObject[key])) {
            toSaveObject[key].forEach((elem, i) => {
              for (let sub_key in elem) {
                let disp;
                if (sub_key.includes("sizes")) {
                  disp = sub_key.split("").pop();
                  if (
                    disp !== elem["adUnitDisplay"] &&
                    elem["adUnitDisplay"] !== "B"
                  ) {
                    continue;
                  }
                }
                if (
                  sub_key === "adUnitName" &&
                  elem[sub_key].includes("Please fill ad unit")
                ) {
                  adUnitfilled = false;
                } else {
                  adUnitfilled = true;
                }
                if (
                  (elem[sub_key].length === 0 || !adUnitfilled) &&
                  !notReqFields.includes(sub_key)
                ) {
                  flag = false;
                  props.showAlertMsg(
                    `Please fill ${sub_key} field inside ${i + 1} ${key}`
                  );
                  break;
                }
              }
            });
          } else {
            for (let sub_key in toSaveObject[key]) {
              if (
                toSaveObject[key][sub_key].length === 0 &&
                !notReqFields.includes(sub_key)
              ) {
                flag = false;
                props.showAlertMsg(
                  `Please fill ${sub_key} field inside ${key}`
                );
                break;
              }
            }
          }
        }
      }
      if (props.editFlag) {
        let changes = JSON.parse(JSON.stringify(changedFields));
        const notMatched = trackChange(props.hbFormData, toSaveObject, changes);
        dispatch(setChangedFields(notMatched));
      }
      if (flag) {
        let event = {
          target: {
            name: "",
            value: "",
          },
        };
        console.log("Json", toSaveObject);
        event.target.name = "additionalDetails" + props.heading;
        event.target.value = toSaveObject;
        props.handleChange(event);
        props.handleClose("D" + props.index);
      }
    }
    props.setSaveClicked(false);
  }
  useEffect(() => {
    saveHeaderBidding();
  }, []);
  return <></>;
};

export default SaveHb;
