import React from "react";

function LoadComponent(Component) {
	return function LoadComponentInner({ isLoading, ...props }) {
		if (!isLoading) return <Component {...props} />;
		return (
			<div>
				<p style={{ textAlign: "center", fontSize: "30px" }}>
					Hold on, fetching data may take some time :)
				</p>
			</div>
		);
	};
}

export default LoadComponent;