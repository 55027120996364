import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setLive } from "../redux/actions/commonActions";
import { useEffect } from "react";
export default function ProductEnable(props) {
  const dispatch = useDispatch();
  const isLive = useSelector((state) => state.common.isLive);
  const handleChange = (e) => {
    let status = { ...isLive };
    status[props.heading] = e.target.checked;
    dispatch(setLive(status));
  };
  useEffect(() => {
    if (Object.keys(isLive).length <= 0) {
      let status = {};
      status[props.heading] = true;
      dispatch(setLive(status));
    }
  }, []);
  return (
    <>
      {Object.keys(isLive).length > 0 && (
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>NOT LIVE</Typography>
            <IOSSwitch
              sx={{ m: 1 }}
              defaultChecked={isLive[props.heading]}
              onBlur={handleChange}
            />
            <Typography>LIVE</Typography>
          </Stack>
        </FormGroup>
      )}
    </>
  );
}

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "50ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 250,
    }),
  },
}));
