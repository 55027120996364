import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, } from "@mui/material";
import { setAppDetails, setNotRequiredFields, setObjCounter, setChangedFields, setSubObjCounter, setObjFields, setSubObjFields, } from "../../redux/actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import ObjGroup from "../Objectgroup/ObjGroup";
import productAdditionalFields from "./ProductAdditionalField";
import SaveGist from "./SaveGist";

const AMPGists = (props) => {
  const dispatch = useDispatch();
  const ref = React.useRef();
  const field_data = productAdditionalFields.AMPGists;
  let apDetails_Fields = {};

  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let not_req_fields = [];

  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [gistFormData, setGistFormData] = useState(props.gistFormData);

  const isArr = (el) => {
    return Object.prototype.toString.call(el) === "[object Array]";
  };

  useEffect(() => {
    if (editFlag && gistFormData) {
      field_data.forEach((el) => {
        let field_value = gistFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        apDetails_Fields[el.name] = field_value;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type == "objectgroup") {
          el.fields.forEach((field) => {
            if (!field.isRequired) {
              not_req_fields.push(field.name);
            }
          });
          if (!isArr(field_value)) {
            let temp = [];
            temp.push(field_value);
            field_value = temp;
          }
          setGistObjGroupFields(el, field_value);
        }
      });
    } else {
      field_data.forEach((el) => {
        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] = typeof subfield.default == "object" ? {} : subfield.default;
              });
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object" ? {} : field.default;
            }
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        }
      });
    }
  }, [gistFormData]);

  const setGistObjGroupFields = (el, field_value) => {
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        if (field.type == "objectgroup") {
          subobjectgroup_counter[el.name + field.name] = [];
          subobjectgroup_fields[el.name + field.name] = {};
          for (let i = 0; i < field_value.length; i++) {
            subobjectgroup_counter[el.name + field.name].push([]);
            let len =
              field_value[i][field.name]?.length > 0
                ? field_value[i][field.name]?.length
                : 1;
            for (let j = 0; j < len; j++) {
              field.fields.forEach((subfield, k) => {
                if (
                  field_value[i][field.name] <= 0 ||
                  Object.keys(field_value[i]).length <= 0 ||
                  field_value[i][field.name] === undefined
                ) {
                  subobjectgroup_fields[el.name + field.name][
                    subfield.name + i + j
                  ] = subfield.default;
                } else {
                  subobjectgroup_fields[el.name + field.name][
                    subfield.name + i + j
                  ] =
                    typeof subfield.default == "object"
                      ? subfield.name === "params"
                        ? field_value[i][field.name][j][subfield.name] ?? {}
                        : JSON.stringify(
                            field_value[i][field.name][j][subfield.name]
                          )?.trim() ?? field.default
                      : field_value[i][field.name][j][
                          subfield.name
                        ]?.toString() ?? field.default;
                }
              });
            }
          }
        } else {
          for (let i = 0; i < field_value.length; i++) {
            if (field_value[i] !== undefined) {
              if (
                field_value[i][field.name] === undefined ||
                Object.keys(field_value[i]).length <= 0
              ) {
                objectgroup_fields[el.name][field.name + i] = field.default;
              } else {
                objectgroup_fields[el.name][field.name + i] =
                  typeof field.default == "object"
                    ? JSON.parse(
                        JSON.stringify(field_value[i][field.name])?.trim()
                      ) ?? field.default
                    : field_value[i][field.name]?.toString() ?? field.default;
              }
            }
          }
        }
      });
    }
  };
  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setSubObjCounter(subobjectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);


  return (
    <span>
      <ObjGroup
        field_data={field_data}
        fieldSet={props.fieldSet}
        heading={props.heading}
      />
      {props.saveClicked ? <SaveGist {...props} /> : <></>}
    </span>
  );
};

export default AMPGists;
