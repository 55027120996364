import React, { useEffect, useState } from "react";
import { Fab, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import axios from "axios";
function ClientDetails(props) {
  function backToClient() {
    props.changeViewClient();
  }
  // console.log(props.clients.row.brand)
  function editClientDetails() {
    const result = () => {
      // console.log(cdn)
      props.clients.row.website[0]?.products?.map(async (item) => {
        // if (item.productName === "VideoPlayer") {
        //   const url = `https://cdn.unibots.in/clientdata/${props.clients.row.brand}.json`;
        //   fetch(url)
        //     .then((res) => res.json())
        //     .then((data) => {
        //       if (item.additionalDetails.Edit_Warning || data.Edit_Warning) {
        //         alert(
        //           "This client is modified manually, kindly confirm to VP team before proceeding...!"
        //         );
        //       }
        //     });
        // }
        let url, team ;
        switch (item.productName) {
          case "VideoPlayer":
            url = `https://cdn.unibots.in/clientdata/${props.clients.row.brand}.json`;
           console.log('url1=',url);
            team = "VP";
            break;
          case "HeaderBidding":
           url = `https://cdn.unibots.in/headerbidding/${props.clients.row.brand}.json`;
           console.log('url=',url);
            team = "HB";
            break;
          default:
            break;
        }
        fetch(url)
            .then((res) => res.json())
            .then((data) => {
              if (item.additionalDetails.Edit_Warning || data.Edit_Warning) {
                alert(
                  `This client is modified manually, kindly confirm to ${team} team before proceeding...!`
                );
              }
            });
      });
    };
    result();
    props.changeViewClient();
    props.changeAddClient();
    props.editClientPage();
  }

  function Products(props) {
    const prod_list = props.val?.map((data, index) => (
      <li key={index}>
        <b>Product Name: </b>
        {data.productName}
        <br />
        <b>Deal: </b>
        {data.deal}
      </li>
    ));
    return prod_list ?? [];
  }
  function Websites(props) {
    const web_list = props.val.map((data, index) => (
      <Grid data xs={12} sm={6} lg={4} xl={4}>
        <Card key={index} sx={{ margin: "10px" }}>
          <CardHeader title={data.websiteName} subheader="Website Name" />
          <CardContent>
            <ul>
              <Products val={data.products} />
            </ul>
          </CardContent>
        </Card>
      </Grid>
    ));
    return web_list;
  }
  return (
    <Container>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        sx={{ mb: 2 }}
      >
        <Button variant="contained" onClick={editClientDetails}>
          Edit Client Details
        </Button>
        <Button variant="contained" onClick={backToClient}>
          Back
        </Button>
      </Stack>
      <Card>
        <CardHeader
          title={props.clients.row.brand}
          subheader="Brand Name"
        ></CardHeader>
        <CardContent>
          <p style={{ textAlign: "right", marginTop: "-80px" }}>
            <div>
              <span style={{ position: "relative", top: "-5px" }}>
                {props.clients.row.contact.name}
              </span>

              <Icon sx={{ fontSize: 25 }}>person </Icon>
            </div>

            <div>
              <span style={{ position: "relative", top: "-5px" }}>
                {props.clients.row.contact.email}
              </span>
              <Icon sx={{ fontSize: 25 }}>email </Icon>
            </div>
            <div>
              <span style={{ position: "relative", top: "-5px" }}>
                {props.clients.row.contact.country}
              </span>
              <Icon sx={{ fontSize: 25 }}>place </Icon>
            </div>
            <div>
              <span style={{ position: "relative", top: "-5px" }}>
                {props.clients.row.contact.phone}
              </span>
              <Icon sx={{ fontSize: 25 }}>phone </Icon>
            </div>
          </p>
          <Card>
            <CardHeader title="Account Details" />
            <CardContent>
              <Typography sx={{ fontSize: 14 }}>
                <b>Bank Account Name: </b>
                {props.clients.row.banking.acName}
                <br />
                <b>Account number: </b>
                {props.clients.row.banking.acn}
                <br />
                <b>Country: </b>
                {props.clients.row.banking.country}
                <br />
                <b>IFSC code: </b>
                {props.clients.row.banking.ifsc}
                <br />
                <b>Swift : </b>
                {props.clients.row.banking.swift}
                <br />
              </Typography>
            </CardContent>
          </Card>
          <p>
            <Card>
              <CardHeader title="Websites" />
              <Grid container>
                <Websites val={props.clients.row.website} />
              </Grid>
            </Card>
          </p>
        </CardContent>
      </Card>
    </Container>
    //<h1 style={{'color':'white'}}>{props.clients.row.banking.acName}</h1>
  );
}
export default ClientDetails;
