import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { alpha, styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
// import Chip from '@mui/material/Chip';
import axios from "axios";
// import Alert from '@mui/material/Alert';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import InputLabel from '@mui/material/InputLabel';
import "../css/style.css";

import { Alert } from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import {
  setJsFile,
  setCssFile,
  setNotice,
  setChangedFields,
} from "../../redux/actions/commonActions";
import NoticeBox from "../../utils/NoticeBox";
import TabComponent from "./TabComponent";

const DataTable = React.memo((props) => {
  // console.log('props in addClient',props);
  const dispatch = useDispatch();
  const cssFile = useSelector((state) => state.common.cssFile);
  const jsFile = useSelector((state) => state.common.jsFile);
  const notice = useSelector((state) => state.common.notice);
  const isLive = useSelector((state) => state.common.isLive);
  const changedFields = useSelector((state) => state.common.changedField);
  const userData = useSelector((state) => state.userData);
  const user = userData.user_name;

  let initialFormData = {
    clientName: "",
    website: [],
    deal: "",
    contactName: "",
    contactNumber: "",
    contactMail: "",
    contactCountry: "",
    acName: "",
    acNumber: "",
    ifsc: "",
    swift: "",
    acCountry: "",
  };
  const products = [
    { name: "VideoPlayer", label: "Video Player" },
    { name: "HeaderBidding", label: "Header Bidding" },
    { name: "NewsBot", label: "NewsBot" },
    { name: "EventsWidget", label: "Events Widget" },
    { name: "CricketWidget", label: "Cricket Widget" },
    { name: "AppMonetization", label: "App Monetization" },
    { name: "GenericCube", label: "Generic Cube" },
    { name: "DirectCampaign", label: "Direct Campaign" },
    { name: "AMPGists", label: "AMP Gists" },
  ];
  const [modelOpen, setModelOpen] = useState(false);
  const handleModelOpen = () => setModelOpen(true);
  const handleModelClose = () => setModelOpen(false);
  const [saveEnable, setSaveEnable] = useState(true);
  const productsAccess = userData.access.products;

  let initialWeb = [{}];
  let initialProd = [[{}]];
  let product_list = [];
  const [nestCheck, setNestCheck] = useState([]);
  const [dbMatch, setDbMatch] = useState(null);
  // const [logCount, setLogCount] = useState(0);
  const setMatch = () => {
    console.log("seeting db match to true");
    setDbMatch(1);
  };

  useEffect(() => {
    if (dbMatch === 1) {
      handleSubmit();
    }
  }, [dbMatch]);

  if (props.editClient) {
    initialFormData.clientName = props.clientData.brand;
    initialFormData.contactName = props.clientData.contact.name;
    initialFormData.contactMail = props.clientData.contact.email;
    initialFormData.contactNumber = props.clientData.contact.phone;
    initialFormData.contactCountry = props.clientData.contact.country;
    initialFormData.acNumber = props.clientData.banking.acn;
    initialFormData.swift = props.clientData.banking.swift;
    initialFormData.ifsc = props.clientData.banking.ifsc;
    initialFormData.acName = props.clientData.banking.acName;
    initialFormData.acCountry = props.clientData.banking.country;
    for (let i = 0; i < props.clientData.website.length; i++) {
      initialFormData["website" + i] = props.clientData.website[i].websiteName;
      if (i > 0) {
        initialWeb = [...initialWeb, {}];
        initialProd = [...initialProd, [{}]];
      }
      for (let j = 0; j < props.clientData.website[i].products?.length; j++) {
        let product_name = props.clientData.website[i].products[j].productName;
        initialFormData["product" + j] =
          props.clientData.website[i].products[j].productName;
        product_list.push(props.clientData.website[i].products[j].productName);
        initialFormData["deal" + j] =
          props.clientData.website[i].products[j].deal;
        initialFormData["additionalDetails" + product_name] =
          props.clientData.website[i].products[j].additionalDetails;
        if (j > 0) {
          initialProd = [
            ...initialProd.slice(0, i),
            [...initialProd[i], {}],
            ...initialProd.slice(i + 1),
          ];
        }
      }
    }
  }
  const [formData, setFormData] = useState(initialFormData);
  let allowed_prods = product_list.filter((prod) =>
    productsAccess.includes(prod)
  );
  if (allowed_prods.length === 0) {
    for (let i = 0; i < products.length; i++) {
      if (productsAccess.includes(products[i].name)) {
        allowed_prods.push(products[i].name);
      }
    }
  }
  const [currentProduct, setcurrentProduct] = useState(allowed_prods[0]);
  const [productList, setProductList] = useState(product_list);
  const [success, setSuccess] = useState(undefined);
  const [wasAdded, setWasAdded] = useState(0);
  const [websiteCounter, setWebsiteCounter] = useState(initialWeb);
  const [productCounter, setProductCounter] = useState(initialProd);
  // console.log("clientData", props.clientData);
  const [open, setOpen] = useState({ D0: false });
  const [code_open, setCodeOpen] = useState({ D0: false });
  const handleClickOpen = (D_key) => {
    if (formData.clientName !== "") {
      if (currentProduct === "HeaderBidding") {
        setSuccess("wait");
        const url = process.env.REACT_APP_API_URL + "client/getScript";
        const options = {
          method: "POST",
          brand: formData["clientName"],
          productName: currentProduct,
        };
        axios
          .post(url, options)
          .then((response) => {
            const data = response.data;
            dispatch(setCssFile({ ...cssFile, file: data.cssFile }));
            dispatch(setJsFile({ ...jsFile, file: data.jsFile }));
            setSuccess(undefined);
            setOpen((prevData) => {
              return {
                ...prevData,
                [D_key]: true,
              };
            });
          })
          .catch((error) => {
            let text = "Could not get script. Please try refreshing.";
            alert(text);
            dispatch(setCssFile({ file: text, save: false }));
            dispatch(setJsFile({ file: text, save: false }));
            setSuccess(undefined);
            setOpen((prevData) => {
              return {
                ...prevData,
                [D_key]: true,
              };
            });
          });
      } else {
        console.log("client==",currentProduct);
        setOpen((prevData) => {
          return {
            ...prevData,
            [D_key]: true,
          };
        });
      }
    } else {
      props.showAlertMsg("Please fill client name!", "error");
    }
  };
  const handleGenerateCodeOpen = (D_key) => {
    dispatch(
      setChangedFields([...changedFields, { name: "Code", info: "Generated" }])
    );
    setCodeOpen((prevData) => {
      return {
        ...prevData,
        [D_key]: true,
      };
    });
  };
  const handleClose = (D_key) => {
    setOpen((prevData) => {
      return {
        ...prevData,
        [D_key]: false,
      };
    });
  };

  const handleGenerateCodeClose = (D_key) => {
    setCodeOpen((prevData) => {
      return {
        ...prevData,
        [D_key]: false,
      };
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "currentproduct") {
      setcurrentProduct((prev) => event.target.value.trim());
    } else {
      setFormData((prevData) => {
        return {
          ...prevData,
          [event.target.name]: event.target.name === 'clientName' ? event.target.value.trim() : event.target.value,
        };
      });
    }
    props.changeDone(true);
  };

  let addClientToggle = () => {
    if (wasAdded > 0) {
      props.fetchClients();
    }
    props.changeAddClient();
    props.notEditClientPage();
    dispatch(setNotice(""));
    // dispatch(setJsFile(""));
    // dispatch(setCssFile(""));
  };
  let checkChange = dbMatch;
  let AddNewProduct = (index) => {
    setProductCounter((prevData) => {
      console.log(prevData);
      return [
        ...prevData.slice(0, index),
        [...prevData[index], {}],
        ...prevData.slice(index + 1),
      ];
    });
  };

  const AddNewWebsite = () => {
    setWebsiteCounter((prevData) => {
      return [...prevData, {}];
    });
    setProductCounter((prevData) => {
      return [...prevData, [{}]];
    });
  };
  const deleteWebsite = (i) => {
    let webCounter = [...websiteCounter];
    let prodCounter = [...productCounter];
    webCounter.splice(i, 1);
    prodCounter.splice(i, 1);
    setWebsiteCounter(webCounter);
    setProductCounter(prodCounter);
  };
  useEffect(() => {
    dispatch(setJsFile({ ...jsFile, file: "" }));
    dispatch(setCssFile({ ...cssFile, file: "" }));
  }, [currentProduct]);
  const handleSubmit = (e) => {
    if (saveEnable) {
      setSaveEnable(false);
      let adddetailsFlag = true;
      let now = new Date(Date.now());
      now = now.toString();
      const moment = now.split(" ").slice(1, 5).join(" ");
      let trackedChanges = {
        user: user,
        time: moment,
        product: currentProduct,
        changes: [],
      };
      if (props.editClient && changedFields.length > 0) {
        // console.log("changedFields =", changedFields);
        changedFields.map((item) => {
          if (!item.info) {
            trackedChanges.changes.push(
              `${item.name}: ${item.valueCDN} => ${item.valueDB}`
            );
          } else {
            trackedChanges.changes.push(`${item.name}: ${item.info}`);
          }
        });
      }

      if (changedFields.length === 0 && checkChange === 0) {
        trackedChanges.changes.push("No Change in additional fields");
      } else if (changedFields.length === 0 && checkChange) {
        trackedChanges.changes.push("Json matched with Db");
      }
      e?.preventDefault();
      let websites = [];

      websiteCounter.map((elem, index) => {
        let prodCounter = JSON.parse(JSON.stringify(productCounter));
        if (!productList.includes(currentProduct) && props.editClient) {
          prodCounter = productCounter;
          prodCounter[index].push({});
          setProductCounter((prev) => prodCounter);
        }
        let webObject = {};
        webObject.websiteName = formData["website" + index];
        webObject.products = [];
        prodCounter[index].map((elem_inner, i) => {
          let innerObj = {};
          let product_name = formData["product" + i] ?? currentProduct;
          innerObj.productName = product_name;
          innerObj.deal = formData["deal" + index];
          if (formData["additionalDetails" + product_name]) {
            innerObj.additionalDetails =
              formData["additionalDetails" + product_name];
          } else adddetailsFlag = false;
          let duplicate = webObject.products.some((prod) => {
            return prod.productName === product_name;
          });
          if (!duplicate) {
            webObject.products.push(innerObj);
          }
        });
        websites.push(webObject);
      });

      if (adddetailsFlag) {
        let reqObj = {
          data: {
            brand: formData.clientName,
            website: websites,
            deal: formData.deal,
            contact: {
              name: formData.contactName,
              email: formData.contactMail,
              phone: formData.contactNumber,
              country: formData.contactCountry,
            },
            banking: {
              acn: formData.acNumber,
              swift: formData.swift,
              ifsc: formData.ifsc,
              acName: formData.acName,
              country: formData.acCountry,
            },
            isLive: isLive,
          },
          notice: notice,
          jsFile: jsFile,
          cssFile: cssFile,
          productName: currentProduct,
          updatedFields: trackedChanges,
        };
        let url = props.editClient
          ? process.env.REACT_APP_API_URL + "client/updateClient"
          : process.env.REACT_APP_API_URL + "client/add";
        setSuccess("wait");
        axios
          .post(url, reqObj)
          .then((response) => {
            if (response.status === 200 && !response.data.error) {
              if (response.data.duplicate) {
                props.showAlertMsg(response.data.message, "error");
                setSaveEnable(true);
              } else {
                props.changeDone(false);
                setSuccess(true);
                setTimeout(() => {
                  setSaveEnable(true);
                  return setSuccess(undefined);
                }, 2000);
                setWasAdded((prev) => {
                  return prev + 1;
                });
              }
            } else {
              setSaveEnable(true);
              setSuccess(false);
            }
          })
          .catch((err) => {
            setSaveEnable(true);
            return props.editClient
              ? "error Occurred While Editing Clients"
              : console.log("error Occurred While Adding Clients");
          });
      } else {
        setSaveEnable(true);
        props.showAlertMsg("Please add additional product details", "error");
      }
    }
  };
  let logCount = 1;
  const getChangesLength = () => {
    let changes = props.clientData?.updatedData.filter((item) => {
      if (
        !item.product &&
        item.changes.length > 0 &&
        currentProduct === "VideoPlayer"
      ) {
        return true;
      } else return item.product === currentProduct;
    });
    return changes.length;
  };
  
  return (
    <div style={{ height: "700px", width: "100%" }}>
      {modelOpen && (
        <Dialog open={modelOpen} fullWidth maxWidth="xl">
          <DialogTitle sx={{ backgroundColor: "#424242", color: "white" }}>
            Changes: {props.clientData.brand} ({currentProduct})
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#424242", color: "white" }}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {props?.clientData?.updatedData?.length === 0 && (
                <h1>No changes yet</h1>
              )}
              {props?.clientData?.updatedData
                ?.slice(0)
                .reverse()
                .map((item, index) => {
                  if ( (!item.product && item.changes.length > 0 && currentProduct === "VideoPlayer") || item.product === currentProduct ) {
                    {/* console.log("item changes",item.changes); */}
                    return (
                      <>
                        <p>
                          {`${logCount++}. On ${item?.time}: By User - `}
                          <span style={{ fontWeight: "Bold" }}>{`${
                            item?.user !== undefined ? item?.user : "Unknown"
                          }`}</span>
                        </p>
                        <ul>
                          {item?.changes.map((change) => (
                            <li>{change}</li>
                          ))}
                        </ul>
                      </>
                    );
                  } else {
                    return <></>;
                  }
                })}
            </Typography>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: "#424242",
              color: "white",
              p: 2,
            }}
          >
            <Button
              style={{ backgroundColor: "#9575cd", color: "white" }}
              onClick={() => {
                setModelOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {(() => {
        if (success !== undefined) {
          if (success === true) {
            return (
              <span style={{ position: "fixed", width: "75%", zIndex: 1000 }}>
                <Alert
                  severity="success"
                  onClose={() => {
                    setSuccess(undefined);
                  }}
                  sx={{ mb: 2 }}
                >
                  {props.editClient
                    ? "The Client is Modified Successfully!"
                    : "The Client is Added Successfully!"}
                </Alert>
              </span>
            );
          } else if (success === "wait") {
            return (
              <span style={{ position: "fixed", width: "75%", zIndex: 1000 }}>
                <Alert
                  severity="info"
                  onClose={() => {
                    setSuccess(undefined);
                  }}
                  sx={{ mb: 2 }}
                >
                  Please Wait!
                </Alert>
              </span>
            );
          } else {
            return (
              <span style={{ position: "fixed", width: "75%" }}>
                <Alert
                  severity="error"
                  sx={{ mb: 2 }}
                  onClose={() => {
                    setSuccess(undefined);
                  }}
                >
                  The client could not be added!
                </Alert>
              </span>
            );
          }
        }
      })()}
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        {props.editClient ? (
          <Badge
            badgeContent={getChangesLength()}
            color="primary"
            onClick={handleModelOpen}
            style={{ cursor: "pointer" }}
          >
            <MailIcon color="white" />
          </Badge>
        ) : (
          ""
        )}
        <Button
          variant="outlined"
          color="error"
          onClick={addClientToggle}
          endIcon={<CancelIcon />}
        >
          <Box sx={{ height: "22px" }}> Cancel</Box>
        </Button>
      </Stack>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {currentProduct === "VideoPlayer" && <NoticeBox />}
        <TabComponent
          formData={formData}
          AddNewWebsite={AddNewWebsite}
          deleteWebsite={deleteWebsite}
          websiteCounter={websiteCounter}
          currentProduct={currentProduct}
          setcurrentProduct={setcurrentProduct}
          handleClickOpen={handleClickOpen}
          handleGenerateCodeOpen={handleGenerateCodeOpen}
          editClient={props.editClient}
          handleSubmit={handleSubmit}
          setMatch={setMatch}
          code_open={code_open}
          open={open}
          handleClose={handleClose}
          handleChange={handleChange}
          handleGenerateCodeClose={handleGenerateCodeClose}
        />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Stack direction="row" spacing={1} sx={{ p: 2 }}>
            <Button
              type="submit"
              sx={{ mt: 2, ml: 2 }}
              variant="contained"
              disabled={!saveEnable}
            >
              Save
            </Button>
          </Stack>
        </Box>
      </Box>
    </div>
  );
});

export default DataTable;
