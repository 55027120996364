import React, { useState } from "react";
import { Button, FormControl, Typography } from "@mui/material";

const Upload = (props) => {
  const [fileName, setFileName] = useState("");
  const data = props.data;
  // console.log("prodDetails", data.prodDetails);
  const field = props.field;
  let path = field.path;
  let heading = props.data.heading;
  heading = heading.toLowerCase();
  let name =
    data.name !== undefined
      ? data.name + "#" + field.name + data.index
      : field.name;
  console.log("prodDetails", data.prodDetails);

  let value =
    data.prodDetails[field.name + data.index] ?? data.prodDetails[field.name];

  const handleFileChange = (e) => {
    const { handleUploadChange, handleSubChange } = data;
    const file = e.target.files[0];
    const fn = file ? file.name : '';
    setFileName(fn);
    handleUploadChange(e, handleSubChange, name);
  };

  const handleFileClick = (e) => {
    console.log("data.name",data.name,"data.index",data.index);
    console.log("data.heading->",heading);
    e.target.value = "";
    if (heading !== "newsbot") {
      data.handleSubChange(null, "", data.name + "#" + "img_link" + data.index);
    }else {
      data.handleSubChange(null, "", data.name + data.index);
    }
  };
  return (
    <FormControl
      component="fieldset"
      disabled={props.disabled}
      sx={{
        m: 2,
        width: "15%",
      }}
      onClick={(e)=>{
        // handleFileClick(e)
        e.target.value = "";
      const selectedFile = e.target.files[0];
      const filename = selectedFile ? selectedFile.name : "";
      data.handleSubChange(null, filename, name);
      data.handleSubChange(null, filename, data.name + "#" + "img_link" + data.index);
      }}
    >
      <Button
        variant="contained"
        component="label"
        sx={{ textTransform: "none" }}
      >
        {field.label}
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={(e) => {handleFileChange(e)}}
          // onClick={(e)=>{
          //   // handleFileClick(e)
          //   e.target.value = "";
          // const selectedFile = e.target.files[0];
          // const filename = selectedFile ? selectedFile.name : "";
          // data.handleSubChange(null, filename, name);
          // data.handleSubChange(null, filename, data.name + "#" + "img_link" + data.index);
          // }}
          // onChange={(e) => {
          //   data.handleUploadChange(e, data.handleSubChange, name);
          // }}
          // onClick={(e) => {
          //   if(heading != "newsbot"){           
          //   e.target.value = "";
          //   console.log("in upload name", name)
          //   data.handleSubChange(
          //     null,
          //     "",
          //     data.name + "#" + "img_link" + data.index
          //   );}
          // }}
        />
      </Button>
      <Typography>
      {(heading != "newsbot") ? (value && value.length > 0 ? value  : "No file chosen") : (fileName ? fileName : "No file chosen") }
      </Typography>
    </FormControl>
  );
};

export default Upload;