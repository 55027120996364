import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  IconButton,
} from "@mui/material";
import DropdownMultiInput from "./DropdownMultiInput";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ScriptInput from "../../utils/ScriptInput";
import Button from "@mui/material/Button";

const RadioInput = (props) => {
  const data = props.data;
  const field = props.field;
  const [open, setOpen] = useState({});
  let value =
    data.prodDetails[field.name + data.index] ?? data.prodDetails[field.name];
  return (
    <span>
      <FormControl
        component="fieldset"
        disabled={props.disabled}
        sx={{
          m: 2,
          p: field.textbox ? 1 : 0,
          border: field.textbox ? "1px solid #000" : "",
        }}
      >
        <FormLabel sx={{ fontSize: "13px" }} component="legend">
          {field.label}
        </FormLabel>
        <RadioGroup
          row
          aria-label={field.label}
          name={
            data.name !== undefined
              ? data.name + "#" + field.name + data.index
              : field.name
          }
          value={value?.toString() ?? field.default?.toString()}
          required={field.isRequired}
          onChange={(e) =>
            data.handleSubChange(e, field.isBool ? "boolean" : "string")
          }
        >
          {field.selectOptions.map((el, index) => {
            return (
              <FormControlLabel value={el} control={<Radio />} label={el} />
            );
          })}
          {field.textbox && value ? (
            <>
              <IconButton
                className={field.name}
                color="primary"
                onClick={(e) => {
                  setOpen((prev) => {
                    return {
                      ...prev,
                      [field.name]: true,
                    };
                  });
                }}
              >
                <AddCircleIcon />
              </IconButton>
              {open[field.name] ? (
                <ScriptInput
                  open={open}
                  setOpen={setOpen}
                  type={(field.name === "CUSTOM_ADS_DIV"|| field.name ===  "CUSTOM_ADS_JS") ? "js" : "css"}
                  name={field.name}
                  heading={data.heading.toLowerCase()}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </RadioGroup>
      </FormControl>
      {field.name === "Labeltype" &&
      value !== "none" &&
      data.countryCodes.length > 0 ? (
        <DropdownMultiInput
          field={{
            name: "CountryCode",
            label: "Select Country",
            selectOptions: data.countryCodes,
          }}
          data={{
            ...data,
            prodDetails:
              typeof data.index === "number" ? data.label : data.innerLabel,
          }}
          handleChange={(e) => {
            data.handleSubChange(e, field.isNum ? "number" : "string");
            if (typeof data.index === "number") {
              data.labelChange(e);
            } else {
              data.innerLabelChange(e);
            }
          }}
        />
      ) : (
        <></>
      )}
    </span>
  );
};

export default RadioInput;
