import {
  FormControlLabel,
  TextField,
  Button,
  Switch,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import productAdditionalFields from "./ProductAdditionalField";
import {
  setAppDetails,
  setChangedFields,
  setObjCounter,
  setSubObjCounter,
  setObjFields,
  setSubObjFields,
  setNotRequiredFields,
} from "../../redux/actions/commonActions";
import ObjGroup from "../Objectgroup/ObjGroup";
import { trackChange } from "../../utils/TrackChange";
const VideoPlayer = (props) => {
  const dispatch = useDispatch();
  const field_data = props.prodFields?.VideoPlayer ?? [];
  const userData = useSelector((state) => state.userData);
  const [codesDisabled, setCodesDisabled] = useState(false);
  let apDetails_Fields = {};
  let initialBaseCode = "";
  let floor_price1 = "0.15, 0.20, 0.25";
  let floor_price2 = "0.15, 0.20, 0.25";
  let rem_floor_price1 = false;
  let rem_floor_price2 = false;

  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let not_req_fields = [];

  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const overrideFields = useSelector((state) => state.common.overrideFields);
  const changedFields = useSelector((state) => state.common.changedField);
  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subObjectGroupCounter = useSelector(
    (state) => state.common.subObjectGroupCounter
  );
  const subObjectGroupDetails = useSelector(
    (state) => state.common.subObjectGroupDetails
  );

  const [genArrchecked, setGenArrChecked] = useState(false); // for vp AD_codes
  const [remFloorPrice, setRemFloorPrice] = useState(rem_floor_price1); // for vp AD_codes
  const [remFloorPrice2, setRemFloorPrice2] = useState(rem_floor_price2); // for vp AD_codes
  const [baseAdCode, setBaseAdCode] = useState(initialBaseCode); // for vp AD_codes
  const [floorPrice, setFloorPrice] = useState(floor_price1); // for vp AD_codes
  const [floorPrice2, setFloorPrice2] = useState(floor_price2); // for vp AD_codes
  const [run, setRun] = useState(true);
  const [floor, setFloor] = useState(true);
  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [vpFormData, setVpFormData] = useState(props.vpFormData);
  const [open, setOpen] = useState({});
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  useEffect(() => {
    setCodesDisabled(
      !userData?.access?.fields["VideoPlayer"].includes("AD_CODES")
    );
  }, [userData]);
  useEffect(() => {
    if (editFlag && vpFormData) {
      field_data.forEach((el) => {
        let field_value = vpFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        apDetails_Fields[el.name] = field_value;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type == "objectgroup") {
          el.fields.forEach((field) => {
            if (!field.isRequired) {
              not_req_fields.push(field.name);
            }
          });
          if (!isArr(field_value)) {
            let temp = [];
            temp.push(field_value);
            field_value = temp;
          }
          setVpObjGroupFields(el, field_value);
        }
      });
      editCustomFields();
    } else {
      field_data.forEach((el) => {
        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] = typeof subfield.default == "object" ? {} : subfield.default;
              });
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object" ? {} : field.default;
            }
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        }
      });
    }
  }, [vpFormData]);

  useEffect(() => {
    let gpt1Removed = false;
    let gpt2Removed = false;
    if (addProdDetails["AD_CODES"]?.length > 0) {
      gpt1Removed = addProdDetails["AD_CODES"]?.every((adCode) => {
        if (
          (adCode.includes("gpt1") && !adCode.includes("KV1")) ||
          !adCode.includes("gpt1")
        ) {
          return true;
        }
      });
      gpt2Removed = addProdDetails["AD_CODES"]?.every((adCode) => {
        if (
          (adCode.includes("gpt2") && !adCode.includes("KV1")) ||
          !adCode.includes("gpt2")
        ) {
          return true;
        }
      });
    }
    setRemFloorPrice(gpt1Removed);
    setRemFloorPrice2(gpt2Removed);
  }, [addProdDetails["AD_CODES"]]);

  function editCustomFields() {
    let adCode1 = apDetails_Fields?.AD_CODES[0] ?? [];
    let adCode2 = apDetails_Fields?.AD_CODES[1] ?? [];
    // if (adCode1.includes("gpt1") && !adCode1.includes("KV1")) {
    //   handleRemoveFloorPrice();
    // }
    // if (adCode2.includes("gpt2") && !adCode2.includes("KV1")) {
    //   handleRemoveFloorPrice2();
    // }
    // if (adCode1.includes("gpt1") && adCode2.includes("gpt1")) {
    //   handleRemoveFloorPrice2();
    // }
    // if (adCode1.includes("gpt2") && adCode2.includes("gpt2")) {
    //   handleRemoveFloorPrice();
    // }
    apDetails_Fields["SIZE"] =
      apDetails_Fields["PLAYER_WIDTH"].toString() +
      "x" +
      apDetails_Fields["PLAYER_HEIGHT"].toString();
    apDetails_Fields["SIZE_MB"] =
      apDetails_Fields["PLAYER_WIDTH_MB"].toString() +
      "x" +
      apDetails_Fields["PLAYER_HEIGHT_MB"].toString();
    if (apDetails_Fields["AD_CODES"][0]?.length > 10 && apDetails_Fields["AD_CODES"][0].includes("https://pubads.g.doubleclick.net/gampad/ads?iu=")) {
      let basecode1 = apDetails_Fields["AD_CODES"][0]
        .split("https://pubads.g.doubleclick.net/gampad/ads?iu=")[1]
        .split("&tfcd=0&npa=0&sz=1x1%7C192")[0]
        .replace(/\s+/g, "");
        console.log("basecode1=",basecode1);
      let basecode2 = apDetails_Fields["AD_CODES"][1]
        .split("https://pubads.g.doubleclick.net/gampad/ads?iu=")[1]
        .split("&tfcd=0&npa=0&sz=1x1%7C192")[0]
        .replace(/\s+/g, "");
        console.log("basecode2=",basecode2);

      initialBaseCode = basecode1 + "\n" + basecode2;
      if (run) {
        setBaseAdCode(initialBaseCode);
        setRun(false);
      }

      const remFloorSuffix =
        "&tfcd=0&npa=0&sz=1x1%7C192x108%7C320x180%7C400x225%7C640x360&gdfp_req=1&output=xml_vast4&unviewed_position_start=1&env=vp&impl=s&correlator=&adsafe=medium&hl=en&vpos=preroll";
      const suffix =
        "&tfcd=0&npa=0&sz=1x1%7C192x108%7C320x180%7C400x225%7C640x360&gdfp_req=1&output=xml_vast4&unviewed_position_start=1&env=vp&impl=s&correlator=&adsafe=medium&hl=en&vpos=preroll&cust_params=KV1%3D";
      let gp1_exist = apDetails_Fields["AD_CODES"][0].split(remFloorSuffix);
      let gp2_exist = apDetails_Fields["AD_CODES"][1].split(remFloorSuffix);

      if (gp1_exist[1]) {
        floor_price1 =
          apDetails_Fields["AD_CODES"][0].split(suffix)[1] +
          ", " +
          apDetails_Fields["AD_CODES"][26].split(suffix)[1] +
          ", " +
          apDetails_Fields["AD_CODES"][49].split(suffix)[1];
        if (floor) {
          setFloorPrice(floor_price1);
          setFloor(true);
        }
      } else rem_floor_price1 = true;
      if (gp2_exist[1]) {
        floor_price2 =
          apDetails_Fields["AD_CODES"][1].split(suffix)[1] +
          ", " +
          apDetails_Fields["AD_CODES"][27].split(suffix)[1] +
          ", " +
          apDetails_Fields["AD_CODES"][50].split(suffix)[1];
        if (floor) {
          setFloorPrice2(floor_price2);
          setFloor(false);
        }
      } else rem_floor_price2 = true;
    }
  }

  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setSubObjCounter(subobjectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      dispatch(setNotRequiredFields(not_req_fields));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  /* for vp AD_codes */
  const handleFloorPriceChange = (event) => {
    setFloorPrice(event.target.value);
  };
  /* for vp AD_codes */
  const handleFloorPriceChange2 = (event) => {
    setFloorPrice2(event.target.value);
  };

  /* for vp AD_codes */
  const handlebaseAdCode = (event) => {
    setBaseAdCode(event.target.value);
  };

  /* for vp AD_codes */
  const handleRemoveFloorPrice = (value) => {
    setRemFloorPrice(!remFloorPrice);
  };

  /* for vp AD_codes */
  const handleRemoveFloorPrice2 = (value) => {
    setRemFloorPrice2(!remFloorPrice2);
  };

  /* for vp AD_codes */
  const handleGenArrSwitch = (event) => {
    setGenArrChecked(event.target.checked);
  };

  const setVpObjGroupFields = (el, field_value) => {
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        if (field.type == "objectgroup") {
          subobjectgroup_counter[el.name + field.name] = [];
          subobjectgroup_fields[el.name + field.name] = {};
          for (let i = 0; i < field_value.length; i++) {
            subobjectgroup_counter[el.name + field.name].push([]);
            let len =
              field_value[i][field.name]?.length > 0
                ? field_value[i][field.name]?.length
                : 1;
            if (field.name === "ub_ClmbParams") {
              subobjectgroup_counter[el.name + field.name][i].push({});
            }
            for (let j = 0; j < len; j++) {
              if (field.name !== "ub_ClmbParams") {
                subobjectgroup_counter[el.name + field.name][i].push({});
              }

              field.fields.forEach((subfield, k) => {
                if (
                  field_value[i][field.name] <= 0 ||
                  Object.keys(field_value[i]).length <= 0 ||
                  field_value[i][field.name] === undefined
                ) {
                  subobjectgroup_fields[el.name + field.name][
                    subfield.name + i + j
                  ] = subfield.default;
                } else {
                  if (field.name === "ub_ClmbParams") {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + 0
                    ] =
                      field_value[i][field.name][k] !== undefined
                        ? field_value[i][field.name][k][subfield.name] ??
                          subfield.default
                        : subfield.default;
                  } else {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + j
                    ] =
                      typeof subfield.default == "object"
                        ? subfield.name === "params"
                          ? field_value[i][field.name][j][subfield.name] ?? {}
                          : JSON.stringify(
                              field_value[i][field.name][j][subfield.name]
                            )?.trim() ?? field.default
                        : field_value[i][field.name][j][
                            subfield.name
                          ]?.toString() ?? field.default;
                  }
                }
              });
            }
          }
        } else {
          for (let i = 0; i < field_value.length; i++) {
            if (field_value[i] !== undefined) {
              if (
                field_value[i][field.name] === undefined ||
                Object.keys(field_value[i]).length <= 0
              ) {
                objectgroup_fields[el.name][field.name + i] = field.default;
              } else {
                objectgroup_fields[el.name][field.name + i] =
                  typeof field.default == "object"
                    ? JSON.parse(
                        JSON.stringify(field_value[i][field.name])?.trim()
                      ) ?? field.default
                    : field_value[i][field.name]?.toString() ?? field.default;
              }
            }
          }
        }
      });
    }
  };

  /* for vp AD_codes */
  const generateADCode = () => {
    let ad_codes = [];
    let gp1_price = floorPrice.replace(/\s+/g, "").split(",");
    let gp2_price = floorPrice2.replace(/\s+/g, "").split(",");
    let baseCode = baseAdCode.split("\n");
    for (let i = 0; i < baseCode.length; i++) {
      baseCode[i] = baseCode[i].replace(/\s+/g, "");
    }
    const prefix = "https://pubads.g.doubleclick.net/gampad/ads?iu=";
    const suffix =
      "&tfcd=0&npa=0&sz=1x1%7C192x108%7C320x180%7C400x225%7C640x360&gdfp_req=1&output=xml_vast4&unviewed_position_start=1&env=vp&impl=s&correlator=&adsafe=medium&hl=en&vpos=preroll&cust_params=KV1%3D";
    const remFloorSuffix =
      "&tfcd=0&npa=0&sz=1x1%7C192x108%7C320x180%7C400x225%7C640x360&gdfp_req=1&output=xml_vast4&unviewed_position_start=1&env=vp&impl=s&correlator=&adsafe=medium&hl=en&vpos=preroll";

    for (let i = 0; i < 26; i++) {
      if (i % 2 == 0) {
        if (remFloorPrice) ad_codes.push(prefix + baseCode[0] + remFloorSuffix);
        else ad_codes.push(prefix + baseCode[0] + suffix + gp1_price[0]);
      } else {
        if (remFloorPrice2)
          ad_codes.push(prefix + baseCode[1] + remFloorSuffix);
        else ad_codes.push(prefix + baseCode[1] + suffix + gp2_price[0]);
      }
    }
    for (let i = 0; i < 23; i++) {
      if (i % 2 == 0) {
        if (remFloorPrice) ad_codes.push(prefix + baseCode[0] + remFloorSuffix);
        else ad_codes.push(prefix + baseCode[0] + suffix + gp1_price[1]);
      } else {
        if (remFloorPrice2)
          ad_codes.push(prefix + baseCode[1] + remFloorSuffix);
        else ad_codes.push(prefix + baseCode[1] + suffix + gp2_price[1]);
      }
    }
    for (let i = 0; i < 18; i++) {
      if (i % 2 == 0) {
        if (remFloorPrice2)
          ad_codes.push(prefix + baseCode[1] + remFloorSuffix);
        else ad_codes.push(prefix + baseCode[1] + suffix + gp2_price[2]);
      } else {
        if (remFloorPrice) ad_codes.push(prefix + baseCode[0] + remFloorSuffix);
        else ad_codes.push(prefix + baseCode[0] + suffix + gp1_price[2]);
      }
    }

    dispatch(
      setAppDetails({
        ...addProdDetails,
        AD_CODES: ad_codes,
      })
    );
  };
  function getAdCodesArray(str) {
    let val = str.replace("[", "");
    val = val.replace("]", "");
    val = val.replace(/"/g, "");
    // val = val.replace(/\s+/g, "");
    val = val.replace(/,http/g, "\nhttp");
    val = val.split("\n");
    val = val.filter((n) => n);

    return val;
  }

  const handleSubChange = (event, saveType) => {
    dispatch(
      setAppDetails({
        ...addProdDetails,
        [event.target.name]:
          saveType == "boolean"
            ? event.target.value == "true"
            : saveType == "number"
            ? isNaN(parseInt(event.target.value))
              ? ""
              : parseInt(event.target.value)
            : saveType == "ad_codes"
            ? getAdCodesArray(event.target.value)
            : saveType === "switchbool"
            ? event.target.checked
            : event.target.value,
      })
    );
    if (event.target.name == "SIZE") {
      let sizes = event.target.value.split("x");
      dispatch(
        setAppDetails({
          ...addProdDetails,
          ["SIZE"]: event.target.value,
          ["PLAYER_WIDTH"]: parseInt(sizes[0]),
          ["PLAYER_HEIGHT"]: parseInt(sizes[1]),
        })
      );
    } else if (event.target.name == "SIZE_MB") {
      let sizes_mb = event.target.value.split("x");
      dispatch(
        setAppDetails({
          ...addProdDetails,
          ["SIZE_MB"]: event.target.value,
          ["PLAYER_WIDTH_MB"]: parseInt(sizes_mb[0]),
          ["PLAYER_HEIGHT_MB"]: parseInt(sizes_mb[1]),
        })
      );
    }
  };
  const getArrayFromTextArea = (str) => {
    let arr = [];
    if (str) arr = str.split("\n");
    return arr;
  };

  // to convert comma seperated string into a array
  const getArrayFromString = (str) => {
    let arr = [];
    if (str) arr = str?.split(",");
    return arr;
  };

  const getSizesArray = (str) => {
    let newStr = "[" + str + "]";
    let arr = JSON.parse(newStr);
    return arr;
  };
  const getquotedString = (str) => {
    let newStr = "";
    newStr = ("'" + str + "'");
    return newStr;
  }
  function formatJson(toSaveObject) {
    const saveObj = {};
    field_data.forEach((el) => {
      saveObj[el.name] = {};
      saveObj[el.name]["VALUE"] = toSaveObject[el.name];
      saveObj[el.name]["OVERRIDE"] = overrideFields.includes(el.name);
    });
    return saveObj;
  }

  function saveVideoPlayer() {
    const toSaveObject = { ...addProdDetails };
    let vidSrc = toSaveObject["VIDEO_SRC_DEFAULT"].trim().split(",");
    vidSrc = vidSrc.map((el) => {
      return el.trim();
    });
    toSaveObject["VIDEO_SRC_DEFAULT"] = vidSrc.length > 1 ? vidSrc : vidSrc[0];
    if (toSaveObject["AD_REQUEST_DURATION"] === "") {
      toSaveObject["AD_REQUEST_DURATION"] = 0;
    }
    // if (typeof toSaveObject["SCRIPT_TEST_LEVEL"] != "number") {
    //   if (toSaveObject["SCRIPT_TEST_LEVEL"].includes("Level")) {
    //     let level = toSaveObject["SCRIPT_TEST_LEVEL"].split(" ").pop();
    //     level = parseInt(level);
    //     toSaveObject["SCRIPT_TEST_LEVEL"] = level;
    //   } else {
    //     toSaveObject["SCRIPT_TEST_LEVEL"] = 0;
    //   }
    // }

    field_data.forEach((el) => {
      if (el.type == "objectgroup") {
        let objlen = objectGroupCounter[el.name].length;
        for (let i = 0; i < objlen; i++) {
          let obj = {};
          el.fields.forEach((sub_el) => {
            if (sub_el.type !== "objectgroup") {
              switch (sub_el.saveType) {
                case "bool":
                  obj[sub_el.name] =
                    objectGroupDetails[el.name][sub_el.name + i] == "true";
                  break;
                case "array":
                  obj[sub_el.name] = getArrayFromString(
                    objectGroupDetails[el.name][sub_el.name + i]
                  );
                  break;
                case "textarea_array":
                  obj[sub_el.name] = getArrayFromTextArea(
                    objectGroupDetails[el.name][sub_el.name + i]
                  );
                  break;
                case "number":
                  obj[sub_el.name] = parseInt(
                    objectGroupDetails[el.name][sub_el.name + i]
                  );
                  break;
                case "float":
                  obj[sub_el.name] = parseFloat(
                    objectGroupDetails[el.name][sub_el.name + i]
                  );
                  break;
                case "size_array":
                  obj[sub_el.name] = getSizesArray(
                    objectGroupDetails[el.name][sub_el.name + i]
                  );
                  break;
                default:
                  obj[sub_el.name] =
                    objectGroupDetails[el.name][sub_el.name + i];
                  if (sub_el.isBool && typeof obj[sub_el.name] === "string") {
                    obj[sub_el.name] = obj[sub_el.name] === "true";
                  }
                  break;
              }
            } else {
              obj[sub_el.name] = [];
              let subobjlen =
                subObjectGroupCounter[el.name + sub_el.name][i].length;
              for (let j = 0; j < subobjlen; j++) {
                let sub_obj = {};
                sub_el.fields.forEach((in_sub_el) => {
                  switch (in_sub_el.saveType) {
                    case "bool":
                      sub_obj[in_sub_el.name] =
                        subObjectGroupDetails[el.name + sub_el.name][
                          in_sub_el.name + i + j
                        ] === "true";
                      break;
                    case "array":
                      sub_obj[in_sub_el.name] = getArrayFromString(
                        subObjectGroupDetails[el.name + sub_el.name][
                          in_sub_el.name + i + j
                        ]
                      );
                      break;
                    case "number":
                      sub_obj[in_sub_el.name] = parseInt(
                        subObjectGroupDetails[el.name + sub_el.name][
                          in_sub_el.name + i + j
                        ]
                      );
                      break;
                    default:
                      sub_obj[in_sub_el.name] =
                        subObjectGroupDetails[el.name + sub_el.name][
                          in_sub_el.name + i + j
                        ];
                      if (
                        in_sub_el.isBool &&
                        typeof sub_obj[in_sub_el.name] === "string"
                      ) {
                        sub_obj[in_sub_el.name] =
                          sub_obj[in_sub_el.name] === "true";
                      }
                      break;
                  }
                });
                obj[sub_el.name][j] = sub_obj;
              }
            }
          });
          if (typeof el.default == "object" && !isArr(el.default)) {
            if (el.index === "static") {
              toSaveObject[el.name] = obj;
            } else {
              el.fields.forEach((field) => {
                let key = field.name;
                if (Object.keys(obj).length <= 1 && obj[key] === undefined) {
                  toSaveObject[el.name] = {};
                  return true;
                } else {
                  toSaveObject[el.name][key + i] = obj[key];
                }
              });
            }
          } else {
            el.isBool
              ? (toSaveObject[el.name] = obj)
              : (toSaveObject[el.name][i] = obj);
          }
        }
      } else {        
        if (
          el?.saveType?.includes("array") &&
          typeof toSaveObject[el.name] === "string"
        ) {
          toSaveObject[el.name] = toSaveObject[el.name]
            .split(",")
            .map((item) =>
              el.saveType.includes("int") ? parseInt(item.trim()) : item.trim()
            );
        }
        if(el?.saveType?.includes("quoted_String")){
            toSaveObject[el.name] =  getquotedString(toSaveObject[el.name]);
        }
      }
    });

    let flag = true;
    delete toSaveObject["SIZE"];
    delete toSaveObject["SIZE_MB"];
    if (props.editFlag) {
      let changes = JSON.parse(JSON.stringify(changedFields));
      const notMatched = trackChange(props.vpFormData, toSaveObject, changes);
      console.log("notMatched = ",notMatched);
      dispatch(setChangedFields(notMatched));
    }
    if (flag) {
      let event = {
        target: {
          name: "",
          value: "",
        },
      };
      console.log("Json", toSaveObject);
      console.log("DB", toSaveObject);
      event.target.name = "additionalDetails" + props.heading;
      event.target.value = toSaveObject;
      props.handleChange(event);
      props.handleClose("D" + props.index);
    }
    props.setSaveClicked(false);
  }

  function uploadVideoPlayerDetails() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }
    dispatch(setAppDetails(uploadFile));
    setVpFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }

  useEffect(() => {
    if (props.saveClicked) {
      saveVideoPlayer();
    }
  }, [props.saveClicked]);

  useEffect(() => {
    if (props.uploadClicked) {
      uploadVideoPlayerDetails();
    }
    props.setUploadClicked(false);
  }, [props.uploadClicked]);

  // check changes

  return (
    <>
      <ObjGroup
        field_data={field_data}
        fieldSet={props.fieldSet}
        heading={props.heading}
      />
      {console.log()}
      {props.fieldSet && (
        <span>
          {field_data.map((data, index) => {
            if (data.type === "Textarea")
              return (
                <div>
                  <FormControlLabel
                    style={{ marginLeft: "10px" }}
                    control={
                      <Switch
                        checked={genArrchecked}
                        onChange={handleGenArrSwitch}
                        disabled={codesDisabled}
                      />
                    }
                    label="Generate AD_CODES via base code"
                  />
                  <br />
                  {genArrchecked ? (
                    <span>
                      <FormControlLabel
                        style={{ marginLeft: "10px" }}
                        control={
                          <Checkbox
                            checked={remFloorPrice}
                            onChange={handleRemoveFloorPrice}
                          />
                        }
                        label="Remove Floor Price gp1"
                      />
                      {remFloorPrice ? (
                        <></>
                      ) : (
                        <TextField
                          style={{ width: "40%", margin: "10px" }}
                          label="Floor pricing values for gp1"
                          value={floorPrice}
                          onChange={handleFloorPriceChange}
                          disabled={codesDisabled}
                        />
                      )}
                      <br />
                      <FormControlLabel
                        style={{ marginLeft: "10px" }}
                        control={
                          <Checkbox
                            checked={remFloorPrice2}
                            onChange={handleRemoveFloorPrice2}
                          />
                        }
                        label="Remove Floor Price gp2"
                      />
                      {remFloorPrice2 ? (
                        <></>
                      ) : (
                        <TextField
                          style={{ width: "40%", margin: "10px" }}
                          label="Floor pricing values for gp2"
                          value={floorPrice2}
                          onChange={handleFloorPriceChange2}
                          disabled={codesDisabled}
                        />
                      )}
                    </span>
                  ) : (
                    <></>
                  )}

                  {genArrchecked ? (
                    <div>
                      <br />
                      <TextField
                        style={{ width: "50%", margin: "10px" }}
                        multiline
                        rows={2}
                        label="Enter base code and click 'GENERATE ARRAY'"
                        value={baseAdCode}
                        disabled={codesDisabled}
                        onChange={handlebaseAdCode}
                      />{" "}
                      <Button
                        style={{
                          margin: "30px",
                        }}
                        onClick={generateADCode}
                      >
                        Generate Array
                      </Button>
                    </div>
                  ) : (
                    <span></span>
                  )}

                  <TextField
                    required={data.isRequired}
                    style={{ width: "95%", margin: "10px" }}
                    label={data.label}
                    name={data.name}
                    multiline
                    disabled={codesDisabled}
                    rows={4}
                    value={
                      addProdDetails[data.name] != undefined
                        ? addProdDetails[data.name].join("\n")
                        : ""
                    }
                    onChange={(e) => handleSubChange(e, "ad_codes")}
                  />
                </div>
              );
          })}
        </span>
      )}
    </>
  );
};

export default VideoPlayer;
