import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";


const Home = (props) => {
 
  return (
    <Container>
      <Typography>This is home page</Typography>
    </Container>
  );
};

export default Home;
