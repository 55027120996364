// Action Creator
//Common Actions
const setAppDetails = (obj_data) => {
  return {
    type: "UPDATE_FORM_FIELDS",
    data: obj_data,
  };
};

const setObjCounter = (obj_data) => {
  return {
    type: "UPDATE_COUNTER",
    data: obj_data,
  };
};
const setSubObjCounter = (obj_data) => {
  return {
    type: "UPDATE_SUB_COUNTER",
    data: obj_data,
  };
};
const setObjFields = (obj_data) => {
  return {
    type: "UPDATE_OBJ_FIELDS",
    data: obj_data,
  };
};
const setSubObjFields = (obj_data) => {
  return {
    type: "UPDATE_SUB_OBJ_FIELDS",
    data: obj_data,
  };
};

//HB Actions
const setBidderLabel = (obj_data) => {
  return {
    type: "UPDATE_BIDDER_LABEL",
    data: obj_data,
  };
};
const setInnerBidderLabel = (obj_data) => {
  return {
    type: "UPDATE_INNER_BIDDER_LABEL",
    data: obj_data,
  };
};
const setBidderParams = (obj_data) => {
  return {
    type: "UPDATE_HB_PARAMS",
    data: obj_data,
  };
};
const setSubBidderParams = (obj_data) => {
  return {
    type: "UPDATE_SUB_HB_PARAMS",
    data: obj_data,
  };
};
const setNotRequiredFields = (fields) => {
  return {
    type: "UPDATE_NOT_REQ_FIELDS",
    data: fields,
  };
};

const setNotice = (notice) => {
  return {
    type: "UPDATE_NOTICE",
    data: notice,
  };
};
const setChangedFields = (items) => {
  return {
    type: "CHANGED_FIELDS",
    data: items,
  };
};
const setLive = (status) => {
  return {
    type: "SET_LIVE",
    data: status,
  };
};
const setCssFile = (file) => {
  return {
    type: "SET_CSS_FILE",
    data: file,
  };
};
const setJsFile = (file) => {
  return {
    type: "SET_JS_FILE",
    data: file,
  };
};
const setBiddersDisabled = (items) => {
  return {
    type: "UPDATE_BIDDERS_DISABLED",
    data: items,
  };
};
const setOverrideFields = (fields) => {
  return {
    type: "UPDATE_OVERRIDE_FIELDS",
    data: fields,
  };
};
export {
  setObjCounter,
  setChangedFields,
  setSubObjCounter,
  setObjFields,
  setSubObjFields,
  setBidderLabel,
  setInnerBidderLabel,
  setBidderParams,
  setSubBidderParams,
  setAppDetails,
  setNotRequiredFields,
  setNotice,
  setLive,
  setCssFile,
  setJsFile,
  setBiddersDisabled,
  setOverrideFields,
};
