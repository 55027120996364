import { Button } from "@mui/material";
import { useState } from "react";

export default function Leads(props) {
  const [counte, setCount] = useState([1]);
  const [file, setFile] = useState(false);
  let xldata = { name: "", data: "" };
  const handleUpload = (e) => {
    setFile(true);
    var filename = e.target.value.replace(/^.*[\\\/]/, "");
    xldata.name = filename;
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(e.target.files[0]);
  };
  function onReaderLoad(event) {
    var obj = event.target.result;
    xldata.data = obj;
    uploadFileServer();
  }
  async function uploadFileServer() {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "lead/upload",
      {
        method: "POST",
        body: JSON.stringify(xldata),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const out = await response.json();
    console.log(out);
    if (out.success)
      props.showAlertMsg("file uploaded successfully", "success");
    else props.showAlertMsg("some error occured", "error");
  }
  const addMore = () => {
    setCount((prevData) => {
      return [...prevData, 1];
    });
  };

  const downloadFile = async () => {
    if (file) {
      let response = await fetch(
        process.env.REACT_APP_API_URL + "lead/download"
      );
      console.log(response);

      let blob = await response.blob();

      downloadCSV(blob);

      console.log(blob);
    } else {
      alert("Please upload the file first");
    }
  };
  function downloadCSV(blob, filename = "leads.csv") {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  return (
    <div>
      <h1>Upload your files</h1>
      <div>
        <Button variant="contained" onClick={downloadFile}>
          Download Leads
        </Button>
      </div>
      <span>Click 'ADD MORE' to upload more files</span>
      <Button onClick={addMore}>Add More</Button>
      {counte.map((c, index) => {
        return (
          <div key={index} style={{ margin: "20px" }}>
            <span>{index + 1}</span>
            <input
              id="fileSelect"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={handleUpload}
            />
          </div>
        );
      })}
    </div>
  );
}
