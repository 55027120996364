import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const DropdownMultiInput = (props) => {
  const { data, field, disabled, handleChange } = props;

  let valueArr = [];
  let isArr =
    Object.prototype.toString.call(
      props.arr ?? data.prodDetails[field.name + data.index]
    ) === "[object Array]";
  if (isArr) {
    valueArr =
      props.arr ??
      data.prodDetails[field.name + data.index] ??
      data.prodDetails[field.name];
  }
  let name =
    data?.name !== undefined
      ? data.name + "#" + field.name + data.index
      : field.name;

  const selectOptions = (field.name === "sizesM" || field.name === "sizesD") ? field.selectOptions.map((el) => ({ label: el.slice(1, -1).split(", ")[0], value: el, })) : field.selectOptions;
  return (
    <FormControl sx={{ m: 2, width: "20%" }}>
      <InputLabel id={field.label}>{field.label}</InputLabel>
      <Select
        value={valueArr}
        label={field.label}
        disabled={disabled}
        name={name}
        onChange={handleChange ?? data.handleSubChange}
        multiple={true}
        renderValue={(selected) => (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {selected.map((val) => (
              <div key={val.value ?? val} style={{ margin: "2px" }}>
                {val.value ?? val}
              </div>
            ))}
          </div>
        )}
      >
       
        {selectOptions.map((el, index) => (
          <MenuItem key={index} value={el.value ?? el}>
            {el.label ?? el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    // <FormControl sx={{ m: 2, width: "20%" }}>
    //   <InputLabel id={field.label}>{field.label}</InputLabel>
    //   <Select
    //     value={valueArr}
    //     label={field.label}
    //     disabled={props.disabled}
    //     name={name}
    //     onChange={props.handleChange ?? data.handleSubChange}
    //     multiple={true}
    //   >
    //     {field.selectOptions.map((el, index) => {
    //       return (
    //         <MenuItem key={index} value={el?.value ?? el}>
    //           {el?.label ?? el}
    //         </MenuItem>
    //       );
    //     })}
    //   </Select>
    // </FormControl>
  );
};

export default DropdownMultiInput;
