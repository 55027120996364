import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import productAdditionalFields from "./ProductAdditionalField";
import { setChangedFields } from "../../redux/actions/commonActions";
import { trackChange } from "../../utils/TrackChange";

const SaveEw = (props) => {
  const dispatch = useDispatch();
  const field_data = productAdditionalFields.EventsWidget;
  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const changedFields = useSelector((state) => state.common.changedField);
  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subObjectGroupCounter = useSelector(
    (state) => state.common.subObjectGroupCounter
  );
  const subObjectGroupDetails = useSelector(
    (state) => state.common.subObjectGroupDetails
  );

  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  const getArrayFromTextArea = (str) => {
    let arr = [];
    if (str) arr = str.split("\n");
    return arr;
  };

  // to convert comma seperated string into a array
  const getArrayFromString = (str) => {
    let arr = [];
    if (str) arr = str?.split(",");
    return arr;
  };
  // to convert stringifield array of array into a simple array (timestamps fields in CWs, sizes obj grp field in hb)
  const getSizeArrayFromString = (str) => {
    let arr;
    try {
      arr = JSON.parse(str);
    } catch (e) {
      let newStr = "[" + str + "]";
      arr = JSON.parse(newStr);
    }

    return arr;
  };

  const saveEventsWidget = () => {
    const toSaveObject = { ...addProdDetails };
    let flag = true;
    if (true) {
      toSaveObject["Covid_Timestamps"] = getSizeArrayFromString(
        toSaveObject["Covid_Timestamps"]
      );
      field_data.forEach((el) => {
        if (el.isArray)
          toSaveObject[el.name] = getArrayFromString(toSaveObject[el.name]);
      });
      field_data.forEach((el) => {
        if (el.type == "objectgroup") {
          let objlen = objectGroupCounter[el.name].length;
          for (let i = 0; i < objlen; i++) {
            let obj = {};
            el.fields.forEach((sub_el) => {
              if (sub_el.type !== "objectgroup") {
                switch (sub_el.saveType) {
                  case "bool":
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i] == "true";
                    break;
                  case "array":
                    obj[sub_el.name] = getArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "textarea_array":
                    obj[sub_el.name] = getArrayFromTextArea(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "number":
                    obj[sub_el.name] = parseInt(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "size_array":
                    obj[sub_el.name] = getSizeArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  default:
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i];
                    break;
                }
              } else {
                obj[sub_el.name] = [];
                let subobjlen =
                  subObjectGroupCounter[el.name + sub_el.name][i].length;
                for (let j = 0; j < subobjlen; j++) {
                  let sub_obj = {};
                  sub_el.fields.forEach((in_sub_el) => {
                    switch (in_sub_el.saveType) {
                      case "bool":
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ] == "true";
                        break;
                      case "array":
                        sub_obj[in_sub_el.name] = getArrayFromString(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        break;
                      case "number":
                        sub_obj[in_sub_el.name] = parseInt(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        break;
                      default:
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ];
                        break;
                    }
                  });
                  obj[sub_el.name][j] = sub_obj;
                }
              }
            });

            if (typeof el.default == "object" && !isArr(el.default)) {
              if (el.index === "static") {
                toSaveObject[el.name] = obj;
              } else {
                el.fields.forEach((field) => {
                  let key = field.name;
                  if (Object.keys(obj).length <= 1 && obj[key] === undefined) {
                    toSaveObject[el.name] = {};
                    return true;
                  } else {
                    toSaveObject[el.name][key + i] = obj[key];
                  }
                });
              }
            } else {
              toSaveObject[el.name][i] = obj;
            }
          }
        }
      });
      props.setSaveClicked(false);
    }
    if (props.editFlag) {
      let changes = JSON.parse(JSON.stringify(changedFields));
      const notMatched = trackChange(props.eventFormData, toSaveObject, changes);
      dispatch(setChangedFields(notMatched));
    }
    if (flag) {
      let event = {
        target: {
          name: "",
          value: "",
        },
      };
      console.log("Json", toSaveObject);
      event.target.name = "additionalDetails" + props.heading;
      event.target.value = toSaveObject;
      props.handleChange(event);
      props.handleClose("D" + props.index);
    }
  };
  useEffect(() => {
    saveEventsWidget();
  }, []);
  return <></>;
};

export default SaveEw;
