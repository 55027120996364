import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const DropdownInput = (props) => {
  const data = props.data;
  const field = props.field;
  let value =
    data?.prodDetails[field.name + data.index] ??
    data?.prodDetails[field.name] ??
    field.default;

  let key = data?.index !== undefined ? field.name + data.index : field.name;
  let selectOptions =
    (field.name === "COUNTRY_ORIGIN" || field.name === "COUNTRY")
      ? data.countryCodes.map((code) => {
          return { label: code, value: code.split(" - ").at(-1) };
        })
      : field.selectOptions;

  return (
    <FormControl sx={{ m: 2, width: "20%" }}>
      <InputLabel id={field.label}>{field.label}</InputLabel>
      <Select
        label={field.label}
        name={
          data?.name !== undefined
            ? data.name + "#" + field.name + data.index
            : field.name
        }
        disabled={props.disabled}
        key={key + Math.random()}
        required={field.isRequired}
        defaultValue={value}
        onBlur={(e) => {
          data.handleSubChange(e, field.isNum ? "number" : "string");
          if (field.name === "bidder") {
            data.setTrigger((prev) => !prev);
          }
        }}
      >
        {selectOptions.map((el, index) => {
          return (
            <MenuItem key={index} value={el?.value ?? el}>
              {el?.label ?? el}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownInput;
