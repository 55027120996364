import { Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import productAdditionalFields from "../CricketWidget/ProductAdditionalField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppDetails,
  setChangedFields,
} from "../../redux/actions/commonActions";
import ObjGroup from "../Objectgroup/ObjGroup";
import { trackChange } from "../../utils/TrackChange";
const CricketWidget = (props) => {
  const dispatch = useDispatch();

  const field_data = productAdditionalFields.CricketWidget;

  let apDetails_Fields = {};

  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const changedFields = useSelector((state) => state.common.changedField);
  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [crwFormData, setCrwFormData] = useState(props.crwFormData);

  useEffect(() => {
    if (editFlag && crwFormData) {
      field_data.forEach((el) => {
        let field_value = crwFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        if (el.name === "CW_Timestamps") {
          apDetails_Fields[el.name] = JSON.stringify(field_value);
        } else {
          apDetails_Fields[el.name] = field_value;
        }
      });
      // setCustomFields();
    } else {
      field_data.forEach((el) => {
        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;
      });
    }
  }, [crwFormData]);

  // function wrapper() {
  //   if (props.upload) {
  //     field_data.forEach((el) => {
  //       let field_value =
  //         props.upload !== undefined ? props.upload[el.name] : el.default;
  //       if (typeof field_value == "object")
  //         field_value = JSON.parse(JSON.stringify(field_value));

  //       apDetails_Fields[el.name] = field_value;
  //       console.log(field_value);
  //     });
  //     // setCustomFields();
  //   } else {
  //     field_data.forEach((el) => {
  //       apDetails_Fields[el.name] =
  //         typeof el.default === "object"
  //           ? JSON.parse(JSON.stringify(el.default))
  //           : el.default;
  //     });
  //   }
  // }

  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  const handleSubChange = (event, saveType) => {
    dispatch(
      setAppDetails({
        ...addProdDetails,
        [event.target.name]:
          saveType == "boolean"
            ? event.target.value == "true"
            : saveType == "number"
            ? parseInt(event.target.value)
            : event.target.value,
      })
    );
  };

  const getSizeArrayFromString = (str = "") => {
    let arr;
    try {
      arr = JSON.parse(str);
    } catch (e) {
      let newStr = "[" + str + "]";
      arr = JSON.parse(newStr);
    }

    return arr;
  };

  const saveCricketWidget = () => {
    const toSaveObject = { ...addProdDetails };
    if (toSaveObject["CW_Timestamps"] === "") {
      toSaveObject["CW_Timestamps"] = [[]];
    } else {
      toSaveObject["CW_Timestamps"] = getSizeArrayFromString(
        toSaveObject["CW_Timestamps"]
      );
    }
    let flag = true;
    if (props.editFlag) {
      let changes = JSON.parse(JSON.stringify(changedFields));
      const notMatched = trackChange(props.crwFormData, toSaveObject, changes);
      dispatch(setChangedFields(notMatched));
    }
    if (flag) {
      let event = {
        target: {
          name: "",
          value: "",
        },
      };
      console.log("Json", toSaveObject);
      event.target.name = "additionalDetails" + props.heading;
      event.target.value = toSaveObject;
      props.handleChange(event);
      props.handleClose("D" + props.index);
    }
    props.setSaveClicked(false);
  };

  function uploadCrwDetail() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }

    dispatch(setAppDetails(uploadFile));
    setCrwFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }
  useEffect(() => {
    if (props.saveClicked) {
      saveCricketWidget();
    }
    // console.log(props.saveCrwClicked)
    // console.log("i ran after page rendered")
  }, [props.saveClicked]);

  useEffect(() => {
    if (props.uploadClicked) uploadCrwDetail();
    props.setUploadClicked(false);
  }, [props.uploadClicked]);
  return (
    <span>
      <ObjGroup field_data={field_data} fieldSet={props.fieldSet} heading={props.heading} />
    </span>
  );
};
const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root, & .MuiOutlinedInput-input": {
    color: "white",
  },
});
const CssSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root, & .MuiOutlinedInput-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
});

export default CricketWidget;
