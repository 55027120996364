import React, { useEffect, useState } from "react";
import { Fab, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import LoadComponent from "../../utils/LoadComponent";
import Client from "./Client";
import axios from "axios";
import AddClient from "./AddClient";
import ClientDetails from "./ClientDetails";
import { useDispatch } from "react-redux";
import {
  setNotice,
  setLive,
  setChangedFields,
} from "../../redux/actions/commonActions";

const ClientComponent = React.memo((props) => {
  // console.log('props ClientComponent',props);
  const dispatch = useDispatch();
  const ClientLoading = LoadComponent(Client);
  const AddingClient = LoadComponent(AddClient);
  const ViewClientCard = LoadComponent(ClientDetails);
  const [page, setPage] = useState(null);
  const [clientState, setClient] = useState({
    clients: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [flags, setFlags] = useState({
    isLoading: true,
    addClient: false,
    viewClient: false,
    editClient: false,
  });
  const [clientData, setData] = useState({});
  function setClientData(data) {
    changeViewClient();
    setData(data);
  }
  const changeViewClient = () => {
    setFlags((prevFlags) => {
      return {
        ...prevFlags,
        viewClient: !flags.viewClient,
      };
    });
  };
  const changeDone = (status) => {
    props.clientEdited(status);
  };
  useEffect(() => {
    setFlags((prevFlags) => {
      return {
        ...prevFlags,
        addClient: false,
        viewClient: false,
        editClient: false,
      };
    });
  }, [props.clientFlag]);

  useEffect(() => {
    if (!flags.viewClient) {
      fetchClients();
      dispatch(setChangedFields([]));
    }
  }, [flags.viewClient]);

  const fetchClients = (brand) => {
    return new Promise((resolve, reject) => {
      setFlags((prevFlags) => {
        return {
          ...prevFlags,
          isLoading: true,
        };
      });
      const options = {
        method: "POST",
        brand: brand,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const url = process.env.REACT_APP_API_URL + "client/getClients";
      axios
        .post(url, options)
        .then((response) => {
          setClient({ clients: response.data });
          if (response.data.data?.length === 1) {
            dispatch(setNotice(response.data.data[0]?.notice ?? ""));
            dispatch(setLive(response.data.data[0]?.isLive ?? {}));
          }
          setFlags((prevFlags) => {
            return {
              ...prevFlags,
              isLoading: false,
            };
          });
          resolve(response.data);
        })
        .catch((err) =>
          console.log("error Occurred While Fetching Clients " + err)
        );
    });
  };
  let changeAddClient = () => {
    setFlags((prevFlags) => {
      return {
        ...prevFlags,
        addClient: !prevFlags.addClient,
      };
    });
  };
  let editClientPage = () => {
    setFlags((prevFlags) => {
      return {
        ...prevFlags,
        editClient: true,
      };
    });
  };
  let notEditClientPage = () => {
    setFlags((prevFlags) => {
      return {
        ...prevFlags,
        editClient: false,
      };
    });
  };

  if (flags.addClient) {
    return (
      <Container>
        {flags.editClient ? (
          <Typography variant="h4">Edit Client</Typography>
        ) : (
          <Typography variant="h4">Add New Client</Typography>
        )}
        <AddClient
          changeDone={changeDone}
          showAlertMsg={props.showAlertMsg}
          isLoading={flags.isLoading}
          clients={clientState.clients}
          changeAddClient={changeAddClient}
          fetchClients={fetchClients}
          notEditClientPage={notEditClientPage}
          editClient={flags.editClient}
          clientData={clientData.row}
        />
      </Container>
    );
  } else if (flags.viewClient && Object.keys(clientData).length > 0) {
    return (
      <Container>
        <Typography variant="h4">Client Details</Typography>
        <ViewClientCard
          isLoading={flags.isLoading}
          isView={flags.viewClient}
          setClientData={setClientData}
          clients={clientData}
          changeViewClient={changeViewClient}
          changeAddClient={changeAddClient}
          editClientPage={editClientPage}
        />
      </Container>
    );
  } else {
    return (
      <Container>
        <Typography variant="h4">All Clients</Typography>
        <ClientLoading
          isLoading={flags.isLoading}
          isView={flags.viewClient}
          setClientData={setClientData}
          clients={clientState.clients}
          changeAddClient={changeAddClient}
          fetchClients={fetchClients}
          page={page}
          setPage={setPage}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      </Container>
    );
  }
});

export default ClientComponent;
