import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
const AccessDenied = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 2000);
  }, []);
  return (
    <div>
      <p style={{ textAlign: "center", fontSize: "30px" }}>
        Sorry! access denied, redirecting to home page :(
      </p>
    </div>
  );
};

export default AccessDenied;
