import React, { useState } from "react";
import { Chip, TextField } from "@mui/material";

const MultiInputField = (props) => {
  const { data, field } = props;
  const name =
    data.name !== undefined
      ? data.name + "#" + field.name + data.index
      : field.name;
  const label = field.label;

  const value_in = data.prodDetails[field.name + data.index] || data.prodDetails[field.name] || [];

  const [values, setValues] = useState(value_in || []);
  const [currValue, setCurrValue] = useState([]);

  
  const handleKeyUp = (e) => {
    if (e.keyCode === 32) {
      if (currValue.trim() !== "") {
        setValues((oldValues) => {
          if (!Array.isArray(oldValues)) {
            oldValues = []; 
          }
          return [...oldValues, currValue];
        });
        setCurrValue("");
      }
    }
  };
  

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim();
    setCurrValue(trimmedValue);
  };

  const handleDelete = (index) => {
    setValues((oldValues) => {
      const newValues = [...oldValues];
      newValues.splice(index, 1);
      data.handleSubChange({ target: { name, value: newValues } }, "array");
      return newValues;
    });
  };
  // const handleDelete = (index) => {
  //   setValues((oldValues) => {
  //     if (!Array.isArray(oldValues)) {
  //       console.error("oldValues is not an array:", oldValues);
  //       return [];
  //     }
  //     const newValues = [...oldValues];
  //     if (index >= 0 && index < newValues.length) {
  //       newValues.splice(index, 1);
  //       data.handleSubChange({ target: { name, value: newValues } }, "array");
  //     } else {
  //       alert("Invalid index:", index);
  //     }
  
  //     return newValues;
  //   });
  // };
  
  let style = Object.assign(
    {},
    {
      width: "25%",
      margin: "20px",
    },
    field.style
  );
  return (
    <>
      <TextField
        style={style}
        value={currValue}
        name={name}
        onChange={handleChange}
        onKeyDown={handleKeyUp}
        label={label}
        placeholder={
          data.name === "ad_mappings"
            ? data.placeHolders[data.gcCubeType][field.name]
            : ""
        }
        required={field.isRequired}
        type={field.isNum ? "number" : "text"}
        onBlur={() =>
          {data.handleSubChange({ target: { name, value: values } }, "array")
          setCurrValue("")}
        }
        InputProps={{
          startAdornment: Array.isArray(values) && values.map((value, index) => (
            <Chip
              key={index}
              size="small"
              onDelete={() => handleDelete(index)}
              label={value}
            />
          )),
        }}
      />
    </>
  );
};

export default MultiInputField;
