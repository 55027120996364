import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import productAdditionalFields from "./ProductAdditionalField";
import { trackChange } from "../../utils/TrackChange";
import { setChangedFields } from "../../redux/actions/commonActions";

const SaveNb = (props) => {
  const dispatch = useDispatch();
  const field_data = productAdditionalFields.NewsBot;
  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const changedFields = useSelector((state) => state.common.changedField);

  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subObjectGroupCounter = useSelector(
    (state) => state.common.subObjectGroupCounter
  );
  const subObjectGroupDetails = useSelector(
    (state) => state.common.subObjectGroupDetails
  );
  const bidderLabel = useSelector((state) => state.common.bidderLabel);
  const innerBidderLabel = useSelector(
    (state) => state.common.innerBidderLabel
  );
  const notReqFields = useSelector((state) => state.common.notRequiredFields);

  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  const getArrayFromTextArea = (str) => {
    let arr = [];
    if (str) arr = str.split("\n");
    return arr;
  };

  // to convert comma seperated string into a array
  const getArrayFromString = (str) => {
    let arr = [];
    if (str) arr = str?.split(",");
    return arr;
  };
  // to convert stringifield array of array into a simple array (timestamps fields in CWs, sizes obj grp field in hb)
  const getSizeArrayFromString = (str) => {
    let arr;
    try {
      arr = JSON.parse(str);
    } catch (e) {
      let newStr = "[" + str + "]";
      arr = JSON.parse(newStr);
    }

    return arr;
  };
  const getSizesArray = (str) => {
    let newStr = "[" + str + "]";
    let arr = JSON.parse(newStr);
    return arr;
  };
  const getObjFromString = (str) => {
    if (props.editFlag && props.setFieldSet) {
      const keys = Object.keys(str);
      const values = Object.values(str);
      let res = "";
      for (let i = 0; i < keys.length; i++) {
        if (i == keys.length - 1) {
          res += keys[i] + "-" + values[i];
        } else {
          res += keys[i] + "-" + values[i] + ",";
        }
      }
      return res;
    } else {
      let obj = {};
      if (str) {
        let res = str.split(",");
        for (let i = 0; i < res.length; i++) {
          let check = res[i].split("-");
          for (let i = 0; i < check.length - 1; i++) {
            obj[check[i]] = check[i + 1];
          }
        }
      }
      return obj;
    }
  };
  function saveNewsBot() {
    const toSaveObject = { ...addProdDetails };
    let flag = true;
    for (let key in toSaveObject) {
      // console.log([key])
      if (key === "DisplayTexts") {
        field_data.forEach((el) => {
          if (el.type === "objectgroup" && el.name === "DisplayTexts") {
            
            let objlen = objectGroupCounter[el.name].length;
            for (let i = 0; i < objlen; i++) {
              let obj = {};
              el.fields.forEach((sub_el) => {
                obj[sub_el.name] = objectGroupDetails[el.name][sub_el.name + i];
              });

              toSaveObject[el.name][i] = obj;
              // for (let key in toSaveObject) {
              //   if (
              //     toSaveObject[key].length === 0 &&
              //     !not_req_fields.includes(key)
              //   ) {
              //     flag = false;
              //     console.log(key);
              //     props.showAlertMsg(`Please fill ${key} field`);
              //     break;
              //   } else if (
              //     !not_req_fields.includes(key) &&
              //     typeof toSaveObject[key] === "object"
              //   ) {
              //     if (isArr(toSaveObject[key])) {
              //       toSaveObject[key].forEach((elem) => {
              //         for (let sub_key in elem) {
              //           if (
              //             elem[sub_key].length === 0 &&
              //             !not_req_fields.includes(sub_key)
              //           ) {
              //             flag = false;
              //             props.showAlertMsg(
              //               `Please fill ${sub_key} field inside ${key}`
              //             );
              //             break;
              //           }
              //         }
              //       });
              //     } else {
              //       for (let sub_key in toSaveObject[key]) {
              //         if (
              //           toSaveObject[key][sub_key].length === 0 &&
              //           !not_req_fields.includes(sub_key)
              //         ) {
              //           flag = false;
              //           props.showAlertMsg(
              //             `Please fill ${sub_key} field inside ${key}`
              //           );
              //           break;
              //         }
              //       }
              //     }
              //   }
              // }
            }
          }
        });
      }
    }
    if (flag) {
      toSaveObject["mybotMatchTimestamps"] = getSizeArrayFromString(
        toSaveObject["mybotMatchTimestamps"]
      );
      field_data.forEach((el) => {
        if (el.isArray)
          toSaveObject[el.name] = getArrayFromString(toSaveObject[el.name]);

        if (el.dataType === "Object") {
          toSaveObject[el.name] = getObjFromString(toSaveObject[el.name]);
        }
      });

      field_data.forEach((el) => {
        if (el.type == "objectgroup") {
          let objlen = objectGroupCounter[el.name].length;
          for (let i = 0; i < objlen; i++) {
            let obj = {};
            let arr = [];
            el.fields.forEach((sub_el) => {
              if (sub_el.dataType === "nested_array") {
                switch (sub_el.saveType) {
                  case "bool":
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i] == "true";
                    break;
                  case "array":
                    obj[sub_el.name] = getArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "textarea_array":
                    obj[sub_el.name] = getArrayFromTextArea(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "number":
                    obj[sub_el.name] = parseInt(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "size_array":
                    obj[sub_el.name] = getSizeArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  default:
                    arr.push(objectGroupDetails[el.name][sub_el.name + i]);
                    arr.push(
                      objectGroupDetails[el.name][sub_el.name + i]?.split(
                        ","
                      )[1]
                    );
                    obj[sub_el.name] = getArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                }
              } else if (sub_el.type !== "objectgroup") {
                switch (sub_el.saveType) {
                  case "bool":
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i] == "true";
                    break;
                  case "array":
                    obj[sub_el.name] = getArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "textarea_array":
                    obj[sub_el.name] = getArrayFromTextArea(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "number":
                    obj[sub_el.name] = parseInt(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "size_array":
                    obj[sub_el.name] = getSizeArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  default:
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i];
                    break;
                }
              } else {
                obj[sub_el.name] = [];
                let subobjlen =
                  subObjectGroupCounter[el.name + sub_el.name][i].length;
                for (let j = 0; j < subobjlen; j++) {
                  let sub_obj = {};
                  sub_el.fields.forEach((in_sub_el) => {
                    switch (in_sub_el.saveType) {
                      case "bool":
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ] == "true";
                        break;
                      case "array":
                        sub_obj[in_sub_el.name] = getArrayFromString(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        break;
                      case "number":
                        sub_obj[in_sub_el.name] = parseInt(
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ]
                        );
                        break;
                      default:
                        sub_obj[in_sub_el.name] =
                          subObjectGroupDetails[el.name + sub_el.name][
                            in_sub_el.name + i + j
                          ];
                        break;
                    }
                  });
                  obj[sub_el.name][j] = sub_obj;
                }
              }
            });
            if (typeof el.default == "object" && !isArr(el.default)) {
              if (el.index === "static") {
                toSaveObject[el.name] = obj;
              } else {
                el.fields.forEach((field) => {
                  let key = field.name;
                  toSaveObject[el.name][key + (i + 1)] = obj[key];
                });
              }
            } else {
              toSaveObject[el.name][i] = obj;
            }
            // if (arr.length > 0) {
            //   let obj = {};
            //   for (let i = 0; i < arr.length - 1; i++) {
            //     obj[arr[i]] = arr[i + 1];
            //   }

            //   toSaveObject[el.name][i] = obj;
            // } else {
            //   toSaveObject[el.name][i] = obj;
            // }
          }
        }
      });
    }
    if (props.editFlag) {
      let changes = JSON.parse(JSON.stringify(changedFields));
      const notMatched = trackChange(props.newsFormData, toSaveObject, changes);
      dispatch(setChangedFields(notMatched));
    }
    if (flag) {
      let event = {
        target: {
          name: "",
          value: "",
        },
      };
      console.log("Json", toSaveObject);
      event.target.name = "additionalDetails" + props.heading;
      event.target.value = toSaveObject;
      props.handleChange(event);
      props.handleClose("D" + props.index);
    }
    props.setSaveClicked(false);
  }

  useEffect(() => {
    saveNewsBot();
  }, []);
  return <></>;
};

export default SaveNb;
