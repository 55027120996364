import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";
import { Typography, Toolbar } from "@material-ui/core";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@material-ui/core/IconButton";
import { updatePerson } from "../../redux/actions/action";
import { useDispatch } from "react-redux";

function Login(props) {
  const dispatch = useDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    setLoginData((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value,
      };
    });
  };
  function handleLogin(e) {
    const api_url = process.env.REACT_APP_API_URL + "login/user";
    fetch(api_url, {
      method: "POST",
      body: JSON.stringify(loginData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((out) => {
        if (out.error) {
          props.showAlertMsg(out.message, "error");
        } else {
          props.setCookieStatus(out.token);
          dispatch(updatePerson(out));
        }
      });
    e.preventDefault();
  }
  useEffect(() => {
    window.history.replaceState(null, document.title, "/");
  }, []);
  return (
    <div>
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" edge="start">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6">Unibots Dashboard</Typography>
      </Toolbar>
      <Card
        sx={{
          width: "370px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "10%",
        }}
      >
        <CardHeader style={{ fontSize: "10px" }} title="Login" />
        <CardContent>
          <Stack spacing={2} direction="row" justifyContent="center" margin={2}>
            <TextField
              onChange={handleChange}
              name="email"
              value={loginData.email}
              label="Email"
              type="email"
            />
          </Stack>{" "}
          <form onSubmit={handleLogin}>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              margin={2}
            >
              <TextField
                onChange={handleChange}
                name="password"
                value={loginData.password}
                label="Password"
                type="password"
                autoComplete="current-password"
              />
            </Stack>
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              margin={2}
            >
              <Button variant="contained" onClick={handleLogin}>
                Login
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
