const productAdditionalFields = {
  AMPGists: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "Add_Ad_Units",
      label: "Add Ad Units",
      type: "objectgroup",
      fields: [
        {
          name: "AD_UNIT",
          label: "AD UNIT",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          saveType: "string"
        },
        {
          name: "SIZES",
          label: "SIZES",
          type: "DropdownMulti",
          default: [],
          addCustom: true,
          selectOptions: [
            "300x50",
            "300x100",
            "300x250",            
            "300x300",
            "300x480",
            "300x600",
            "320x50",
            "320x100",
            "320x240",
            "320x400",
            "320x480",
            "336x250",
            "336x280",
            "336x480",
            "1x1"
          ],
          isBool: false,
          isRequired: false,
        },
        {
          name: "AD_TYPE",
          label: "AD TYPE",
          type: "AutoComplete",
          default: "",
          addCustom: true,
          selectOptions: [
            "Banner/Inarticle",
            "anchor",
            "parallax"
          ],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "cube_details",
      label: "Add cube details",
      type: "objectgroup",
      fields: [
        {
          name: "CHOOSE_TYPE",
          label: "CHOOSE TYPE",
          type: "AutoComplete",
          default: "",
          addCustom: true,
          selectOptions: [
            "CUBE",
            "WIDGET"
          ],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "WIDTH",
          label: "WIDTH",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: true,
          saveType: "number"
        },
        {
          name: "HEIGHT",
          label: "HEIGHT",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: true,
          saveType: "number"
        },
        {
          name: "src",
          label: "source url",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: false,
          saveType: "string"
        },
        {
          name: "bottom",
          label: "Bottom",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: true,
          saveType: "number"
        },
        {
          name: "side_position",
          label: "Cube Position",
          type: "radio",
          default: "right",
          selectOptions: ["right", "left"],
          isBool: false,
          isRequired: false,
        }
      ],
      addButton: false,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "Direct_Banner",
      label: "Add Direct Banner",
      type: "objectgroup",
      fields: [
        {
          name: "CHOOSE_TYPE",
          label: "CHOOSE TYPE",
          type: "AutoComplete",
          default: "",
          addCustom: true,
          selectOptions: [
            "MOA",
            "EOA"
          ],
          isBool: false,
          isRequired: true,
          saveType: "string",
        },
        {
          name: "WIDTH",
          label: "WIDTH",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: true,
          saveType: "number"
        },
        {
          name: "HEIGHT",
          label: "HEIGHT",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: true,
          saveType: "number"
        },
        {
          name: "src",
          label: "source url",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          isRequired: true,
          isNum: false,
          saveType: "string"
        }
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: false,
    }
  ],
};
export default productAdditionalFields;
