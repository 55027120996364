import { FlashOffOutlined } from "@material-ui/icons";

const productAdditionalFields = {
  EventsWidget: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "Covid_Timestamps",
      label: "Covid Timestamps",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "UB_Facesvalue",
      label: "UB Facesvalue",
      type: "Dropdown",
      catNum: true,
      default: 6,
      selectOptions: [3, 4, 5, 6],
      isNum: true,
    },
    {
      name: "widget_size",
      label: "Widget Size",
      type: "Dropdown",
      catNum: true,
      default: 100,
      selectOptions: [100, 90, 80],
      isNum: true,
    },
    {
      name: "UI_Style",
      label: "UI Style",
      type: "Dropdown",
      default: "images",
      selectOptions: ["images", "covid"],
      isBool: false,
    },
    {
      name: "MobileTopPixel",
      label: "Mobile Top Pixel",
      type: "Input",
      default: 54,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "DesktopBottomPixel",
      label: "Desktop Bottom Pixel",
      type: "Input",
      default: 0,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "MobileBottomPixel",
      label: "Mobile Bottom Pixel",
      type: "Input",
      default: 0,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "MobileBottomPixel",
      label: "Mobile Bottom Pixel",
      type: "Input",
      default: 0,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "mbLeftShift",
      label: "Mobile Left Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "mbRightShift",
      label: "Mobile Right Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "pcLeftShift",
      label: "Desktop Left Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "pcRightShift",
      label: "Desktop Right Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "ExternalWidget_script",
      label: "External Widget script",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
      line: true,
    },
    {
      name: "LoadAdDefault",
      label: "Load Ad Default",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Sticky_Ad",
      label: "Sticky Ad",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "AuthEnabled",
      label: "Auth Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CloseEnabled",
      label: "Close Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "ClickCountEnabled",
      label: "Click Count Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "mybotScrollFlag",
      label: "My Bot Scroll Flag",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ScrollStartFlag",
      label: "Scroll Start Flag",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ScrollStartPixelHeight",
      label: "Scroll Start Pixel Height",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "IsBottomScrollDisapperEnabled",
      label: "Is Bottom Scroll Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "IsBottomScrollStartDisapperEnabled",
      label: "Is Bottom Scroll Start Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "MobileTopPlacement",
      label: "Mobile Top Placement",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "IsScrollupDisapperEnabled",
      label: "Is Scroll up Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "CUSTOM_ADS_CSS",
      label: "CUSTOM ADS CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CUSTOM_ADS_DIV",
      label: "CUSTOM ADS DIV",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CustomClientAPI",
      label: "Custom Client API",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ampEnable",
      label: "Amp Enable",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "saveUserConfig",
      label: "Save Use Config",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "WidgetPosition",
      label: "Widget Position Desktop",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "WidgetPositionMob",
      label: "Widget Position Mobile",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: false,
      line: true,
    },
    {
      name: "ImageRep",
      label: "Repeat Image",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "LinkRep",
      label: "Repeat Link",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "CustomFaces",
      label: "Custom Faces",
      type: "objectgroup",
      addCount: 1,
      fields: [
        {
          name: "Location",
          label: "Select Location",
          type: "AutoComplete",
          custom: false,
          default: "",
          selectOptions: [
            "world",
            "india",
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chhattisgarh",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jammu and Kashmir",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttarakhand",
            "Uttar Pradesh",
            "West Bengal",
            "Andaman and Nicobar Islands",
            "Chandigarh",
            "Dadra and Nagar Haveli",
            "Daman and Diu",
            "Delhi",
            "Lakshadweep",
            "Puducherry",
          ],
        },
        {
          name: "data",
          label: "Data",
          type: "AutoComplete",
          default: "",
          selectOptions: [
            [
              "cases",
              "deaths",
              "location",
              "people_fully_vaccinated",
              "people_vaccinated",
              "recovered",
              "world_total_vaccinations",
            ],
            [
              "cases",
              "deaths",
              "location",
              "newActive",
              "newCases",
              "newDeaths",
              "newRecovered",
              "newTests",
              "positivityIndia",
              "recovered",
              "totalActive",
              "vaccinated",
            ],
            [
              "dose1",
              "dose2",
              "newActive",
              "newDeaths",
              "newRecovered",
              "state_name",
              "totalActive",
              "totalConfirmed",
              "totalDeaths",
              "totalRecovered",
              "vaccinated",
            ],
          ],
        },
        {
          name: "text",
          label: "Text",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "topBar",
          label: "Top Bar",
          type: "Input",
          default: "Powered By Unibots",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "bottomBar",
          label: "Bottom Bar",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "faceColor",
          label: "Face Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "barColor",
          label: "Bar Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "textColor",
          label: "Text Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "barTextColor",
          label: "Bar Text Color",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "imgLink",
          label: "Image Link",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
        {
          name: "redirectLink",
          label: "Redirect Link",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
        },
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
  ],
};

export default productAdditionalFields;
