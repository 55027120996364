import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import productAdditionalFields from "./ProductAdditionalField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppDetails,
  setObjCounter,
  setObjFields,
  setSubObjCounter,
  setSubObjFields,
} from "../../redux/actions/commonActions";
import ObjGroup from "../Objectgroup/ObjGroup";
import SaveEw from "./SaveEw";

const EventsWidget = (props) => {
  const dispatch = useDispatch();

  const field_data = productAdditionalFields.EventsWidget;
  let apDetails_Fields = {};
  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let not_req_fields = [];

  const addProdDetails = useSelector((state) => state.common.addProdDetails);

  const [botCatNum, setBotCatNum] = useState(-1);
  const [catName, setCatName] = useState("");

  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [eventFormData, setEventFormData] = useState(props.eventFormData);
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  useEffect(() => {
    if (editFlag && eventFormData) {
      field_data.forEach((el) => {
        if (el.addCount !== undefined && botCatNum < 0) {
          if (isArr(eventFormData[el.name])) {
            el.addCount = eventFormData[el.name]?.length;
          } else {
            el.addCount = Object.keys(eventFormData[el.name])?.length;
          }
        }
        if (el.catNum && botCatNum < 0) {
          let addLimit = eventFormData[el.name] ?? el.default;
          setBotCatNum(addLimit);
          setCatName(el.name);
        }
        let field_value = eventFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        apDetails_Fields[el.name] = field_value;
        if (el.type == "objectgroup")
          if (!isArr(field_value)) {
            let temp = [];
            temp.push(field_value);
            field_value = temp;
          }
        setEventsWidgetObjGroupFields(el, field_value);
      });
    } else {
      field_data.forEach((el) => {
        //assigning field add limit
        if (el.catNum && botCatNum < 0) {
          setBotCatNum(el.default);
          setCatName(el.name);
        }
        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;

        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] =
                  typeof subfield.default == "object"
                    ? {}
                    : subfield.default.toString();
              });
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object"
                  ? {}
                  : field.default?.toString();
            }
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        }
      });
    }
  }, [eventFormData]);

  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setSubObjCounter(subobjectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  const setEventsWidgetObjGroupFields = (el, field_value) => {
    if (
      typeof el.default == "object" &&
      !isArr(el.default) &&
      el.index !== "static"
    ) {
      let obj = field_value[0];
      let newKey = el.fields[0].name;

      Object.keys(obj).forEach((key) => {
        let newObj = {};
        newObj[newKey] = obj[key];
        let index = parseInt(key.split("").pop());

        field_value[index] = newObj;
      });
    }
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        let len = field_value.length;
        for (let i = 0; i < len; i++) {
          if (field_value[field.name] <= 0 || len <= 0) {
            objectgroup_fields[el.name][field.name + i] =
              typeof field.default == "object"
                ? {} ?? JSON.stringify(field.default).trim()
                : field.default != null
                ? field.default.toString()
                : "";
          } else {
            objectgroup_fields[el.name][field.name + i] =
              typeof field.default == "object"
                ? JSON.stringify(field_value[i][field.name]).trim() ?? {}
                : field_value[i][field.name] !== null
                ? field_value[i][field.name]?.toString()
                : "";
          }
        }
      });
    }
  };

  function uploadEventsWidgetDetails() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }

    dispatch(setAppDetails(uploadFile));
    setEventFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }

  useEffect(() => {
    if (props.uploadClicked) uploadEventsWidgetDetails();
    props.setUploadClicked(false);
  }, [props.uploadClicked]);

  return (
    <span>
      <ObjGroup
        field_data={field_data}
        catName={catName}
        botCatNum={botCatNum}
        setBotCatNum={setBotCatNum}
        fieldSet={props.fieldSet}
        heading={props.heading}
      />
      {props.saveClicked ? <SaveEw {...props} /> : <></>}
    </span>
  );
};

export default EventsWidget;
