const fieldState = {
  addProdDetails: {},
  objectGroupCounter: {},
  objectGroupDetails: {},
  subObjectGroupCounter: {},
  subObjectGroupDetails: {},
  hbParams: {},
  subHbParams: {},
  bidderLabel: {},
  innerBidderLabel: {},
  notRequiredFields: [],
  notice: "",
  isLive: {},
  changedField: [], // it was and array trying changing it in an object
  cssFile: { file: "", save: false },
  jsFile: { file: "", save: false },
  biddersDisabled: {},
  overrideFields: [],
};

export default function commonReducer(state = fieldState, action) {
  switch (action.type) {
    case "UPDATE_FORM_FIELDS":
      return { ...state, addProdDetails: action.data };
    case "UPDATE_COUNTER":
      return { ...state, objectGroupCounter: action.data };
    case "UPDATE_SUB_COUNTER":
      return { ...state, subObjectGroupCounter: action.data };
    case "UPDATE_OBJ_FIELDS":
      return { ...state, objectGroupDetails: action.data };
    case "UPDATE_SUB_OBJ_FIELDS":
      return { ...state, subObjectGroupDetails: action.data };
    case "UPDATE_HB_PARAMS":
      return { ...state, hbParams: action.data };
    case "UPDATE_SUB_HB_PARAMS":
      return { ...state, subHbParams: action.data };
    case "UPDATE_BIDDER_LABEL":
      return { ...state, bidderLabel: action.data };
    case "UPDATE_INNER_BIDDER_LABEL":
      return { ...state, innerBidderLabel: action.data };
    case "UPDATE_NOT_REQ_FIELDS":
      return { ...state, notRequiredFields: action.data };
    case "UPDATE_NOTICE":
      return { ...state, notice: action.data };
    case "CHANGED_FIELDS":
      return { ...state, changedField: action.data };
    case "SET_LIVE":
      return { ...state, isLive: action.data };
    case "SET_CSS_FILE":
      return { ...state, cssFile: action.data };
    case "SET_JS_FILE":
      return { ...state, jsFile: action.data };
    case "UPDATE_BIDDERS_DISABLED":
      return { ...state, biddersDisabled: action.data };
    case "UPDATE_OVERRIDE_FIELDS":
      return { ...state, overrideFields: action.data };
    default:
      return state;
  }
}
