import React from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { setNotice } from "../redux/actions/commonActions";

const NoticeBox = () => {
  const dispatch = useDispatch();
  const notice = useSelector((state) => state.common.notice);
  const handleChange = (e) => {
    dispatch(setNotice(e.target.value));
  };
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        margin: "10px",
        width: "750px",
      }}
    >
      Notice
      <CssTextField
        id="filled-basic"
        variant="filled"
        defaultValue={notice}
        placeholder="Enter text here"
        multiline
        style={{ width: "100%", padding: "1px", marginLeft: 0 }}
        minRows={1}
        maxRows={10}
        onBlur={handleChange}
      />
    </div>
  );
};

const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    padding: "5px",
  },
});

export default NoticeBox;
