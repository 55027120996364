const dfpscript = {"VideoPlayer" :`var ub_frame = window.parent.document.getElementById(parent.window.frames[window.name].name);
var div = document.createElement("div");
div.id = "div-ub-clientname";
var final_ub_elem = ub_frame.parentNode.parentNode;
final_ub_elem.parentNode.insertBefore(div, final_ub_elem);
url = 'https://cdn.unibotscdn.com/ubplayer/player.js'; s1 = document.createElement('script'); s1.src = url; s1.async = "async";s1.type = "text/javascript"; window.parent.document.getElementsByTagName('head')[0].appendChild(s1);
s1.onload=function(){ console.log('DFP loaded'); window.parent.unibots = window.parent.unibots || { cmd: [] }; window.parent.unibots.cmd.push(function(){ window.parent.unibotsPlayer("clientname"); });}`,
"HeaderBidding" : `let ubhb_s1 = document.createElement('script'); ubhb_s1.src = "https://cdn.unibots.in/headerbidding/common/hb.js"; ubhb_s1.type = "text/javascript"; window.parent.document.getElementsByTagName('head')[0].appendChild(ubhb_s1); ubhb_s1.onload=function(){ console.log('clientname DFP loaded'); window.parent.unibotshb = window.parent.unibotshb || { cmd: [] }; window.parent.unibotshb.cmd.push(function(){ window.parent.ubHB("clientname"); });}`};

export { dfpscript };
