import React, { useState } from "react";

import {
    Typography,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Button,
    Grid,
    Modal,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%)`,
    },
}));

const Tasks = (props) => {
    const classes = useStyles();
    const { tasks } = props;
    const [state, setState] = useState({
        id: "",
        isOpen: false,
    });

    const toggle = (id) => {
        setState({
            id: id,
            isOpen: !state.isOpen,
        });
    };

    if (!tasks || tasks.length === 0) return <p>No tasks, sorry</p>;
    return (
        <div>
            <Typography varient="h2">Available Tasks</Typography>
            <Grid container spacing={3}>
                {tasks.map((task) => {
                    const id = task._id;
                    return (
                        <Grid item key={id} sm={6} md={3}>
                            <Card>
                                <CardHeader
                                    title={task.title}
                                    subheader={task.creator}
                                />
                                <CardContent>
                                    <Typography
                                        varient="body2"
                                        color="textSecondary"
                                    >
                                        {task.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => toggle(id)}
                                    >
                                        Show More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
            <Modal open={state.isOpen} onClose={() => toggle(state.id)}>
                <div className={classes.paper}>
                    {tasks
                        .filter((task) => {
                            if (task._id === state.id) {
                                return task;
                            }
                        })
                        .map((task) => {
                            return (
                                <div>
                                    <div>{task.title}</div>
                                    <div>
                                        <p>
                                            <strong>Description:</strong>
                                            <br /> {task.description}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Task By:</strong>{" "}
                                            {task.creator}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Created On:</strong>{" "}
                                            {task.createdOn}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Created For:</strong>{" "}
                                            {task.for}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Due By:</strong>{" "}
                                            {task.dueBy}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Priority:</strong>{" "}
                                            {task.priority}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Is Internal?:</strong>{" "}
                                            {String(task.isInternal)}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Is Archieved?:</strong>{" "}
                                            {String(task.isArchieved)}
                                        </p>
                                        <hr />
                                        <p>
                                            <strong>Comments:</strong>
                                            <br />
                                            {task.comments.map(
                                                (comment, index) => {
                                                    return (
                                                        <div>
                                                            <p>
                                                                <strong>
                                                                    #{index + 1}
                                                                </strong>{" "}
                                                                -{" "}
                                                                {
                                                                    comment.comment
                                                                }{" "}
                                                                - {comment.by} (
                                                                {comment.time})
                                                            </p>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </p>
                                    </div>
                                    <div>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => toggle(state.id)}
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </Modal>
        </div>
    );
};

export default Tasks;
