import { FlashOffOutlined } from "@material-ui/icons";

const productAdditionalFields = {
  CricketWidget: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "Ui_Style",
      label: "Ui Style",
      type: "Dropdown",
      default: "bowl",
      selectOptions: ["bowl", "cube", "caricature", "marquee", "grndwidget", "carousel", "cricketpoll"],
      isBool: false,
    },
    {
      name: "CW_Timestamps",
      label: "CW Timestamps",
      type: "Input",
      default: [[]],
      selectOptions: null,
      isBool: false,
    },
    {
      name: "ScrollPixelHeight",
      label: "Scroll Pixel Height",
      type: "Input",
      default: 45,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "MobileTopPixel",
      label: "Mobile Top Pixel",
      type: "Input",
      default: 54,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "ExternalWidget_script",
      label: "External Widget script",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "DesktopBottomPixel",
      label: "Desktop Bottom Pixel",
      type: "Input",
      default: 30,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "MobileBottomPixel",
      label: "Mobile Bottom Pixel",
      type: "Input",
      default: 30,
      selectOptions: null,
      isBool: false,
      isNum: true,
      line: false,
    },
    {
      name: "mbLeftShift",
      label: "Mobile Left Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
      line: false,
    },
    {
      name: "mbRightShift",
      label: "Mobile Right Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
      line: false,
    },
    {
      name: "pcLeftShift",
      label: "Desktop Left Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
      line: false,
    },
    {
      name: "pcRightShift",
      label: "Desktop Right Shift",
      type: "Input",
      default: 5,
      selectOptions: null,
      isBool: false,
      isNum: true,
      line: true,
    },
    {
      name: "CW_Nonlive_Hrs",
      label: "CW Nonlive Hrs",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "LoadAdDefault",
      label: "Load Ad Default",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "Sticky_Ad",
      label: "Sticky Ad",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "AuthEnabled",
      label: "Auth Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CloseEnabled",
      label: "Close Enabled",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "ClickCountEnabled",
      label: "Click Count Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "WidgetPosition",
      label: "Widget Position",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "WidgetPositionMob",
      label: "Widget Position Mob",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "mybotScrollFlag",
      label: "My Bot Scroll Flag",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ScrollStartFlag",
      label: "Scroll Start Flag",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ScrollStartPixelHeight",
      label: "Scroll Start Pixel Height",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IsBottomScrollDisapperEnabled",
      label: "Is Bottom Scroll Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IsBottomScrollStartDisapperEnabled",
      label: "Is Bottom Scroll Start Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "MobileTopPlacement",
      label: "Mobile Top Placement",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IsScrollupDisapperEnabled",
      label: "Is Scroll up Disapper Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "MobileTopPixel",
      label: "Mobile Top Pixel",
      type: "Input",
      default: 54,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "CustomRedirectUrl",
      label: "Custom Redirect Url",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "CUSTOM_ADS_CSS",
      label: "CUSTOM ADS CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CUSTOM_ADS_DIV",
      label: "CUSTOM ADS DIV",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    // {
    //   name:"ADD_AMP_CODE",
    //   label:"Add AMP Code",
    //   type:"Switch",

    // },
    {
      name: "saveUserConfig",
      label: "Save User Config",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "ampEnable",
      label: "Amp Enable",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "adText",
      label: "ad Text",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "disableScript",
      label: "Disable Widget",
      type: "switch",
      default: false,
      isBool: true,
    },
  ],
};

export default productAdditionalFields;
