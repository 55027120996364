import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ListComp from "./ListComp";
import { useSelector, useDispatch } from "react-redux";
import { setOverrideFields } from "../../redux/actions/commonActions";

const Override = (props) => {
  const dispatch = useDispatch();
  const overrideFields = useSelector((state) => state.common.overrideFields);

  const [checked, setChecked] = React.useState(overrideFields);
  const [filteredData, setFilteredData] = useState(props.data);
  const handleClose = () => {
    dispatch(setOverrideFields(checked));
    props.setOpen(false);
  };
  const handleSearch = (e) => {
    let newData = [];
    newData = props.data.filter((field) => {
      return field.name.toLowerCase().includes(e.target.value.toLowerCase());
    });
    setFilteredData(newData);
  };
  return (
    <Dialog open={props.open} fullWidth maxWidth="xl">
      <DialogTitle>Override Fields</DialogTitle>
      <DialogContent
        sx={{
          height: "70vh",
          maxHeight: "70vh",
        }}
      >
        <TextField
          id="input-with-icon-textfield"
          label="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
          variant="standard"
        />
        <ListComp
          data={filteredData}
          checked={checked}
          setChecked={setChecked}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "end",
          p: 2.5,
        }}
      >
        <Button variant="outlined" onClick={handleClose}>
          <Box> Close </Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Override;
