import { useEffect, useState } from "react";
import ObjGroup from "../Objectgroup/ObjGroup";
import {
  setAppDetails,
  setObjCounter,
  setSubObjCounter,
  setObjFields,
  setSubObjFields,
  setBidderLabel,
  setInnerBidderLabel,
  setBidderParams,
  setSubBidderParams,
  setNotRequiredFields,
  setBiddersDisabled,
} from "../../redux/actions/commonActions";
import { useDispatch, useSelector } from "react-redux";
import SaveHb from "./SaveHb";
import productAdditionalFields from "./ProductAdditionalField";

const HeaderBidding = (props) => {
  const dispatch = useDispatch();
  const field_data = props.prodFields?.HeaderBidding ?? [];
  let apDetails_Fields = {};
  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let hb_params = {};
  let inner_hb_params = {};
  let bidder_Label = {};
  let inner_bidder_Label = {};
  let not_req_fields = [];
  let bidders_disabled = {};

  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [hbFormData, setHbFormData] = useState(props.hbFormData);
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };

  useEffect(() => {
    if (editFlag && hbFormData) {
      // console.log("inside edit fields");
      field_data.forEach((el) => {
        let field_value = hbFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        apDetails_Fields[el.name] = field_value;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type == "objectgroup") {
          el.fields.forEach((field) => {
            if (!field.isRequired) {
              not_req_fields.push(field.name);
            }
          });
          if (!isArr(field_value)) {
            let temp = [];
            temp.push(field_value);
            field_value = temp;
          }
          setHeaderBiddingObjGroupFields(el, field_value);
        }
      });
    } else {
      field_data.forEach((el) => {
        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] =
                  typeof subfield.default == "object"
                    ? {}
                    : subfield.default.toString();
              });
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object"
                  ? {}
                  : field.default?.toString();
            }
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        }
      });
    }
  }, [hbFormData]);

  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setSubObjCounter(subobjectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      dispatch(setBidderLabel(bidder_Label));
      dispatch(setInnerBidderLabel(inner_bidder_Label));
      dispatch(setBidderParams(hb_params));
      dispatch(setSubBidderParams(inner_hb_params));
      dispatch(setNotRequiredFields(not_req_fields));
      dispatch(setBiddersDisabled(bidders_disabled));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  function setHeaderBiddingObjGroupFields(el, field_value) {
    if (el.name === "hb_full_common_bidders") {
      for (let j = 0; j < field_value.length; j++) {
        let bid_obj = field_value[j];
        if (Object.keys(bid_obj).length > 0) {
          for (let x in bid_obj.params)
            hb_params[x + j] = bid_obj.params[x].toString();
          if (bid_obj.labelAny) {
            bid_obj["Labeltype"] = "labelAny";
            bidder_Label["CountryCode" + j] = bid_obj.labelAny.join(",");
          } else if (bid_obj.labelAll) {
            bid_obj["Labeltype"] = "labelAll";
            bidder_Label["CountryCode" + j] = bid_obj.labelAll;
          } else bid_obj["Labeltype"] = "none";
        }
      }
    }
    if (el.name === "mappings_full_hb_config") {
      for (let j = 0; j < field_value.length; j++) {
        for (let i = 0; i < field_value[j]["bidders"]?.length; i++) {
          let inner_bid_obj = field_value[j]["bidders"][i];
          for (let x in inner_bid_obj.params) {
            inner_hb_params[x + j + i] = inner_bid_obj.params[x].toString();
          }
          if (inner_bid_obj.labelAny) {
            inner_bid_obj["Labeltype"] = "labelAny";
            inner_bidder_Label["CountryCode" + j + i] =
              inner_bid_obj.labelAny.join(",");
          } else if (inner_bid_obj.labelAll) {
            inner_bid_obj["Labeltype"] = "labelAll";
            inner_bidder_Label["CountryCode" + j + i] = inner_bid_obj.labelAll;
          } else inner_bid_obj["Labeltype"] = "none";
        }
        if (field_value[j]["bidders"] === false) {
          bidders_disabled["bidders" + j] = true;
          field_value[j]["bidders"] = [];
        } else {
          bidders_disabled["bidders" + j] = false;
        }
      }
    }
    // if (el.name == "CURRENCY_CONVERSION") {
      // let emp_arr = [];
      // emp_arr.push(field_value);
      // field_value = emp_arr;
    // }
    if (el.name == "mappings_extra_units_config") {
      let emp_arr = [];
      emp_arr.push(field_value);
      field_value = emp_arr;
    }
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        if (field.type == "objectgroup") {
          subobjectgroup_counter[el.name + field.name] = [];
          subobjectgroup_fields[el.name + field.name] = {};
          for (let i = 0; i < field_value.length; i++) {
            subobjectgroup_counter[el.name + field.name].push([]);
            let len =
              field_value[i][field.name]?.length > 0
                ? field_value[i][field.name]?.length
                : 1;
            if (field.name === "ub_ClmbParams") {
              subobjectgroup_counter[el.name + field.name][i].push({});
            }
            for (let j = 0; j < len; j++) {
              if (field.name !== "ub_ClmbParams") {
                subobjectgroup_counter[el.name + field.name][i].push({});
              }

              field.fields.forEach((subfield, k) => {
                if (
                  field_value[i][field.name] <= 0 ||
                  Object.keys(field_value[i]).length <= 0 ||
                  field_value[i][field.name] === undefined
                ) {
                  subobjectgroup_fields[el.name + field.name][
                    subfield.name + i + j
                  ] = subfield.default;
                } else {
                  if (field.name === "ub_ClmbParams") {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + 0
                    ] =
                      field_value[i][field.name][k] !== undefined
                        ? field_value[i][field.name][k][subfield.name] ??
                          subfield.default
                        : subfield.default;
                  } else if (
                    field.name === "targetDiv" ||
                    field.name === "mgid" || field.name === "ub_adSense"
                  ) {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + 0
                    ] =
                      field_value[i][field.name] !== undefined
                        ? field_value[i][field.name][subfield.name] ??
                          subfield.default
                        : subfield.default;
                  } else {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + j
                    ] =
                      typeof subfield.default == "object"
                        ? subfield.name === "params"
                          ? field_value[i][field.name][j][subfield.name] ?? {}
                          : JSON.stringify(
                              field_value[i][field.name][j][subfield.name]
                            )?.trim() ?? field.default
                        : field_value[i][field.name][j][
                            subfield.name
                          ]?.toString() ?? field.default;
                  }
                }
              });
            }
          }
        } else {
          for (let i = 0; i < field_value.length; i++) {
            if (field_value[i] !== undefined) {
              if (
                field_value[i][field.name] === undefined ||
                Object.keys(field_value[i]).length <= 0
              ) {
                objectgroup_fields[el.name][field.name + i] = field.default;
              } else {
                objectgroup_fields[el.name][field.name + i] =
                  typeof field.default == "object"
                    ? JSON.parse(
                        JSON.stringify(field_value[i][field.name])?.trim()
                      ) ?? field.default
                    : field_value[i][field.name]?.toString() ?? field.default;
              }
              if (field.name === "sizesM" || field.name === "sizesD") {
                let field_arr =
                  field_value[i][field.name] ?? field_value[i]["sizes"];
                let sizeArr = [];
                field_arr?.forEach((arr, j) => {
                  let stArr = "[" + arr.toString() + "]";
                  if (field.selectOptions.includes(stArr)) {
                    sizeArr.push(stArr);
                  }
                });
                objectgroup_fields[el.name][field.name + i] = sizeArr;
              }
            }
          }
        }
      });
    }
  }

  function uploadHbDetails() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }
    dispatch(setAppDetails(uploadFile));
    setHbFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }

  useEffect(() => {
    if (props.uploadClicked) uploadHbDetails();
    props.setUploadClicked(false);
  }, [props.uploadClicked]);

  return (
    <span>
      {props.fieldSet && (
        <ObjGroup
          field_data={field_data}
          heading={props.heading}
          fieldSet={props.fieldSet}
          setHbFormData={setHbFormData}
          setFieldSet={props.setFieldSet}
          editFlag={editFlag}
        />
      )}
      {props.saveClicked ? <SaveHb {...props} /> : <></>}
    </span>
  );
};

export default HeaderBidding;
