import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

export default function ListComp(props) {
  const checked = props.checked;
  const setChecked = props.setChecked;

  const data = props.data;
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const selectAll = () => {
    let newChecked = [];
    if (checked.length < data.length) {
      data.forEach((field) => {
        newChecked.push(field.name);
      });
    }
    setChecked(newChecked);
  };

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem key="SelectAll" disablePadding>
        <ListItemButton role={undefined} onClick={selectAll} dense>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.length === data.length}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "selectall" }}
            />
          </ListItemIcon>
          <ListItemText id="selectall" primary="Select All" />
        </ListItemButton>
      </ListItem>
      {data.map((field) => {
        const labelId = `checkbox-list-label-${field.name}`;

        return (
          <ListItem key={field.name} disablePadding>
            <ListItemButton
              role={undefined}
              onClick={handleToggle(field.name)}
              dense
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(field.name) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={field.name} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
