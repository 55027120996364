const productAdditionalFields = {
  VideoPlayer: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isRequired: true,
    },
    {
      name: "LOAD_VD_TIMEOUT",
      label: "LOAD VD TIMEOUT",
      type: "Input",
      default: 8000,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "SIZE",
      label: "SIZE",
      type: "Dropdown",
      default: "640x360",
      selectOptions: [
        "640x360",
        "560x315",
        "544x306",
        "528x297",
        "400x300",
        "400x225",
        "360x203",
        "320x180",
        "300x250",
        "256x144",
        "192x108",
      ],
      isRequired: true,
    },
    {
      name: "PLAYER_WIDTH",
      label: "PLAYER WIDTH",
      type: "Input",
      default: 640,
      selectOptions: null,
      disabled: true,
      isRequired: true,
      isNum: true,
      display: "none",
    },
    {
      name: "PLAYER_HEIGHT",
      label: "PLAYER HEIGHT",
      type: "Input",
      default: 360,
      selectOptions: null,
      disabled: true,
      isRequired: true,
      isNum: true,
      display: "none",
    },
    {
      name: "SIZE_MB",
      label: "SIZE MB",
      type: "Dropdown",
      default: "400x225",
      selectOptions: [
        "560x315",
        "544x306",
        "528x297",
        "400x300",
        "400x225",
        "360x203",
        "320x180",
        "300x250",
        "256x144",
        "192x108",
      ],
      disabled: true,
      isRequired: true,
    },
    {
      name: "PLAYER_WIDTH_MB",
      label: "PLAYER WIDTH MB",
      type: "Input",
      default: 400,
      selectOptions: null,
      disabled: true,
      isRequired: true,
      isNum: true,
      display: "none",
    },
    {
      name: "PLAYER_HEIGHT_MB",
      label: "PLAYER HEIGHT MB",
      type: "Input",
      default: 225,
      selectOptions: null,
      disabled: true,
      isRequired: true,
      isNum: true,
      display: "none",
    },
    {
      name: "SCRIPT_TEST_LEVEL",
      label: "SCRIPT_TEST_LEVEL",
      type: "Dropdown",
      default: 0,
      selectOptions: [
        { label: "Disabled", value: 0 },
        { label: "Level 1", value: 1 },
        { label: "Level 2", value: 2 },
        { label: "Level 3", value: 3 },
        { label: "Level 4", value: 4 },
      ],
    },
    {
      name: "VIDEO_DYNAMIC_API",
      label: "VIDEO DYNAMIC API",
      type: "Input",
      default: "https://newsbot.unibots.in/get_videos/ubpvideos",
      selectOptions: null,
      isRequired: false,
    },
    {
      name: "VIDEO_SRC_DEFAULT",
      label: "VIDEO SRC DEFAULT",
      type: "Input",
      default:
        "https://stream.unibotscdn.com/25acc3a8-9b3c-41a6-92f3-216becb358f6/playlist.m3u8",
      selectOptions: null,
      isRequired: true,
    },
    {
      name: "CLIENTSITE_TARGETED_DIV_ID_CLASSES",
      label: "CLIENTSITE TARGETED DIV ID CLASSES",
      type: "Input",
      default: "",
      selectOptions: null,
      isRequired: false,
    },
    {
      name: "PL_BUTTON_LINK",
      label: "PL BUTTON LINK",
      type: "Input",
      default: "",
      selectOptions: null,
      isRequired: false,
    },
    {
      name: "CUSTOM_AD_DELAY",
      label: "CUSTOM AD DELAY",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: false,
    },
    {
      name: "FLOATING_BLOCKED_URL",
      label: "FLOATING BLOCKED URL",
      type: "Input",
      default: "",
      selectOptions: null,
      isRequired: false,
    },
    {
      name: "COUNTRY_ORIGIN",
      label: "Country Origin",
      type: "Dropdown",
      default: "",
      selectOptions: [],
      isRequired: false,
    },
    {
      name: "REQUIRED_SCROLL_FOR_PL_RUN",
      label: "REQUIRED SCROLL FOR PL RUN",
      type: "Input",
      default: 100,
      selectOptions: null,
      isBool: false,
      isRequired: true,
      isNum: true,
    },
    {
      name: "NEW_AD_REQUEST_VERSION",
      label: "NEW AD REQUEST VERSION",
      type: "Dropdown",
      default: 2,
      selectOptions: ["1", "2", "3", "4"],
      isRequired: true,
      isNum: true,
    },
    {
      name: "AD_DELAY_DURATIONS",
      label: "AD DELAY DURATIONS",
      type: "Input",
      default: [900, 30, 900],
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "array(int)",
    },
    {
      name: "PLAYER_BLOCKED_URL",
      label: "PLAYER BLOCKED URL",
      type: "Input",
      default: [],
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "array",
    },
    {
      name: "GEO_LOCATIONS_TO_BLOCK",
      label: "GEO LOCATIONS TO BLOCK",
      type: "Input",
      default: [],
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "array",
    },
    {
      name: "DIV_ID_CLASS",
      label: "DIV ID CLASS",
      type: "Input",
      default: [],
      selectOptions: null,
      isBool: false,
      isRequired: true,
      saveType: "array",
    },
    {
      name: "AD_PLAY_VERSION",
      label: "AD PLAY VERSION",
      type: "Input",
      default: 4,
      isNum: true,
      inDevlopment: true,
    },
    {
      name: "AD_REQUEST_DURATION",
      label: "Ad Request Duration",
      type: "Input",
      default: 0,
      isNum: true,
    },
    {
      name: "SKIP_VIDEO_CONTENT_ON_START",
      label: "SKIP VIDEO CONTENT ON START",
      type: "Input",
      default: 0,
      isNum: true,
    },
    {
      name: "PREROLL_TIMEOUT",
      label: "PREROLL TIMEOUT ",
      type: "Input",
      default: 10000,
      isNum: true,
    },
    {
      name: "REQUIRED_PX_FOR_NEARBY_VIEWPORT",
      label: "REQUIRED PX FOR NEARBY VIEWPORT",
      type: "Input",
      default: 0,
      isNum: true,
    },
    {
      "name": "CLARITY_CODE",
      "label": "CLARITY CODE",
      "type": "Input",
      "default": "",
      "selectOptions": null,
      "isBool": false,
      "isRequired": false
    },
    {
      name: "AD_PLAY_DURATION_VERSION",
      label: "AD PLAY DURATION VERSION",
      type: "Input",
      default: 2,
      isNum: true,
      line: true,
    },
    {
      name: "MANAGE_DEALY_FOR_UNFILL_REQUESTS",
      label: "MANAGE DEALY FOR UNFILL REQUESTS",
      type: "objectgroup",
      default: {},
      index: "static",
      fields: [
        {
          name: "IS_ENABLE",
          label: "IS ENABLE",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
        {
          name: "AFTER",
          label: "AFTER",
          type: "Input",
          default: 5,
          selectOptions: null,
          isBool: false,
          isNum: true,
          saveType: "number",
        },
        {
          name: "DELAY",
          label: "DELAY",
          type: "Input",
          default: 1,
          selectOptions: null,
          isBool: false,
          isNum: true,
          saveType: "number",
        },
        {
          name: "DEFAULT",
          label: "DEFAULT",
          type: "Input",
          default: 1,
          selectOptions: null,
          isBool: false,
          isNum: true,
          saveType: "float",
        },
        {
          name: "PASSBACK_URL",
          label: "PASSBACK_URL",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
        },
        {
          name: "4C_COMPAITABLE",
          label: "4C COMPAITABLE",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
      ],
      isRequired: false,
      addButton: false,
    },
    {
      name: "CLICK_TO_PLAY",
      label: "CLICK TO PLAY",
      type: "objectgroup",
      default: {},
      index: "static",
      fields: [
        {
          name: "IS_ENABLE",
          label: "IS ENABLE",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
        {
          name: "WAITING",
          label: "WAITING",
          type: "Input",
          default: 0,
          selectOptions: null,
          isBool: false,
          isNum: true,
          saveType: "number",
        },
        {
          name: "KEEP_FLOAT_AFTER_AUTOPLAY",
          label: "KEEP FLOAT AFTER AUTOPLAY",
          type: "switch",
          default: false,
          isBool: true,
          isRequired: true,
        },
      ],
      isRequired: false,
      addButton: false,
    },
    {
      name: "UBP_DEBUG",
      label: "UBP DEBUG",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "ENABLE_CUSTOM_GA",
      label: "Unibots Custom GA",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "AS_PER_NEW_POLICY",
      label: "AS PER NEW POLICY",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "LOAD_PL_WITH_DELAY",
      label: "LOAD PL WITH DELAY",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "STICKY_TOP",
      label: "STICKY TOP",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "PLAYER_FLOATING",
      label: "PLAYER FLOATING",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IS_FLOATING_ON_MOBILE",
      label: "IS FLOATING ON MOBILE",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "FLOATING_ONLY_AFTER_INVIEW",
      label: "FLOATING ONLY AFTER INVIEW",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "FLOATING_ONLY_AFTER_INVIEW_MB",
      label: "FLOATING ONLY AFTER INVIEW MB",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "FLOAT_ONCE_PER_USER",
      label: "FLOAT ONCE PER USER",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "FLOAT_ABOVE_VIEW",
      label: "FLOAT ABOVE VIEW",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "SHORT_TIME_FLOATING",
      label: "SHORT TIME FLOATING",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "ENABLED_FOR_PC",
      label: "ENABLED FOR PC",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "ENABLED_FOR_MB",
      label: "ENABLED FOR MB",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "VIDEO_DYNAMIC",
      label: "VIDEO DYNAMIC",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IFRAME_PLAYER",
      label: "IFRAME PLAYER",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "AUTO_DIV",
      label: "Auto Div",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "PL_BUTTON_ENABLE",
      label: "PL BUTTON ENABLE",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "LOGO_ENABLE",
      label: "LOGO ENABLE",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "LOGO_ENABLE_MB",
      label: "LOGO ENABLE MB",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "REQUEST_SINGLE_AD",
      label: "REQUEST SINGLE AD",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "BLOCK_GEO_LOCATION",
      label: "BLOCK GEO LOCATION",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CLOSE_BUTTON_ENABLE",
      label: "CLOSE BUTTON ENABLE",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CLOSE_BUTTON_ENABLE_MB",
      label: "CLOSE BUTTON ENABLE MB",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CLOSE_BUTTON_DISPOSE_PLAYER",
      label: "CLOSE BUTTON DISPOSE PLAYER",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IS_PERMANENT_STICKY",
      label: "IS PERMANENT STICKY",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "CHECK_BLOCKED_PAGES",
      label: "CHECK BLOCKED PAGES",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "RECREATE_PLAYER",
      label: "RECREATE PLAYER",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "NEW_CUSTOM_CSS_MODULE",
      label: "NEW CUSTOM CSS MODULE",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "IS_FLOATING_BLOCK_ON_SOME_URLS",
      label: "IS FLOATING BLOCK ON SOME URLS",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "PL_RUN_AFTER_PAGE_SCROLL",
      label: "PL RUN AFTER PAGE SCROLL",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: true,
    },
    {
      name: "AD_DELAY_ENABLED",
      label: "AD DELAY ENABLED",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "STICKY_ONCE_VIEWED",
      label: "STICKY ONCE VIEWED",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "HIDE_CLOSE_BTN_INREAD",
      label: "HIDE CLOSE BTN INREAD",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "AD_TAGS_SHUFFLING",
      label: "AD TAGS SHUFFLING",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "AD_POD_REQUEST",
      label: "AD_POD_REQUEST",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "TEST_MODULES",
      label: "TEST MODULES",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "RUN_PLAY_ONLY_NEARBY_VIEWPORT",
      label: "RUN PLAY ONLY NEARBY VIEWPORT",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "HLS_ENABLED",
      label: "HLS ENABLED",
      type: "switch",
      default: true,
      isBool: true,
    },
    {
      name: "Edit_Warning",
      label: "Edit Warning",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "DISABLE_LOGS",
      label: "DISABLE LOGS",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "STICKY_POSITION",
      label: "STICKY POSITION",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isRequired: true,
    },
    {
      name: "FLOATING_POSITION_MB",
      label: "FLOATING POSITION MB",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isRequired: true,
    },
    {
      name: "IS_PERMANENT_STICKY_POSITION",
      label: "IS PERMANENT STICKY POSITION",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isRequired: true,
    },
    {
      name: "IS_PERMANENT_STICKY_POSITION_MB",
      label: "IS PERMANENT STICKY POSITION MB",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isRequired: true,
    },
    {
      name: "AD_MODE",
      label: "AD MODE",
      type: "radio",
      default: "live",
      selectOptions: ["live", "test"],
      isRequired: true,
    },
    {
      name: "AD_REQUEST_TYPE",
      label: "AD REQUEST TYPE",
      type: "radio",
      default: "new",
      selectOptions: ["old", "new"],
      isRequired: true,
    },
    {
      name: "UB_CUSTOM_CSS",
      label: "UB CUSTOM CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
    },
    {
      name: "USE_OF_CUSTOM_CSS",
      label: "USE OF CUSTOM CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
    },
    {
      name: "CUSTOM_JS",
      label: "CUSTOM JS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
    },
    {
      name: "XML_ADS",
      label: "ENABLE XML ADS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
    },
    {
      name: "COUNTRY_KV",
      label: "COUNTRY KV",
      type: "objectgroup",
      fields: [
        {
          name: "COUNTRY",
          label: "COUNTRY",
          type: "Dropdown",
          default: "",
          selectOptions: [],
          isRequired: false,
        },
        {
          name: "MAIN",
          label: "MAIN",
          type: "MultiInputField",
          default: [],
          selectOptions: null,
          isBool: false,
          isRequired: false
        },
        {
          name: "NEW_MAIN",
          label: "NEW_MAIN",
          type: "MultiInputField",
          default: [],
          selectOptions: null,
          isBool: false,
          isRequired: false          
        },
      ],
      default: [{}],
      addButton: true,
      selectOptions: null,
      isBool: false,
    },
    {
      name: "AD_CODES",
      label: "AD CODES",
      type: "Textarea",
      default: [],
      selectOptions: null,
      isRequired: true,
    },
  ],
};

export default productAdditionalFields;
