import { useEffect, useState, useRef } from "react";
import productAdditionalFields from "../NewsBot/ProductAdditionalField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppDetails,
  setObjCounter,
  setObjFields,
  setSubObjCounter,
  setSubObjFields,
} from "../../redux/actions/commonActions";
import ObjGroup from "../Objectgroup/ObjGroup";
import SaveNb from "./SaveNb";

const NewsBot = (props) => {
  // console.log("newsbot props", props);
  const dispatch = useDispatch();
  const field_data = productAdditionalFields.NewsBot;
  let apDetails_Fields = {};
  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let not_req_fields = [];

  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );
  const subobjectGroupCounter = useSelector(
    (state) => state.common.subobjectGroupCounter
  );
  const subobjectGroupDetails = useSelector(
    (state) => state.common.subobjectGroupDetails
  );

  const [botCatNum, setBotCatNum] = useState(-1);
  const [catName, setCatName] = useState("");
  const capPubName = useRef("");
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };
  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [newsFormData, setNewsFormData] = useState(props.newsFormData);

  const getObjFromString = (str) => {
    if (editFlag && props.setFieldSet) {
      const keys = Object.keys(str);
      const values = Object.values(str);
      let res = "";
      for (let i = 0; i < keys.length; i++) {
        if (i == keys.length - 1) {
          res += keys[i] + "-" + values[i];
        } else {
          res += keys[i] + "-" + values[i] + ",";
        }
      }
      return res;
    } else {
      let obj = {};
      if (str) {
        let res = str.split(",");
        for (let i = 0; i < res.length; i++) {
          let check = res[i].split("-");
          for (let i = 0; i < check.length - 1; i++) {
            obj[check[i]] = check[i + 1];
          }
        }
      }
      return obj;
    }
  };

  useEffect(() => {
    if (editFlag && newsFormData) {
      field_data.forEach((el) => {
        console.log("useeffect el-----", el);
        if (el.addCount !== undefined && botCatNum < 0) {
          el.addCount = newsFormData[el.name].length;
        }
        if (el.catNum && botCatNum < 0) {
          let addLimit = newsFormData[el.name] ?? el.default;
          setBotCatNum(addLimit);
          setCatName(el.name);
        }
        console.log("newsFormData[el.name]",newsFormData[el.name]);
        
        let field_value = newsFormData[el.name] ?? el.default;
        console.log(el.name," field_value",field_value)
        if (typeof field_value == "object") {
          field_value = JSON.parse(JSON.stringify(field_value));
        }
        if (el.type === "upload") {
          console.log("myel",el,field_value)
          el.name = field_value;
          console.log("field_val",field_value)
        }        
        if (el.name === "CustomSearchTexts") {
          apDetails_Fields[el.name] = getObjFromString(field_value);
        } else {
          apDetails_Fields[el.name] = field_value;
        }
        if (el.type == "objectgroup") {
          if (!isArr(field_value)) {
            let temp = [];
            temp.push(field_value);
            field_value = temp;
          }
          setNewsBotObjGroupFields(el, field_value);
        } else {
          if (!el.isRequired) not_req_fields.push(el.name);
        }
      });
    } else {
      field_data.forEach((el) => {
        //assigning field add limit
        if (el.catNum && botCatNum < 0) {
          setBotCatNum(el.default);
          setCatName(el.name);
        }

        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;

        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] =
                  typeof subfield.default == "object"
                    ? {}
                    : subfield.default.toString();
              });
            } else if (field.name === "BotMenuCustomTopText") {
              objectgroup_fields[el.name][
                field.name + "0"
              ] = `Welcome to ${capPubName.current} reporter. What kind of updates would you like today?`;
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object"
                  ? {}
                  : field.default?.toString();
            }
            if (!field.isRequired) {
              not_req_fields.push(field.name);
            }
          });
        } else {
          if (!el.isRequired) not_req_fields.push(el.name);
        }
      });
    }
  }, [newsFormData]);

  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setSubObjCounter(subobjectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  const setNewsBotObjGroupFields = (el, field_value) => {
    console.log("field_value", field_value);
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        if (field.type == "objectgroup") {
          subobjectgroup_counter[el.name + field.name] = [];
          subobjectgroup_fields[el.name + field.name] = {};
          for (let i = 0; i < field_value.length; i++) {
            console.log("field.val", field.value);
            subobjectgroup_counter[el.name + field.name].push([]);
            let len =
              field_value[i][field.name]?.length > 0
                ? field_value[i][field.name]?.length
                : 1;
            for (let j = 0; j < len; j++) {
              subobjectgroup_counter[el.name + field.name][i].push({});
              field.fields.forEach((subfield, k) => {
                if (
                  field_value[i][field.name] <= 0 ||
                  Object.keys(field_value[i]).length <= 0
                ) {
                  subobjectgroup_fields[el.name + field.name][
                    subfield.name + i + j
                  ] =
                    typeof subfield.default == "object"
                      ? {} ?? JSON.stringify(subfield.default).trim()
                      : subfield.default != null
                      ? subfield.default.toString()
                      : "";
                }
              });
            }
          }
          if (!field.isRequired) {
            not_req_fields.push(field.name);
          }
        } else {
          console.log("2----");
          let len = Object.keys(field_value).length;
          // for (let i = 0; i < len; i++) {
          for (let i = 0; i < field_value.length; i++) {
            if (field.dataType === "Object" || el.name === "MyBotCat") {
              let botcat = field_value[i];

              let catvalues = Object.values(botcat);

              if ([field.name + i][0].includes("Category_Key")) {
                console.log("inside cat key");
                objectgroup_fields[el.name][field.name + i] = catvalues[0];
              } else if ([field.name + i][0].includes("Category_Value")) {
                objectgroup_fields[el.name][field.name + i] = catvalues[1];
              }
            } else {
              objectgroup_fields[el.name][field.name + i] =
                typeof field.default == "object"
                  ? JSON.stringify(field_value[i][field.name]).trim() ?? {}
                  : field_value[i][field.name] !== null
                  ? field_value[i][field.name]?.toString()
                  : "";
              console.log(field_value, field.name);
            }
          }
        }
      });
    }
  };

  function uploadNewsBotDetails() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }

    dispatch(setAppDetails(uploadFile));
    setNewsFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }

  useEffect(() => {
    if (props.uploadClicked) uploadNewsBotDetails();
    props.setUploadClicked(false);
  }, [props.uploadClicked]);
  return (
    <span>
      <ObjGroup
        field_data={field_data}
        catName={catName}
        botCatNum={botCatNum}
        setBotCatNum={setBotCatNum}
        fieldSet={props.fieldSet}
        heading={props.heading}
        clientname={props.clientname}
        showAlertMsg={props.showAlertMsg}
        showSuccessMsg={props.showSuccessMsg}
      />
      {props.saveClicked ? <SaveNb {...props} /> : <></>}
    </span>
  );
};

export default NewsBot;
