// Action Creator
const updatePerson=(person)=> {
    return {
        type: 'UPDATE_USER_DATA',
        data: person
    }
}

export { updatePerson};

