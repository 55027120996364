import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { setOverrideFields } from "../../redux/actions/commonActions";
import AMPGists from "../../Products/AMPGists/AMPGists";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  color: "Black",
};
const NestCheck = (props) => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [changedValues, setChangedValues] = useState(null);

  const [open, setOpen] = React.useState(true);

  const handleClose = () => setOpen(false);
  const handleChange = (e) => {
    props.setMatch();

    setOpen(false);
  };
  const setVpOverride = (vpJson) => {
    let overrideFields = [];
    for (let field in vpJson) {
      if (vpJson[field]["OVERRIDE"]) {
        overrideFields.push(field);
      }
    }
    dispatch(setOverrideFields(overrideFields));
  };
  // for db and bunny values compare
  useEffect(() => {
    let notMatched = {};
    const fetchBrand = () => {
      let ub_clientData = {};
      let heading = props.heading?.toLowerCase();
      if (heading === "videoplayer") {
        heading = "clientdata";
      }
      const bunny_clientName =
        `https://cdn.unibots.in/${heading}/` +
        props.formData.clientName +
        ".json";
      fetch(bunny_clientName)
        .then((res) => res.json())
        .then((data) => {
          ub_clientData = data;
          if (heading === "clientdata") {
            setVpOverride(ub_clientData);
          }
          if (
            JSON.stringify(ub_clientData) ===
            JSON.stringify(props.formData["additionalDetails" + props.heading])
          ) {
            console.log("Data Matched!");
          } else {
            console.log("Data Mismatched!");
            nestedCheck(
              ub_clientData,
              props.formData["additionalDetails" + props.heading]
            );
            // alert(`Data Mismatched!\n\n${Object.keys(notMatched).join("\n")}
            // `);
            if (!Object.keys(notMatched).includes("Config")) {
              setShowAlert(true);
            }
            setChangedValues(notMatched);
            // props.setClientData(details);
          }
        })
        .catch((err) => {
          console.log(
            props.formData.clientName + " " + heading + " not present!"
          );
          console.error(err);
        });
    };
    if (props.editFlag && props.heading !== "AMPGists") {
      fetchBrand();
    }
    const nestedCheck = (item1, item2, name) => {
      if (item1 !== undefined && item2 !== undefined) {
        let isArr = Object.prototype.toString.call(item1) == "[object Array]";
        let isObj = typeof item1 === "object";
        if (isArr) {
          item1.forEach((el, i) => {
            nestedCheck(el, item2[i], name);
          });
        } else if (isObj) {
          for (let key in item1) {
            nestedCheck(item1[key], item2[key], key);
          }
        } else {
          if (item1 !== item2) {
            notMatched[name] = {
              valueCDN: item1,
              valueDB: item2,
            };
          }
          return 0;
        }
      } else {
        notMatched[name ?? "Config"] = {
          valueCDN: item1 ? "Present" : "Not present",
          valueDB: item2 ? "Present" : "Not present",
        };
      }
    };
  }, [props.heading]);

  return (
    <>
      {showAlert && changedValues && open ? (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Mismatched Fields
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {/* <p>{Object.entries(JSON.parse(changedValues))}</p> */}
                  {Object.entries(changedValues)?.map((item, i) => (
                    <p>{`Field ${item[0]} mismatched `}</p>
                  ))}
                </Typography>
                <Button onClick={handleChange}>Change in Json</Button>
                <Button onClick={handleClose}>Close</Button>
              </Box>
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default NestCheck;
