import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Button,
  Switch,
  Checkbox,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import productAdditionalFields from "./ProductAdditionalField";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppDetails,
  setObjCounter,
  setObjFields,
  setChangedFields,
} from "../../redux/actions/commonActions";
import ObjGroup from "../Objectgroup/ObjGroup";
import { trackChange } from "../../utils/TrackChange";

function ContentRecom(props) {
  const dispatch = useDispatch();
  const field_data = productAdditionalFields.ContentRecom;
  let apDetails_Fields = {};
  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let not_req_fields = [];

  const [showFieldPop, setShowFieldPop] = useState(false);
  const [showFieldAnchor, setShowFieldAnchor] = useState(false);
  const [showFieldParallax, setShowFieldParallax] = useState(false);
  const [showFieldImage, setShowFieldImage] = useState(false);
  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [contentFormData, setContentFormData] = useState(props.contentFormData);
  const changedFields = useSelector((state) => state.common.changedField);
  // let [customFields, setCustomFields] = useState([])

  useEffect(() => {
    if (editFlag && contentFormData) {
      // console.log("inside edit fields");
      field_data.forEach((el) => {
        let field_value = contentFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        apDetails_Fields[el.name] = field_value;
        if (el.type == "objectgroup") {
          setContenFields(el, field_value);
        }
        if (el.type === "objectgroup") {
          el.fields.forEach((field) => {
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        } else {
          if (!el.isRequired) not_req_fields.push(el.name);
        }
      });
    } else {
      field_data.forEach((el) => {
        //assigning field add limit

        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;
        if (el.type !== "objectgroup") {
          if (!el.isRequired) not_req_fields.push(el.name);
        }
        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] =
                  typeof subfield.default == "object"
                    ? {}
                    : subfield.default.toString();
              });
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object"
                  ? {}
                  : field.default?.toString();
            }
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        }
      });
    }
  }, [contentFormData]);

  const setShow = (data, field) => {
    // console.log(data)
    // console.log(field)
    if (field === "UB_POPUP") {
      setShowFieldPop(data);
    } else if (field === "UB_Anchor") {
      setShowFieldAnchor(data);
    } else if (field === "Parallax_AD") {
      setShowFieldParallax(data);
    } else if (field === "Inimage_AD") {
      setShowFieldImage(data);
    }
  };

  const addProdDetails = useSelector((state) => state.common.addProdDetails);

  const objectGroupCounter = useSelector(
    (state) => state.common.objectGroupCounter
  );
  const objectGroupDetails = useSelector(
    (state) => state.common.objectGroupDetails
  );

  function setContenFields(el, field_value) {
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        if (field.type == "objectgroup") {
          subobjectgroup_counter[el.name + field.name] = [];
          subobjectgroup_fields[el.name + field.name] = {};

          for (let i = 0; i < field_value.length; i++) {
            subobjectgroup_counter[el.name + field.name].push([]);
            let len =
              field_value[i][field.name]?.length > 0
                ? field_value[i][field.name]?.length
                : 1;
            for (let j = 0; j < len; j++) {
              subobjectgroup_counter[el.name + field.name][i].push({});
            }
          }
        } else {
          for (let i = 0; i < field_value.length; i++) {
            objectgroup_fields[el.name][field.name + i] =
              typeof field.default == "object"
                ? {} ?? JSON.stringify(field_value[i][field.name]).trim()
                : field_value[i][field.name]
                ? field_value[i][field.name].toString()
                : "";

            // if(objectgroup_fields[el.name][field.name + i] === "custom"){
            //   if(el.name === "POPUP"){
            //     setShowFieldPop("true")
            //   }else if (el.name === "InImage"){
            //     setShowFieldImage("true")
            //   }else if (el.name === "Parallax"){
            //     setShowFieldParallax("true")
            //   }else if (el.name === "Anchor"){
            //     setShowFieldAnchor("true")
            //   }
            // }
          }
        }
      });
    }
  }

  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  useEffect(() => {
    if (props.uploadClicked) uploadContentDetails();
    props.setUploadClicked(false);
  }, [props.uploadClicked]);

  function uploadContentDetails() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }

    dispatch(setAppDetails(uploadFile));
    setContentFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }

  const handleSubChange = (event, saveType) => {
    dispatch(
      setAppDetails({
        ...addProdDetails,
        [event.target.name]:
          saveType == "boolean"
            ? event.target.value == "true"
            : saveType == "number"
            ? parseInt(event.target.value)
            : event.target.value,
      })
    );
  };
  const getArrayFromString = (str) => {
    console.log(str);
    let arr = [];
    if (str) arr = str.toString().split(",");

    return arr;
  };
  const getObjFromString = (str) => {
    let obj = {};
    if (str) {
      let res = str.split(",");
      for (let i = 0; i < res.length; i++) {
        let check = res[i].split("-");
        for (let i = 0; i < check.length - 1; i++) {
          obj[check[i]] = check[i + 1];
        }
      }
    }
    return obj;
  };
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };

  const getArrayFromTextArea = (str) => {
    let arr = [];
    if (str) arr = str?.split("\n");
    return arr;
  };
  const getSizeArrayFromString = (str) => {
    let arr;

    try {
      arr = JSON.parse(str);
      console.log(str, arr);
    } catch (e) {
      let newStr = "[" + str + "]";
      arr = JSON.parse(newStr);
    }

    return arr;
  };

  useEffect(() => {
    if (props.saveClicked) {
      saveContentRecom();
    }
  }, [props.saveClicked]);

  function saveContentRecom() {
    const toSaveObject = { ...addProdDetails };
    let flag = true;
    for (let key in toSaveObject) {
      // console.log([key])
      if (toSaveObject[key]?.length === 0 && !not_req_fields.includes(key)) {
        console.log([key]);
        flag = false;
        console.log(`Please fill ${key} field`);
        props.showAlertMsg(`Please fill ${key} field`);
        break;
      }
    }
    if (flag) {
      field_data.forEach((el) => {
        if (el.isArray)
          toSaveObject[el.name] = getArrayFromString(toSaveObject[el.name]);

        if (el.dataType === "Object") {
          toSaveObject[el.name] = getObjFromString(toSaveObject[el.name]);
        }
      });

      field_data.forEach((el) => {
        if (el.type == "objectgroup") {
          let objlen = objectGroupCounter[el.name].length;
          for (let i = 0; i < objlen; i++) {
            let obj = {};
            el.fields.forEach((sub_el) => {
              if (sub_el.type !== "objectgroup") {
                switch (sub_el.saveType) {
                  case "bool":
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i] == "true";
                    break;
                  case "array":
                    obj[sub_el.name] = getArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "textarea_array":
                    obj[sub_el.name] = getArrayFromTextArea(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "number":
                    obj[sub_el.name] = parseInt(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  case "size_array":
                    obj[sub_el.name] = getSizeArrayFromString(
                      objectGroupDetails[el.name][sub_el.name + i]
                    );
                    break;
                  default:
                    obj[sub_el.name] =
                      objectGroupDetails[el.name][sub_el.name + i];
                    break;
                }
              }
            });
            if (typeof el.default == "object" && !isArr(el.default)) {
              if (el.index === "static") {
                toSaveObject[el.name] = obj;
              } else {
                el.fields.forEach((field) => {
                  let key = field.name;
                  toSaveObject[el.name][key + (i + 1)] = obj[key];
                });
              }
            } else {
              toSaveObject[el.name][i] = obj;
            }
          }
        }
      });
      if (props.editFlag) {
        let changes = JSON.parse(JSON.stringify(changedFields));
        const notMatched = trackChange(props.contentFormData, toSaveObject, changes);
        dispatch(setChangedFields(notMatched));
      }
      if (flag) {
        let event = {
          target: {
            name: "",
            value: "",
          },
        };
        console.log("yes", toSaveObject);
        event.target.name = "additionalDetails" + props.heading;
        event.target.value = toSaveObject;
        props.handleChange(event);
        props.handleClose("D" + props.index);
      }
    }
    props.setSaveClicked(false);
  }
  return (
    <span>
      <ObjGroup field_data={field_data} fieldSet={props.fieldSet} heading={props.heading} />
    </span>
  );
}

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root, & .MuiOutlinedInput-input": {
    color: "white",
  },
});
const CssSelect = styled(Select)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "white",
    },
  },
  "& .MuiInputLabel-root, & .MuiOutlinedInput-input": {
    color: "white",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "white",
  },
});

export default ContentRecom;
