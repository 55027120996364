import * as React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  IconButton,
  Tooltip,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { styled } from "@mui/material/styles";

import { useState } from "react";

const SwitchInput = (props) => {
  const data = props.data;
  const field = props.field;
  const [openTip, setOpenTip] = useState(false);

  let checked =
    data.prodDetails[field.name + data.index] ?? data.prodDetails[field.name];
  if (typeof checked === "string") {
    checked = checked === "true";
  }

  let key = data?.index !== undefined ? field.name + data.index : field.name;

  return (
    <FormControl
      component="fieldset"
      sx={{
        m: 2,
        p: 0,
        width: "10%",
      }}
    >
      <FormLabel sx={{ fontSize: "13px" }} component="legend">
        {field.label}
        <ClickAwayListener
          onClickAway={() => {
            setOpenTip(false);
          }}
        >
          <Tooltip
            title="example"
            open={openTip}
            onClose={() => {
              setOpenTip(false);
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <IconButton
              onClick={() => {
                setOpenTip((prev) => !prev);
              }}
            >
              <HelpOutlineIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      </FormLabel>
      <FormControlLabel
        control={
          <>
            <CssSwitch
              sx={{ transition: "transform 50ms linear" }}
              name={
                data.name !== undefined
                  ? data.name + "#" + field.name + data.index
                  : field.name
              }
              disabled={props.disabled}
              key={key + Math.random()}
              defaultChecked={checked}
              onBlur={(e) => {
                if (props.custom) {
                  props.handleChange(e);
                } else if (data.name) {
                  data.handleSubChange({}, e.target.checked, e.target.name);
                } else {
                  data.handleSubChange(
                    e,
                    field.isBool ? "switchbool" : "string"
                  );
                }
              }}
            />
          </>
        }
      />
    </FormControl>
  );
};

const CssSwitch = styled(Switch)({
  "& .MuiSwitch-switchBase": {
    transition: "unset",
  },
});

export default SwitchInput;
