import { diff } from "deep-diff";
import { isObject, isArray, isEqual } from "lodash";
// to compare the objects for users logs
export const trackChange = (originalObj, updatedObj, notMatched, name) => {
  if (originalObj !== undefined && updatedObj !== undefined) {    
    const diffrnc = diff(originalObj, updatedObj);
    // console.log("difference",diffrnc);
    // let diffrnc = diffrnc1?.filter((diffEntry) => {
    //   return (!(diffEntry.path[0]==='AD_CODES' && diffEntry.path.length === 2 && diffEntry.kind === "E"));
    // });
  // console.log("changed fields are: ",diffrnc);
  if(diffrnc){
      for (const diffEntry of diffrnc) {
        const path = diffEntry.path;   // Property path that has changed //index= path[1]
        let pathString;
        if(path.includes("mappings_full_hb_config") && path[2]){
          let inputString = originalObj.mappings_full_hb_config[path[1]]?.adUnitName;
          const stringWithoutSlash = inputString.substring(1);
          const parts = stringWithoutSlash.split('/');
          const lastPart = parts[parts.length - 1];
          pathString = path[2] + "(" + lastPart + ")";
        }else{
          pathString = path.join('.');
        }
          let lhs_ ,rhs_;                       //Left-hand side (original) value  Right-hand side (new) value
        if (diffEntry.item?.kind === "D" || diffEntry?.kind === "D") {
          let dlt_lhs = diffEntry.item ? diffEntry.item.lhs : diffEntry.lhs;
          if(isObject(dlt_lhs)){
            lhs_ = dlt_lhs.adUnitName ? JSON.stringify(dlt_lhs.adUnitName) : JSON.stringify(dlt_lhs); 
          }else if(isArray(dlt_lhs)){
            lhs_ = dlt_lhs.join('.');
          }else{
            lhs_ = dlt_lhs;
          }
          rhs_ = "deleted";
        } else if(diffEntry?.kind === "N" || diffEntry.item?.kind === "N"){
          let added_rhs = diffEntry.item ? diffEntry.item.rhs : diffEntry.rhs;
          if(isObject(added_rhs)){
            rhs_ = added_rhs.adUnitName ? JSON.stringify(added_rhs.adUnitName) + " added" : JSON.stringify(added_rhs); 
          }else if(isArray(added_rhs)){
            rhs_ = added_rhs.join('.');       
          }else{
            rhs_ = added_rhs;
          }
          lhs_ = "Not Present";
        } else {
          if(isObject(diffEntry.lhs)){
            lhs_ = JSON.stringify(diffEntry.lhs);
          }else if(isArray(diffEntry.lhs)){
            lhs_ = diffEntry.lhs.join('.');           
          }else{
            lhs_ = diffEntry.lhs;
          }

          if(isObject(diffEntry.rhs)){
            const objString = JSON.stringify(diffEntry.rhs);
            rhs_ = objString;
          }else if(isArray(diffEntry.rhs)){
            rhs_ = diffEntry.rhs.join('.');     
          }else{
            rhs_ = diffEntry.rhs;
          }
        }
        
      if(!isEqual(originalObj, updatedObj))
      {
        notMatched.push({
          name: pathString,
          valueCDN: lhs_ ,
          valueDB: rhs_ ,
        });
      }
    }
  }
  }
  else{
    notMatched.push({
      name: name ?? "Config",
      valueCDN: originalObj ? "Present" : "Not present",
      valueDB: updatedObj ? "Present" : "Not present",
    });
  }
    return notMatched;
};
