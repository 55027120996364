import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import DropdownMultiInput from "../../components/Inputs/DropdownMultiInput";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  setSubObjFields,
  setSubObjCounter,
  setObjFields,
  setObjCounter,
} from "../../redux/actions/commonActions";

import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";

import { IconButton, Button } from "@mui/material";
import DialogPopup from "./DialogPopup";

const GridTable = (props) => {
  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const dispatch = useDispatch();
  const XLSX = require("xlsx");
  const [addNum, setAddNum] = useState(1);
  const [prevId, setPrevId] = useState(0);
  const [upload, setUpload] = useState(null);
  const [slots, setSlots] = useState(null);
  const [bidderNames, setBidderNames] = useState([]);
  const [dialogClosed, setDialogClosed] = useState(false);
  let initAdUnit = {
    id: 1,
    adUnitDisplay: "B",
    adUnitName: "Please fill ad unit 1 here",
    adId: "",
    sizesM: [],
    sizesD: [],
    bidders: [],
    isAP: true,
    include_homepage: [],
    include_category: [],
    exclude_homepage: [],
    exclude_category: [],
    ub_ClmbParams: [
      {
        ub_Clmbenable: false,
        ub_Dslotvalue: "463945",
        ub_Mslotvalue: "463946",
        ub_urlclmb: "https://static.clmbtech.com/ase/91170/3748/c1.js",
      },
    ],
    disabled: false,
    refresh: true,
  };
  const [adUnit, setAdUnit] = useState(initAdUnit);
  const [rows, setRows] = useState([initAdUnit]);
  const [newAdded, setNewAdded] = useState(false);

  const bidderDefault = {
    adf: ["mid", "mid1", "mid2"],
    openx: ["delDomain|unibots-d.openx.net", "unit"],
    pubmatic: ["publisherId|159448", "adSlot"],
    sovrn: ["tagid"],
    teads: ["placementId", "pageId", "websiteId"],
    ucfunnel: ["adid"],
    yandex: ["pageId", "impId"],
    fluct: ["groupId", "tagId"]
  };
  const desktopSizes = [
    "[120,240]",
    "[120,300]",
    "[120,600]",
    "[160,300]",
    "[160,600]",
    "[160,240]",
    "[200,200]",
    "[234,60]",
    "[240,400]",
    "[250,250]",
    "[300,50]",
    "[300,75]",
    "[300,100]",
    "[300,250]",
    "[300,300]",
    "[300,480]",
    "[300,600]",
    "[320,50]",
    "[320,90]",
    "[320,100]",
    "[320,240]",
    "[320,250]",
    "[320,280]",
    "[320,400]",
    "[320,480]",
    "[336,250]",
    "[336,280]",
    "[336,480]",
    "[360,280]",
    "[360,300]",
    "[400,225]",
    "[468,60]",
    "[480,300]",
    "[480,320]",
    "[570,90]",
    "[580,90]",
    "[580,400]",
    "[600,90]",
    "[630,90]",
    "[640,90]",
    "[640,180]",
    "[640,250]",
    "[650,90]",
    "[670,90]",
    "[675,90]",
    "[690,90]",
    "[720,300]",
    "[728,90]",
    "[728,250]",
    "[728,280]",
    "[728,300]",
    "[729,90]",
    "[750,200]",
    "[768,1024]",
    "[960,90]",
    "[970,90]",
    "[970,250]",
    "[970,300]",
    "[980,90]",
    "[1024,768]",
    "[1200,250]",
    "[1,1]",
    "[4,1]",
    "[2,2]",
  ];
  const mobileSizes = [
    "[120,240]",
    "[200,200]",
    "[234,60]",
    "[240,400]",
    "[250,250]",
    "[300,50]",
    "[300,75]",
    "[300,100]",
    "[300,250]",
    "[300,300]",
    "[300,480]",
    "[300,600]",
    "[320,50]",
    "[320,90]",
    "[320,100]",
    "[320,240]",
    "[320,250]",
    "[320,280]",
    "[320,400]",
    "[320,480]",
    "[336,250]",
    "[336,280]",
    "[336,480]",
    "[360,280]",
    "[360,300]",
    "[375,50]",
    "[468,60]",
    "[480,320]",
    "[1,1]",
    "[4,1]",
    "[2,2]",
  ];
  let customHeader = {
    mid: "EU",
    mid1: "US",
    mid2: "AS",
  };
  let adIndex;

  const handleAdd = (num) => {
    let newRows = [...rows];
    let index = rows.length + 1;
    let sub_obj_counter = JSON.parse(JSON.stringify(props.subObjCounter));
    let limit = num ?? addNum;
    for (let i = 0; i < limit; i++) {
      let newUnit = { ...adUnit };
      props.addField("mappings_full_hb_config");
      newUnit.id = index + i;
      newUnit.adUnitName = "Please fill ad unit " + (index + i) + " here";
      // let name = "mappings_full_hb_config#adUnitName" + (index + i);
      // props.fieldChange({}, newUnit.adUnitName, name);
      newRows.push(newUnit);
      let counter = bidderNames.map((bidder) => {
        return {};
      });
      if (counter.length <= 0) {
        counter.push({});
      }
      sub_obj_counter["mappings_full_hb_configbidders"][index + i] = counter;
    }
    setRows(newRows);
    if (limit === 1) {
      setNewAdded(true);
    }
  };

  const handleAddBidder = (e) => {
    setBidderNames(e.target.value);
  };
  const handleUpload = (e) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const workbook = XLSX.read(e.target.result);
      var sheet_name_list = workbook.SheetNames;
      setUpload(XLSX.utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]]));
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };
  const handleSlotUpload = (e) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      let slots = e.target.result.split("\n\r\n");
      setSlots(slots);
    };
    reader.readAsText(e.target.files[0]);
  };
  useEffect(() => {
    if (slots) {
      let newAdd =
        !props.editFlag ||
        (props.objCounter["mappings_full_hb_config"].length === 1 &&
          props.objDetails["mappings_full_hb_config"]["adUnitName0"].length <=
            0);
      let index = newAdd
        ? 0
        : props.objCounter["mappings_full_hb_config"].length;

      handleAdd(newAdd ? slots.length - 1 : slots.length);
      let details = JSON.parse(JSON.stringify(props.objDetails));
      slots.forEach((slot, i) => {
        if(slot !== undefined && slot !== "" && slot !== null){
        let comps = slot.split(/['']/);
        let adUnitName = comps[1];
        let sizes = comps[2];
        sizes = sizes.slice(sizes.indexOf("["), sizes.lastIndexOf("]") + 1);
        sizes = JSON.parse(sizes);
        if (typeof sizes[0] !== "object") {
          let arr = [];
          arr.push(sizes);
          sizes = arr;
        }
        let newSizes = sizes.map((size, i) => {
          return JSON.stringify(size);
        });
        let sizesD = newSizes.filter((size) => {
          return !mobileSizes.includes(size);
        });
        if (sizesD.length <= 0) {
          sizesD = newSizes.filter((size) => {
            return desktopSizes.includes(size);
          });
        }
        let sizesM = newSizes.filter((size) => mobileSizes.includes(size));
        let adId = comps[3];
        let display = "B";
        if (sizesM.length <= 0) {
          display = "D";
        }
        if (sizesD.length <= 0) {
          display = "M";
        }

        details["mappings_full_hb_config"]["adUnitName" + (index + i)] =
          adUnitName;
        details["mappings_full_hb_config"]["sizesD" + (index + i)] = sizesD;
        details["mappings_full_hb_config"]["sizesM" + (index + i)] = sizesM;
        details["mappings_full_hb_config"]["adId" + (index + i)] = adId;
        details["mappings_full_hb_config"]["adUnitDisplay" + (index + i)] =
          display;
      }
      });
      dispatch(setObjFields(details));
    }
  }, [slots]);

  useEffect(() => {
    let subDetails = JSON.parse(JSON.stringify(props.subObjDetails));
    let subCounter = JSON.parse(JSON.stringify(props.subObjCounter));

    let bidderDetails = subDetails["mappings_full_hb_configbidders"];
    let bidderCounter = subCounter["mappings_full_hb_configbidders"];
    let mappings = addProdDetails["mappings_full_hb_config"];
    if (upload) {
      upload.forEach((row) => {
        let bidders = Object.keys(row);
        let index;
        mappings.some((adUnit, i) => {
          if (adUnit.adUnitName.includes(row.adUnitName)) {
            index = i;
            return true;
          } else return false;
        });
        bidders.splice(0, 1);
        if (index !== undefined) {
          let length =
            subCounter["mappings_full_hb_configbidders"][index].length;
          subCounter["mappings_full_hb_configbidders"][index].forEach(
            (el, sub_i) => {
              let bidder = bidderDetails["bidder" + index.toString() + sub_i];
              if (bidder && bidders.includes(bidder)) {
                let newParams = {};
                bidderDefault[bidder].forEach((param) => {
                  const [name, value] = param.split("|");
                  if (value) {
                    newParams[name] = value;
                  } else {
                    newParams[name] = row[bidder];
                  }
                });
                bidderDetails["params" + index.toString() + sub_i] = newParams;
              }
              bidders = bidders.filter((name) => name !== bidder);
            }
          );
          bidders.forEach((bidder) => {
            bidderDetails["bidder" + index.toString() + length] = bidder;
            let newParams = {};
            bidderDefault[bidder].forEach((param) => {
              const [name, value] = param.split("|");
              if (value) {
                newParams[name] = value;
              } else {
                newParams[name] = row[bidder];
              }
            });
            bidderDetails["params" + index.toString() + length] = newParams;
            bidderCounter[index].push({});
            length++;
          });
        }
      });
    }
    subDetails["mappings_full_hb_configbidders"] = bidderDetails;
    dispatch(setSubObjFields(subDetails));
    dispatch(setSubObjCounter(subCounter));
  }, [upload]);
  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    let details = [...addProdDetails["mappings_full_hb_config"]];
    if (Object.keys(details[0]).length > 0) {
      details = details.map((el, i) => {
        el["id"] = i + 1;
        return el;
      });
      setRows(details);
    } else {
      // let name = "mappings_full_hb_config#adUnitName0";
      // props.fieldChange({}, adUnit.adUnitName, name);
    }
  }, []);
  const columns = [
    {
      field: "adUnitName",
      headerName: "Ad Unit Name",
      width: 350,
      editable: true,
      valueGetter: (params) => {
        adIndex = params.api.getRowIndex(params.row.id);
        let value =
          props.objDetails["mappings_full_hb_config"]["adUnitName" + adIndex];
        if (value === "") {
          value = params.value;
        }
        return value;
      },
      valueSetter: (params) => {
        let name = "mappings_full_hb_config#adUnitName" + adIndex;
        props.fieldChange({}, params.value, name);
        return { ...params.row };
      },
    },
    {
      field: "adId",
      headerName: "Div Id",
      width: 200,
      valueGetter: (params) => {
        adIndex = params.api.getRowIndex(params.row.id);
        let value =
          props.objDetails["mappings_full_hb_config"]["adId" + adIndex];
        if (value === "") {
          value = params.value;
        }
        return value;
      },
    },
  ];
  useEffect(() => {
    let bidders = JSON.parse(
      JSON.stringify(props.subObjDetails["mappings_full_hb_configbidders"])
    );
    let bidNames = [];
    for (let key in bidders) {
      if (key.includes("bidder")) {
        if (
          bidders[key] in bidderDefault &&
          !bidNames.includes(bidders[key]) &&
          bidders[key].length > 0
        ) {
          bidNames.push(bidders[key]);
        }
      }
    }
    setBidderNames(bidNames);
  }, [props.subObjDetails]);

  useEffect(() => {
    rows?.forEach((el) => {
      if (el.bidders !== false) {
        el?.bidders?.forEach((sub_el) => {
          if (
            sub_el.bidder.length > 0 &&
            !bidderNames.includes(sub_el.bidder)
          ) {
            setBidderNames((prev) => [...prev, sub_el.bidder]);
          }
        });
      }
    });
  }, [rows]);

  bidderNames.forEach((bidder, i) => {
    let index;
    let bidderFields = bidderDefault[bidder]?.filter((el) => {
      return !el.includes("|");
    });
    let subIndex;
    bidderFields?.forEach((bidderParam, param_i) => {
      let header = customHeader[bidderParam] ?? bidderParam;
      let field = {
        field: bidder + " (" + bidderParam + ")",
        headerName: bidder + " (" + header + ")",
        width: 200,
        editable: true,
        valueGetter: (params) => {
          index = params.api.getRowIndex(params.row.id);
          let value = "";
          props.subObjCounter["mappings_full_hb_configbidders"][index]?.forEach(
            (el, sub_i) => {
              let bidderName =
                props.subObjDetails["mappings_full_hb_configbidders"][
                  "bidder" + index + sub_i
                ];
              if (bidder === bidderName) {
                let params =
                  props.subObjDetails["mappings_full_hb_configbidders"][
                    "params" + index + sub_i
                  ];
                subIndex = sub_i;
                value = params[bidderParam];
              }
            }
          );
          return value;
        },
        valueSetter: (params) => {
          if (params.value !== undefined) {
            let name, value;
            let defaults = [];
            bidderDefault[bidder]?.forEach((param) => {
              var [paramName, paramValue] = param.split("|");
              if (paramValue) {
                let param = { name: paramName, value: paramValue };
                defaults.push(param);
              }
            });
            let e = {
              target: {
                name: bidderParam,
                value: params.value,
                defaults: defaults,
              },
            };
            props.subParamChange(e, bidder, index, subIndex ?? i);
          }
          return { ...params.row };
        },
      };
      columns.push(field);
    });
  });
  let config = {
    field: "config",
    headerName: "Config",
    width: 100,
    editable: true,
    renderCell: (params) => {
      let index = params.api.getRowIndex(params.row.id);
      return (
        <DialogPopup
          customName="Config"
          newIndex={index}
          counter={[index]}
          field={props.field}
          nextProps={props.nextProps}
        />
      );
    },
  };
  let deleteField = {
    field: "delete",
    headerName: "",
    width: 70,
    renderCell: (params) => {
      let index = params.api.getRowIndex(params.row.id);
      let name = "mappings_full_hb_config";
      return (
        <>
          {index > 0 && (
            <IconButton
              onClick={() => {
                props.deleteField(name, index);
                let currentRows = [...rows];
                currentRows.splice(index, 1);
                setRows((prev) => currentRows);
              }}
              color="error"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      );
    },
  };
  columns.push(config);
  columns.push(deleteField);

  return (
    <Box sx={{ height: 450, width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box style={{ marginBottom: "10px" }}>
          <i style={{ marginRight: "30px", fontSize: "15px" }}>Upload Slots</i>
          <input
            style={{ cursor: "pointer" }}
            type="file"
            onChange={handleSlotUpload}
            onClick={(e) => {
              e.target.value = "";
            }}
            accept=".txt"
          />
        </Box>
        <Box style={{ marginBottom: "10px" }}>
          <i style={{ marginRight: "30px", fontSize: "15px" }}>
            Upload Bidders
          </i>
          <input
            style={{ cursor: "pointer" }}
            type="file"
            onChange={handleUpload}
            onClick={(e) => {
              e.target.value = "";
            }}
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "end",
            gap: "20px",
          }}
        >
          <Button
            variant="contained"
            style={{
              width: "max-content",
              marginBottom: "10px",
            }}
            onClick={() => {
              handleAdd();
            }}
          >
            Add Unit
          </Button>
          <Box
            sx={{
              height: "36px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              color="primary"
              onClick={() => {
                if (addNum > 1) {
                  setAddNum((prev) => prev - 1);
                }
              }}
            >
              <RemoveCircleIcon />
            </IconButton>
            <h2> {addNum}</h2>
            <IconButton
              color="primary"
              onClick={() => {
                if (addNum < 10) {
                  setAddNum((prev) => prev + 1);
                }
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </Box>
        </Box>
        <DropdownMultiInput
          field={{
            name: "bidders",
            label: "Bidders",
            selectOptions: Object.keys(bidderDefault),
          }}
          arr={bidderNames}
          handleChange={handleAddBidder}
        />
      </Box>

      {newAdded && (
        <DialogPopup
          customName="Config"
          newIndex={rows.length - 1}
          field={props.field}
          counter={[rows.length - 1]}
          nextProps={props.nextProps}
          open={newAdded}
          setOpen={setNewAdded}
          setDialogClosed={setDialogClosed}
        />
      )}
      <DataGrid
        rows={rows}
        columns={columns}
        getRowId={(row) => row.id}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        components={{ Pagination: CustomPagination, Toolbar: CustomToolbar }}
      />
    </Box>
  );
};

export default React.memo(GridTable);
