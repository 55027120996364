const productAdditionalFields = {
  DirectCampaign: [
    {
      name: "CURRENT_CLIENT",
      label: "CURRENT CLIENT",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "GA_CODE",
      label: "GA CODE",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "client_domain",
      label: "client domain",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
    },
    {
      name: "channelId",
      label: "Channel Id",
      type: "Input",
      default: "",
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    {
      name: "disabled",
      label: "disabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "Sticky_Ad",
      label: "Sticky Ad",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "WidgetPosition",
      label: "Widget Position Desktop",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: true,
    },
    {
      name: "WidgetPositionMob",
      label: "Widget Position Mobile",
      type: "radio",
      default: "right",
      selectOptions: ["left", "right"],
      isBool: false,
      isRequired: false,
    },
    {
      name: "DesktopBottomPixel",
      label: "Desktop Bottom Pixel",
      type: "Input",
      default: 0,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "MobileBottomPixel",
      label: "Mobile Bottom Pixel",
      type: "Input",
      default: 0,
      selectOptions: null,
      isBool: false,
      isNum: true,
    },
    {
      name: "ampEnable",
      label: "Amp Enable",
      type: "switch",
      default: false,
      isBool: true,
    },
    {
      name: "cubeType",
      label: "Cube Type",
      type: "Dropdown",
      default: "game",
      selectOptions: [
        "game",
        "nativclick",
        "colombia",
        "outbrain",
        "mgid",
        "dc",
      ],
      isBool: false,
    },
    {
      name: "cubeEnabled",
      label: "Cube Enabled",
      type: "switch",
      default: false,
      isBool: true,
      isRequired: false,
    },
    {
      name: "moa_ads",
      label: "Moa Ads",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: false,
    },
    {
      name: "infinite_ads",
      label: "Infinite Ads",
      type: "switch",
      default: true,
      isBool: true,
      isRequired: false,
    },
    {
      name: "CUSTOM_ADS_JS",
      label: "Custom Ads Js",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "CUSTOM_ADS_CSS",
      label: "CUSTOM ADS CSS",
      type: "radio",
      default: false,
      selectOptions: ["true", "false"],
      isBool: true,
      isRequired: true,
      textbox: true,
    },
    {
      name: "cubeData",
      label: "Cube Data",
      type: "objectgroup",
      fields: [
        {
          name: "url",
          label: "url",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
        },
        {
          name: "txt",
          label: "text",
          type: "Input",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
        },

        {
          name: "img_upload",
          label: "Upload Image",
          type: "upload",
          path: "https://unibots.b-cdn.net/directcampaign/images/",
          default: "",
          selectOptions: null,
          isBool: false,
          saveType: "string",
        },
        {
          name: "img_link",
          label: "Image Link",
          type: "Input",
          path: "https://unibots.b-cdn.net/directcampaign/images/",
          default: "",
          selectOptions: null,
          isBool: false,
          style: { width: "70%" },
          saveType: "string",
        },
      ],
      addButton: true,
      default: [{}],
      selectOptions: null,
      isBool: false,
      isRequired: true,
    },
    // {
    //   name: "ad_mappings",
    //   label: "Ad Mappings",
    //   type: "objectgroup",
    //   fields: [
    //     {
    //       name: "url",
    //       label: "url",
    //       type: "Input",
    //       default: "",
    //       selectOptions: null,
    //       isBool: false,
    //       saveType: "string",
    //     },
    //     {
    //       name: "txt",
    //       label: "text",
    //       type: "Input",
    //       default: "",
    //       selectOptions: null,
    //       isBool: false,
    //       saveType: "string",
    //     },
    //     {
    //       name: "img_upload",
    //       label: "Image",
    //       type: "upload",
    //       path: "https://unibots.b-cdn.net/directcampaign/images/",
    //       default: "",
    //       selectOptions: null,
    //       isBool: false,
    //       saveType: "string",
    //     },
    //     {
    //       name: "img_link",
    //       label: "Image Link",
    //       type: "Input",
    //       path: "https://unibots.b-cdn.net/directcampaign/images/",
    //       default: "",
    //       selectOptions: null,
    //       isBool: false,
    //       style: { width: "70%" },
    //       saveType: "string",
    //     },
    //   ],
    //   addButton: true,
    //   default: [{}],
    //   selectOptions: null,
    //   isBool: false,
    //   isRequired: true,
    // },
  ],
};
export default productAdditionalFields;