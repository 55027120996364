import React, { useState } from "react";
import { FormControl, TextField, Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";

const AutoCompleteInput = (props) => {
  const data = props.data;
  const field = props.field;
  const [optLength, setOptLength] = useState(null);
  const [customVal, setCustomVal] = useState(null);
  if (!optLength) {
    setOptLength((prev) => field.selectOptions.length);
  }
  let faceIndex = -1;
  if (field.name === "data") {
    switch (data.prodDetails["Location" + data.index]) {
      case "world":
        faceIndex = 0;
        break;
      case "india":
        faceIndex = 1;
        break;
      case "":
        faceIndex = -1;
        break;
      default:
        faceIndex = 2;
        break;
    }
  }
  let name =
    data.name !== undefined
      ? data.name + "#" + field.name + data.index
      : field.name;
  return (
    <FormControl sx={{ m: 2, width: "20%" }}>
      <Autocomplete
        disablePortal
        disabled={props.disabled}
        name={name}
        value={data?.prodDetails[field.name + data.index]}
        options={
          field.name === "data"
            ? faceIndex >= 0
              ? field.selectOptions[faceIndex]
              : []
            : field.selectOptions
        }
        renderInput={(params) => (
          <TextField {...params} label={field.label} name={name} />
        )}
        onChange={(e, newVal) => {
          data.handleSubChange(e, newVal, name);
          setCustomVal(null);
        }}
        onInputChange={(e, newVal) => {
          if (
            !field.selectOptions.some((e) => e.includes(newVal)) &&
            field.addCustom
          ) {
            field.selectOptions[optLength] = newVal;
            setCustomVal(newVal);
          }
        }}
      />
    </FormControl>
  );
};

export default AutoCompleteInput;
