import React, { useState, useEffect } from "react";
import ObjGroup from "../Objectgroup/ObjGroup";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppDetails,
  setObjCounter,
  setSubObjCounter,
  setObjFields,
  setSubObjFields,
} from "../../redux/actions/commonActions";
import productAdditionalFields from "./ProductAdditionalField";
import SaveDc from "./SaveDc";
const DirectCampaign = (props) => {
  const dispatch = useDispatch();
  const field_data = productAdditionalFields.DirectCampaign;
  let apDetails_Fields = {};
  let objectgroup_fields = {};
  let subobjectgroup_fields = {};
  let objectgroup_counter = {};
  let subobjectgroup_counter = {};
  let not_req_fields = [];

  const addProdDetails = useSelector((state) => state.common.addProdDetails);
  const [editFlag, setEditFlag] = useState(props.editFlag);
  const [dcFormData, setDcFormData] = useState(props.dcFormData);
  const isArr = (el) => {
    return Object.prototype.toString.call(el) == "[object Array]";
  };

  useEffect(() => {
    if (editFlag && dcFormData) {
      // console.log("inside edit fields");
      field_data.forEach((el) => {
        let field_value = dcFormData[el.name] ?? el.default;
        if (typeof field_value == "object")
          field_value = JSON.parse(JSON.stringify(field_value));
        apDetails_Fields[el.name] = field_value;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type == "objectgroup") {
          el.fields.forEach((field) => {
            if (!field.isRequired) {
              not_req_fields.push(field.name);
            }
          });
          if (!isArr(field_value)) {
            let temp = [];
            temp.push(field_value);
            field_value = temp;
          }
          setDirectCampaignObjGroupFields(el, field_value);
        }
      });
    } else {
      field_data.forEach((el) => {
        apDetails_Fields[el.name] =
          typeof el.default === "object"
            ? JSON.parse(JSON.stringify(el.default))
            : el.default;
        if (!el.isRequired) not_req_fields.push(el.name);
        if (el.type === "objectgroup") {
          objectgroup_counter[el.name] = [{}];
          objectgroup_fields[el.name] = {};
          el.fields.forEach((field) => {
            if (field.type === "objectgroup") {
              subobjectgroup_counter[el.name + field.name] = [[{}]];
              subobjectgroup_fields[el.name + field.name] = {};

              field.fields.forEach((subfield) => {
                subobjectgroup_fields[el.name + field.name][
                  subfield.name + "00"
                ] =
                  typeof subfield.default == "object"
                    ? {}
                    : subfield.default.toString();
              });
            } else {
              objectgroup_fields[el.name][field.name + "0"] =
                typeof field.default == "object"
                  ? {}
                  : field.default?.toString();
            }
            if (!field.isRequired) not_req_fields.push(field.name);
          });
        }
      });
    }
  }, [dcFormData]);
  useEffect(() => {
    if (!props.fieldSet) {
      dispatch(
        setAppDetails({ ...apDetails_Fields, CURRENT_CLIENT: props.clientname })
      );
      dispatch(setObjCounter(objectgroup_counter));
      dispatch(setSubObjCounter(subobjectgroup_counter));
      dispatch(setObjFields(objectgroup_fields));
      dispatch(setSubObjFields(subobjectgroup_fields));
      props.setFieldSet(true);
    }
  }, [props.fieldSet]);

  const setDirectCampaignObjGroupFields = (el, field_value) => {
    if (el.type == "objectgroup") {
      objectgroup_counter[el.name] = [];
      objectgroup_fields[el.name] = {};
      for (let i = 0; i < field_value.length; i++) {
        objectgroup_counter[el.name].push({});
      }
      el.fields.forEach((field) => {
        if (field.type == "objectgroup") {
          subobjectgroup_counter[el.name + field.name] = [];
          subobjectgroup_fields[el.name + field.name] = {};
          for (let i = 0; i < field_value.length; i++) {
            subobjectgroup_counter[el.name + field.name].push([]);
            let len =
              field_value[i][field.name]?.length > 0
                ? field_value[i][field.name]?.length
                : 1;

            for (let j = 0; j < len; j++) {
              subobjectgroup_counter[el.name + field.name][i].push({});

              field.fields.forEach((subfield, k) => {
                if (
                  field_value[i][field.name] <= 0 ||
                  Object.keys(field_value[i]).length <= 0 ||
                  field_value[i][field.name] === undefined
                ) {
                  subobjectgroup_fields[el.name + field.name][
                    subfield.name + i + j
                  ] = subfield.default;
                } else {
                  if (field.name === "targetDiv") {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + 0
                    ] =
                      field_value[i][field.name] !== undefined
                        ? field_value[i][field.name][subfield.name] ??
                          subfield.default
                        : subfield.default;
                  } else {
                    subobjectgroup_fields[el.name + field.name][
                      subfield.name + i + j
                    ] =
                      typeof subfield.default == "object"
                        ? subfield.name === "params"
                          ? field_value[i][field.name][j][subfield.name] ?? {}
                          : JSON.stringify(
                              field_value[i][field.name][j][subfield.name]
                            )?.trim() ?? field.default
                        : field_value[i][field.name][j][
                            subfield.name
                          ]?.toString() ?? field.default;
                  }
                }
              });
            }
          }
        } else {
          for (let i = 0; i < field_value.length; i++) {
            if (field_value[i] !== undefined) {
              if (
                field_value[i][field.name] === undefined ||
                Object.keys(field_value[i]).length <= 0
              ) {
                objectgroup_fields[el.name][field.name + i] = field.default;
              } else {
                objectgroup_fields[el.name][field.name + i] =
                  typeof field.default == "object"
                    ? JSON.parse(
                        JSON.stringify(field_value[i][field.name])?.trim()
                      ) ?? field.default
                    : field_value[i][field.name]?.toString() ?? field.default;
              }
              if (
                field.type === "upload" &&
                field_value[i]["img"].includes(field.path)
              ) {
                objectgroup_fields[el.name][field.name + i] = field_value[i][
                  "img"
                ]
                  .split(field.path)
                  .pop();
              }
              if (
                field.name === "img_link" &&
                !field_value[i]["img"].includes(field.path)
              ) {
                objectgroup_fields[el.name][field.name + i] =
                  field_value[i]["img"];
              }
              if (field.name === "sizesM" || field.name === "sizesD") {
                let field_arr =
                  field_value[i][field.name] ?? field_value[i]["sizes"];
                let sizeArr = [];
                field_arr?.forEach((arr, j) => {
                  let stArr = "[" + arr.toString() + "]";
                  if (field.selectOptions.includes(stArr)) {
                    sizeArr.push(stArr);
                  }
                });
                objectgroup_fields[el.name][field.name + i] = sizeArr;
              }
            }
          }
        }
      });
    }
  };

  function uploadDcDetails() {
    let uploadFile = { ...addProdDetails };
    for (let key in addProdDetails) {
      if (key in props.upload) {
        uploadFile[key] = props.upload[key];
      }
    }
    dispatch(setAppDetails(uploadFile));
    setDcFormData(uploadFile);
    setEditFlag(true);
    props.setFieldSet(false);
  }

  useEffect(() => {
    if (props.uploadClicked) uploadDcDetails();
    props.setUploadClicked(false);
  }, [props.uploadClicked]);
  return (
    <span>
      <ObjGroup
        field_data={field_data}
        fieldSet={props.fieldSet}
        heading={props.heading}
        showAlertMsg={props.showAlertMsg}
        showSuccessMsg={props.showSuccessMsg}
      />
      {props.saveClicked ? <SaveDc {...props} /> : <></>}
    </span>
  );
};

export default DirectCampaign;
