import React, { useEffect, useState } from "react";
import { FormControl, TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";

const TextInput = (props) => {
  const data = props.data;
  const field = props.field;

  // let value =
  //   data.prodDetails[field.name + data.index] ?? data.prodDetails[field.name];
  let [value, setValue] = useState(
    data.prodDetails[field.name + data.index] ?? data.prodDetails[field.name]
  );

  var [gagenerated, setGagenerated] = useState(false);
  var [gaVal, setGaVal] = useState(data.prodDetails["GA_CODE"]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  let counter = [""];
  // let textChange = (e) => {
  //   data.handleSubChange(e, field.isNumFloat ? "float" : field.isNum ? "number" : "string");
  // };
  let handleInputChange = (e) => {
    let newValue = e.target.value;
    if (field.isNumFloat) {
      newValue = parseFloat(newValue);
    }
    setValue(newValue);
    data.handleSubChange(
      e,
      field.isNumFloat ? "float" : field.isNum ? "number" : "string"
    );
  };

  if (field.name === "params" && data.prodDetails["bidder" + data.index]) {
    counter =
      field.selectOptions[data.prodDetails["bidder" + data.index]] ?? [];
    handleInputChange =
      data.index.length > 1 ? data.subParamChange : data.paramChange;
  }
  let name =
    data.name !== undefined
      ? data.name + "#" + field.name + data.index
      : field.name;
  let label = field.label;

  const handleGa = async () => {
    setLoading(true);
    try {
      let headersList = {
        "Content-Type": "application/json",
      };

      let bodyContent = JSON.stringify({
        name: data.prodDetails["CURRENT_CLIENT"] + "(" + data.heading + ")",
        url: data.prodDetails["publisherDomain"]
          ? data.prodDetails["publisherDomain"]
          : `https//${data.prodDetails["CURRENT_CLIENT"]}.com`,
        productType: data.heading,
      });

      let reqOptions = {
        url: process.env.REACT_APP_API_URL + "ga/generate",
        method: "POST",
        headers: headersList,
        data: bodyContent,
      };

      let response = await axios.request(reqOptions);
      console.log("GA(", data.heading, ")- ", response.data.id);
      setGagenerated(true);
      setGaVal(response.data.id);
      if (field.name === "GA_CODE") {
        data.handleSubChange(
          { target: { name: name, value: response.data.id } },
          "string"
        );
      }
      setSuccess(true);
    } catch (error) {
      console.error("Error generating GA:", error);
      alert("Error generating GA:");
    } finally {
      setLoading(false);
    }
  };
  const isGaCodeEmpty = !data.prodDetails[field.name];

  return (field.name === "params" &&
    data.prodDetails["bidder" + data.index]?.length > 0) ||
    field.name !== "params" ? (
    <>
      {counter.map((input, index) => {
        if (field.name === "params") {
          var [paramName, paramValue] = input.split("|");
          name = input.length > 0 ? paramName : name;
          label = input.length > 0 ? paramName : label;
          value =
            paramValue ??
            data.prodDetails["params" + data.index][paramName] ??
            "";
          field.disabled = paramValue !== undefined;
        }
        if (
          paramValue !== undefined &&
          !data.prodDetails["params" + data.index][paramName]
        ) {
          let e = { target: { name: name, value: paramValue } };
          // textChange(e, data.prodDetails["bidder" + data.index], data.index);
          handleInputChange(
            e,
            data.prodDetails["bidder" + data.index],
            data.index
          );
        }

        let style = Object.assign(
          {},
          {
            width: "20%",
            margin: "20px",
          },
          field.style
        );
        let key =
          data.prodDetails["bidder" + data.index] + paramName + data.index;
        key = isNaN(key)
          ? data?.index !== undefined
            ? field.name + data.index
            : field.name
          : "";
        return (
          <>
            {field.name === "GA_CODE" && isGaCodeEmpty && !gagenerated ? (
              <Button variant="contained" onClick={handleGa} style={style}>
                {loading ? (
                  <>
                    "Generating..." <LoadingButton />
                  </>
                ) : success ? (
                  "Generated Successfully!"
                ) : (
                  "Generate Your GA Code Here"
                )}
              </Button>
            ) : (
              <TextField
                style={style}
                label={label}
                name={name}
                key={key + Math.random()}
                defaultValue={field.name === "GA_CODE" ? gaVal : value}
                disabled={props.disabled}
                placeholder={
                  (data.name === "ad_mappings" || data.name === "cubeData")
                    ? data.placeHolders[data.gcCubeType][field.name]
                    : ""
                }
                onBlur={(e) => {
                  // textChange( e, data.prodDetails["bidder" + data.index], data.index );
                  handleInputChange(
                    e,
                    data.prodDetails["bidder" + data.index],
                    data.index
                  );
                }}
                required={field.isRequired}
                type={
                  field.isNumFloat ? "number" : field.isNum ? "number" : "text"
                }
                inputProps={field.isNumFloat ? { step: 0.1 } : {}}
                InputProps={{
                  readOnly: field.disabled
                    ? true
                    : field.name == "CURRENT_CLIENT"
                    ? true
                    : false,
                }}
              />
            )}
          </>
        );
      })}
    </>
  ) : (
    <></>
  );
};

export default TextInput;
