import React, { useEffect, useState } from "react";
import Tasks from "./Tasks";
import LoadComponent from "../../utils/LoadComponent";
import axios from "axios";
import { Container, Typography } from "@material-ui/core";

function TaskComponent() {
    const TaskLoading = LoadComponent(Tasks);
    const [tasksState, setTasks] = useState({
        loading: false,
        tasks: null,
    });

    useEffect(() => {
        setTasks({ loading: true });
        const allTaskUrl = process.env.REACT_APP_API_URL + "api/tasks";
        axios.get(allTaskUrl).then((response) => {
            setTasks({ loading: false, tasks: response.data });
        });
    }, [setTasks]);

    return (
        <Container>
            <Typography variant="h4">All Tasks</Typography>
            <TaskLoading
                isLoading={tasksState.isLoading}
                tasks={tasksState.tasks}
            />
        </Container>
    );
}

export default TaskComponent;
