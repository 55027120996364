import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
  GridSearchIcon,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import { TextField } from "@mui/material";
import { NoEncryption } from "@material-ui/icons";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import {
  Select,
  MenuItem,
  InputLabel,
  Alert,
  Chip,
  FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
const useStyles = makeStyles({
  ul: {
    "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
        color: "white",
        background: "#121212",
        "&:hover": {
          background: "#121212",
        },
      },
    },
  },
});

const DataTable = (props) => {
  let initialFormData = {
    domain: "",
    name: "",
    seller_type: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [initForm, setInitForm] = useState(initialFormData);
  const [searchValue, setSearchValue] = useState();
  const [sellers, setSellers] = useState(props.sellers.data);
  const [sellerDetails, setSellerDetails] = useState(false);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({
    message: "",
    show: false,
    type: "",
  });
  const [saveEnable, setSaveEnable] = useState(true);
  const classes = useStyles();

  const showAlertMsg = (msg, type = "error") => {
    return new Promise((resolve, reject) => {
      setAlert((prev) => {
        return {
          ...prev,
          message: msg,
          show: true,
          type: type,
        };
      });

      setTimeout(() => {
        setAlert((prev) => {
          return {
            ...prev,
            message: "",
            show: false,
            type: "",
          };
        });
        if (!alert.show) {
          resolve();
        } else {
          reject();
        }
      }, 2000);
    });
  };
  // let sellers;
  // console.log(props.sellers.data);
  // if (typeof props.sellers.data == "object") {
  //   sellers = props.sellers.data;
  // } else {
  //   sellers = props.sellers.data;
  // }
  const columns = [
    {
      field: "seller_id",
      headerName: "Seller Id",
      width: 200,
      headerAlign: "left",
      align: "left",
      type: "number",
    },
    {
      field: "domain",
      headerName: "Domain",
      width: 400,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "seller_type",
      headerName: "Seller Type",
      flex: 1,
    },
  ];

  let addSeller = () => {
    props.changeAddSeller();
  };

  let loadSellerDetails = (details) => {
    setSellerDetails(true);
    setOpen(true);
    let form = {
      domain: details.row.domain,
      name: details.row.name,
      seller_type: details.row.seller_type,
      seller_id: details.id,
    };
    setFormData(form);
    setInitForm(form);
  };
  const handleChange = (event) => {
    setFormData((prevData) => {
      return {
        ...prevData,
        [event.target.name]: event.target.value,
      };
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    let filterSeller = props.sellers.data.filter((seller) =>
      seller.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setSellers(filterSeller);
  };
  const CustomToolbar = () => {
    const search_bar = (
      <GridToolbarContainer>
        <TextField
          style={{ width: "45%", margin: "5px" }}
          onChange={handleSearchChange}
          value={searchValue}
          autoFocus={searchValue ? true : false}
          label={
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <GridSearchIcon />
              <> Search Seller</>
            </div>
          }
          sx={{ width: "300px" }}
        ></TextField>
      </GridToolbarContainer>
    );
    return search_bar;
  };

  const handleSave = () => {
    if (saveEnable) {
      let flag = true;
      setSaveEnable(false);
      for (let key in formData) {
        if (!formData[key]) {
          flag = false;
          showAlertMsg(`Please fill ${key} option!`);
          setSaveEnable(true);
          break;
        }
      }
      if (JSON.stringify(sellers).includes(formData)) {
        showAlertMsg("Seller already present");
        setSaveEnable(true);
        flag = false;
      }
      if (flag) {
        const options = {
          method: "POST",
          body: JSON.stringify({ formData: formData, initForm: initForm }),
          headers: {
            "Content-Type": "application/json",
          },
        };

        fetch(process.env.REACT_APP_API_URL + "seller/updateSeller", options)
          .then((response) => response.json())
          .then((response) => {
            if (response.error) {
              showAlertMsg(response.message);
            } else {
              showAlertMsg("Seller updated successfully!", "success").then(
                () => {
                  handleClose();
                }
              );
              setSaveEnable(true);
            }
          })
          .catch((err) => {
            setSaveEnable(true);
            console.error(err);
          });
      }
    }
  };

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Pagination
        classes={{
          root: classes.ul,
        }}
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  return (
    <div style={{ height: "700px", width: "100%" }}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="flex-end"
        sx={{ mb: 2 }}
      >
        <Button variant="contained" onClick={addSeller}>
          Add Seller
        </Button>
      </Stack>
      {sellerDetails && (
        <Dialog open={open} onClose={handleClose}>
          {alert.show ? (
            <span style={{ position: "fixed", width: "40%", margin: "20px" }}>
              <Alert severity={alert.type}>{alert.message}</Alert>
            </span>
          ) : (
            <></>
          )}
          <DialogTitle>
            <Chip color="info" label="Seller Info" />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              style={{ width: "90%", margin: "10px" }}
              label="Id"
              name="seller_id"
              value={formData["seller_id"]}
              onChange={handleChange}
              required
              type="number"
            />
            <TextField
              autoFocus
              style={{ width: "90%", margin: "10px" }}
              label="Domain"
              name="domain"
              value={formData["domain"]}
              onChange={handleChange}
              required
            />
            <TextField
              autoFocus
              style={{ width: "90%", margin: "10px" }}
              label="Name"
              name="name"
              value={formData["name"]}
              onChange={handleChange}
              required
            />
            <FormControl sx={{ margin: "10px", width: "90%" }}>
              <InputLabel>Seller Type * </InputLabel>
              <Select
                label="Seller Type"
                name="seller_type"
                required
                value={formData["seller_type"]}
                onChange={handleChange}
              >
                <MenuItem value="PUBLISHER">PUBLISHER</MenuItem>
                <MenuItem value="INTERMEDIARY">INTERMEDIARY</MenuItem>
                <MenuItem value="BOTH">BOTH</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!saveEnable}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <DataGrid
        // onSelectionModelChange={(rows) => { selectedUnits(rows) }}
        // style={{color:'white', border:"1px solid teal"}}
        rows={sellers}
        initialState={{
          sorting: {
            sortModel: [{ field: "seller_id", sort: "desc" }],
          },
        }}
        rowHeight={60}
        getRowId={(row) => row.seller_id}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        onRowClick={loadSellerDetails}
        components={{ Pagination: CustomPagination, Toolbar: CustomToolbar }}
        pagination
      />
    </div>
  );
};

export default DataTable;
